//@ts-nocheck

// Importing necessary components and styling
import styled from "@emotion/styled";
import {
  Avatar,
  Button,
  FormControlLabel,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./sidebar.css";
import chevronRightIcon from "assets/Icons/chevron-right.svg";
import logoutIcon from "assets/Icons/log-out-04.svg";
import MailIcon from "assets/Icons/mail.svg";
import LockIcon from "assets/Icons/lock.svg";
import {
  UpdatePaperlessEnrolledAPI,
  useLogoutQuery,
} from "queries/AuthQueries";
import { getLocalStorageItem } from "hooks/localStorageHelper";
import localstorageKeys from "hooks/localstorageKeys";
import ChangePasswordDialog from "./changePasswordDialog";
import CreatePasswordDialog from "./createPasswordDialog";
import Branding from "./Branding";
import { useResetPassword, useGetUserById, FetchUserDetailsAPI, useCreatePassword } from "queries/AuthQueries";
// Styling for the popover component using Emotion
const StyledPopover = styled(Popover)`
  margin-top: 50px;
  border-radius: 8px;
  .popover-content {
    width: 395px;
    height: 420px;
    padding: 16px;
    margin-top: 10px;
    margin-right: 10px;
  }
`;

const UserAccount: React.FC<any> = ({
  open,
  handleClose = () => null,
  handleAnchor = () => null,
  userDetails,
}) => {
  // Extracting user data and preparing initials for display
  const userData = userDetails;
  console.log(userDetails)
  const name = userData?.name;
  const initials = name
    ? name
        .split(" ")
        .map((part: any) => part.charAt(0))
        .join("")
    : "";

  // State to store fetched user data
  const [userPassword, setUserPassword] = useState<string | null>(null);

  // Logout query hook
  const { mutate: userSignOut } = useLogoutQuery();
  const { mutate: getUserById } = useGetUserById();
  const uuid = userDetails?.uuid;

  


  // Fetch user details on component mount
  useEffect(() => {
    console.log("hello",uuid)
    if (uuid) {
      getUserById(
        { data: {}, uuid },
        {
          onSuccess: (data) => {
            setUserPassword(data?.data?.password);
            console.log("data",data?.data?.password)
          },
          onError: (error) => {
            console.error("Error fetching user:", error);
          },
        }
      );
    }
  }, [getUserById, uuid]);


  // const { mutate: resetPaperlessEnroll } = UpdatePaperlessEnrolledAPI();

  // const [switchState, setSwitchState] = useState(true);
  // State for dialog visibility
  const [openDialog, setOpenDialog] = useState(false); // For change password dialog

  const [openChangeEmail, setOpenChangeEmail] = useState(false);

  // Handlers for opening and closing dialogs
  const handleOpenChangePassDialog = () => {
    setOpenDialog(!openDialog);
  };
  const handleOpenCreatePassDialog = () => {
    setOpenDialog(!openDialog);
  };

  const openChangeEmailDialog = () => {
    setOpenChangeEmail(!openChangeEmail);
  };

  const handleCloseChangePassDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseCreatePassDialog = () => {
    setOpenDialog(false);
  };

  // Handler for change password submit
  const handleChangePassword = (oldPassword: string, newPassword: string) => {
    console.log(oldPassword, newPassword);
  };


  // Logout functionality
  const Logout = () => {
    const token = getLocalStorageItem(localstorageKeys.AUTH_TOKEN);
    userSignOut(
      { token },
      {
        onSuccess: (res) => {},
        onError: (error: any) => {
          console.log("Error:", error.data.errors[0].message);
        },
      }
    );
  };

  // const handleSwitchToggle = () => {
  //   setSwitchState(!switchState);
  //   const resetData = {
  //     uuid: userData.uuid,
  //     data: {
  //       firstName: null,
  //       lastName: null,
  //       dob: null,
  //       paperlessEnrolled: !switchState,
  //     },
  //   };
  //   resetPaperlessEnroll(resetData, {
  //     onSuccess: (data) => {
  //       console.log("Data:", data);
  //     },
  //     onError: (error: any) => {
  //       console.error("Password reset failed:", error.fieldErrors[0].message);
  //     },
  //   });
  // };

  // useEffect(() => {
  //   if (userData?.paperlessEnrolled !== undefined) {
  //     setSwitchState(userData.paperlessEnrolled);
  //   }
  // }, [userData]);

  // Rendering the UserAccount component

  return (
    <StyledPopover
      open={open}
      anchorEl={handleAnchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      className="popover "
    >
      {/* Popover content layout and elements */}
      <div className="popover-content justify-center !h-auto">
        <div className="popover-row ml-4">
          <div className="popover-part m-auto h-[170px] p-[16px] w-[347px] justify-center">
            <div className="h-[106px]">
              {/* <Avatar src="user-profile-image-url" /> */}
              <Avatar
                sx={{ bgcolor: "#BB3330", width: "94px", height: "94px" }}
              >
                <span className="font-normal text-4xl leading-12">
                  {initials}
                </span>
              </Avatar>
            </div>
            <div className="h-[64px]">
              <div className="h-[32px]">
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Typography
                      className="font-semibold text-2xl leading-[32px]"
                      style={{ fontSize: "20px" }}
                    >
                      {name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className="flex justify-end w-[140px]"
                  ></Grid>
                </Grid>
              </div>
              <div className="h-32px flex mt-4">
                <Typography
                  color="#545454"
                  className="font-normal text-base leading-5"
                >
                  Member ID : {userData?.memberId}
                </Typography>
              </div>
            </div>
          </div>
          <Grid
            container
            alignItems="center"
            direction="row"
            className="h-[124px]"
          >
            {/* <Grid
              container
              alignItems="center"
              direction="row"
              style={{
                backgroundColor: "#F5F5F5",
                height: "56px",
                cursor: "pointer",
              }}
              className="mt-2"
            >
              <Grid
                item
                xs
                container
                alignItems="center"
                className="p-2"
                onClick={openChangeEmailDialog}
              >
                <img src={MailIcon} alt="Start Icon" />
                <Typography className="ml-2">Change Email</Typography>
              </Grid>
              <Grid item className="p-2">
                <img src={chevronRightIcon} alt="End Icon" />
              </Grid>
            </Grid> */}
             <Grid
              container
              alignItems="center"
              direction="row"
              style={{
                backgroundColor: "#F5F5F5",
                height: "56px",
                cursor: "pointer",
              }}
              className="mt-2"
              onClick={()=> userPassword==null?handleOpenCreatePassDialog():handleOpenChangePassDialog()}
            >
              <Grid item xs container alignItems="center" className="p-2">
                <img src={LockIcon} alt="Start Icon" />
                <Typography className="ml-2"> {userPassword == null?"Create Password":"Change Password" }</Typography>
              </Grid>
              <Grid item className="p-2">
                <img src={chevronRightIcon} alt="End Icon" />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container alignItems="center" direction="row">
            <Grid item xs={12} className="flex flex-col items-center m-4">
              <Switch
                checked={switchState}
                onChange={handleSwitchToggle}
                style={{
                  justifyContent: "center",
                  color: "#fff",
                }}
              />
            </Grid>
          </Grid> */}

          <div className="popover-part m-auto p-[10px] h-[44px] w-[347px] flex items-center justify-center mt-5">
            <Button
              onClick={Logout}
              variant="text"
              className="flex items-center"
              style={{ boxShadow: "none" }}
            >
              <span
                className="text-base font-medium leading-[28px]"
                color="#333333"
              >
                Logout
              </span>{" "}
              <img src={logoutIcon} alt="" className="ml-1" />
            </Button>
          </div>
          <div className="mt-4 flex items-center justify-center pb-4">
            <Branding />
          </div>
        </div>
      </div>
    {userPassword !==null &&  <ChangePasswordDialog
        open={openDialog}
        onClose={handleCloseChangePassDialog}
        onSubmit={handleChangePassword}
        userData={userData}
      /> } 
    {userPassword == null &&  <CreatePasswordDialog   open={openDialog}
        onClose={handleCloseCreatePassDialog}
        onSubmit={handleChangePassword}
        userData={userData} />}
    </StyledPopover>
  );
};

// Exporting the UserAccount component
export default UserAccount;
