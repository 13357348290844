function getNextMonthStartDate(expiryDate: string) {
  // Parse MM/YY string
  const [month, year] = expiryDate.split('/').map(Number) as [number, number];

  // Adjust to the first day of the next month
  const nextMonth = month === 12 ? 1 : month + 1;
  const nextYear = month === 12 ? year + 1 : year;

  // Create a Date object for the adjusted date
  const nextMonthStartDate = new Date(Date.UTC(+`20${nextYear}`, nextMonth - 1, 1));

  // Return the ISO string representation
  return nextMonthStartDate.toISOString();
}

export default getNextMonthStartDate;

// Examples
// console.log(getNextMonthStartDate('11/36')); // Output: "2036-12-01T00:00:00.000Z"
// console.log(getNextMonthStartDate('12/42')); // Output: "2043-01-01T00:00:00.000Z"
// console.log(getNextMonthStartDate('12/30')); // Output: "2031-01-01T00:00:00.000Z"

