// A constant object containing keys for localStorage items. 
// This ensures consistent key usage across the application and reduces the risk of key name errors.
const localstorageKeys = {
  // Key for storing or retrieving Onboarding information related to OTT (Over-the-top) services.
  ONBOARD_OTT: 'ONBOARD_OTT',

  // Key for storing or retrieving profile information during the onboarding process.
  ONBOARDING_PROFILE: 'ONBOARDING_PROFILE',

  // Key for storing or retrieving authentication information during the onboarding process.
  ONBOARDING_AUTH: 'ONBOARDING_AUTH',

  // Key for storing or retrieving the authentication token.
  AUTH_TOKEN: 'AUTH_TOKEN',

  // Key for storing or retrieving the refresh token.
  REFRESH_TOKEN: 'REFRESH_TOKEN',

  // Key for storing or retrieving user data.
  USER_DATA: 'USER_DATA',

  // Key for storing or retrieving authentication tokens.
  AUTH_TOKENS: 'AUTH_TOKENS',

  // Key for storing or retrieving details of a collision, likely for insurance or reporting purposes.
  COLLISION_DETAILS: 'COLLISION_DETAILS'
};

// Export the localstorageKeys object for use throughout the application.
export default localstorageKeys;
