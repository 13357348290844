//@ts-nocheck

// Importing necessary libraries and components
import { yupResolver } from "@hookform/resolvers/yup";
import { withHookFormMask } from "use-mask-input";
import {
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { FormValidationSchemas } from "constants/FormValidationSchemas";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import "./index.css";
import infoIcon from "assets/Icons/info-circle.svg";
import CloseIcon from "@mui/icons-material/Close";
import FindingPolicyNumber from "assets/Images/FindingPolicyNumber.png";
import FindingZipCode from "assets/Images/FindingZipCode.png";
// import PolicyInfoImage from "assets/Images/policyInfoIcon.png";
import { AddExistingPolicyAPI } from "queries/PolicyQuery";
import CommonDialog from "components/Common/CommonDialog";
import HandleErrorResponse from "helper/HandleErrorResponse";
import DateSelect from "components/Common/DatePicker";
import InputItem from "pages/Claims/Collision/InputItem";
import moment from "moment";

// Importing the validation schema for the policy number
const { policy_no, dob, zip_code } = FormValidationSchemas;

// Defining the schema for form validation using yup
const policySchema = yup.object().shape({
  policy_no,
  zip_code,
  dob,
});

interface AddExistingPolicyDialogProps {
  open: boolean;
  handleClose: () => void;
}

// AddExistingPolicyDialog is a functional component for adding an existing policy
const AddExistingPolicyDialog = (props: AddExistingPolicyDialogProps) => {
  const { open, handleClose } = props;

  const [policyNumber, setPolicyNumber] = useState("");

  // State hooks for various component states
  const [isHovered, setIsHovered] = useState(false); // State to handle tooltip visibility
  const [isHoveredZipCode, setIsHoveredZipCode] = useState(false); // State to handle tooltip visibility
  const [isSwitchOn, setIsSwitchOn] = useState(false); // State for the paperless switch
  const [openEnrollPolicyConfirmDialog, setOpenEnrollPolicyConfirmDialog] =
    useState(false); // State for the confirmation dialog

  // Hook to interact with the AddExistingPolicy API
  const { mutate: addPolicy, error } = AddExistingPolicyAPI();

  // useForm hook for form handling
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
    control,
    getValues,
  } = useForm({
    resolver: yupResolver(policySchema),
  });
  // Watch the policy_no value from the form
  const { policy_no, dob, zip_code: zipCode } = watch();

  // Function to handle errors from form submission
  const handlePolicyErrors = (err: any) => {
    console.log(err, "errors");
  };

  // Function to handle the form submission
  const submitPolicyForm = (data: any) => {
    console.log({ data });
    const { dob, policy_no, zip_code } = data || {};
    const dateOfBirth = moment(dob);
    const newdob = dateOfBirth?.format("MM-DD-YYYY");

    // if (!isSwitchOn) {
    //   setOpenEnrollPolicyConfirmDialog(true);
    // } else {
      // Preparing parameters for the API call
      const params = {
        policyNo: policy_no,
        paperlessEnrolled: isSwitchOn,
        zipcode: zip_code.toString(),
        dob: newdob,
      };
      // Making the API call to add the policy
      addPolicy(params, {
        onSuccess: (res) => {
          handleDialogClose();
        },
        onError: (err) => {
          return HandleErrorResponse(err);
        },
      });
    // }
  };

  // Function to handle the toggle of the paperless switch
  const handleSwitchToggle = () => {
    setIsSwitchOn(!isSwitchOn);
    if (!isSwitchOn) {
      // Opening a link to the electronic delivery consent form if the switch is turned on
      const url = `https://moatit-dev-fbins.s3.us-west-2.amazonaws.com/documents/electronic_delivery_consent.pdf`;
      window.open(url, "_blank");
    }
  };

  function handleZipCodeChange(event: any) {
    setValue("zip_code", event, { shouldValidate: true });
  }

  function handleDateChange(event: any) {
    setValue("dob", event.target.value, { shouldValidate: true });
  }

  const policyIdFormatter = (value: string) => {
    // Remove non-numeric characters
    const cleanedValue = value.replace(/\D/g, "");

    // Apply the format ##-######-##
    // const formattedValue = cleanedValue.replace(/(\d{0,2})(\d{0,6})(\d{0,2})/, "$1-$2-$3");

    const formattedValue = cleanedValue.replace(
      /(\d{0,2})(\d{0,6})(\d{0,2})/,
      (_, part1, part2, part3) => {
        let result = part1 ? `${part1}` : "";
        result += part2 ? `-${part2}` : "";
        result += part3 ? `-${part3}` : "";
        return result;
      }
    );
    console.log({ formattedValue });
    setPolicyNumber(formattedValue);

    return formattedValue;
  };
  const handleAcceptEnrollPolicyDialog = () => {
    const { dob, zip_code } = getValues();
    const dateOfBirth = moment(dob);
    const newdob = dateOfBirth?.format("MM-DD-YYYY");
    const params = {
      policyNo: policy_no,
      paperlessEnrolled: isSwitchOn,
      zipcode: zip_code.toString(),
      // dob: newdob,
    };
    addPolicy(params, {
      onSuccess: (res) => {
        handleDialogClose();
      },
      onError: (err) => {
        return HandleErrorResponse(err);
      },
    });
    setOpenEnrollPolicyConfirmDialog(false);
  };

  // Function to close the policy enrollment confirmation dialog
  const closeEnrollPolicyConfirmDialog = () => {
    setOpenEnrollPolicyConfirmDialog(false);
  };

  // withHookFormMask hook for applying input mask to the policy number field
  // const maskedProps = withHookFormMask(register("policy_no"), "99-999999-99");

  // console.log({ policy_no, dob });

  // console.log("dobErr", errors.dob);

  // console.log("dobErr", errors.dob);

  // Function to handle closing of the main dialog
  const handleDialogClose = () => {
    reset(); // Resetting the form
    setIsSwitchOn(false); // Resetting the switch state
    handleClose(); // Calling the handleClose function passed as a prop
  };

  // const CustomSwitch = styled(Switch)(({ theme }) => ({
  //   "& .MuiSwitch-switchBase": {
  //     color: "#fff",
  //     "&.Mui-checked": {
  //       color: "#fff",
  //     },
  //     "&.Mui-checked + .MuiSwitch-track": {
  //       backgroundColor: "#BB3330",
  //     },
  //   },
  //   "& .MuiSwitch-track": {
  //     backgroundColor: "gray",
  //   },
  // }));

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#BB3330",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  console.log({ errors });

  // Render the AddExistingPolicyDialog component
  return (
    <>
      {/* Main dialog for adding an existing policy */}
      <Dialog open={open} onClose={handleDialogClose}>
        <form
          onSubmit={handleSubmit(submitPolicyForm, handlePolicyErrors)}
          id="policy-form"
          className="max-w-[455px] max-h-[684px] mt-2"
        >
          {/* Close button for the dialog */}
          <span className="mr-4 flex cursor-pointer items-center justify-end mb-[-35px]">
            <IconButton onClick={handleDialogClose}>
              <CloseIcon style={{ color: "#434349" }} />
            </IconButton>
          </span>
          {/* Dialog content */}
          <DialogContent className="pt-0">
            <Grid item container spacing={2} className="p-4">
              <Grid item xs={12}>
                <Typography
                  color="#0F0F0F"
                  className="max-w-[400px] font-semibold text-base m-auto pt-0"
                >
                  Enter your details
                </Typography>
                <Typography
                  color="#545454"
                  className="max-w-[400px] font-normal text-sm m-auto"
                >
                  Please provide verification information for the account you
                  wish to access
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <LabelledTextField
                  className="max-w-[400px] items-start justify-center m-auto flex"
                  variant="outlined"
                  labelText="Policy No."
                  placeholder="Enter Policy No."
                  // inputProps={{ maxLength: 12, ...maskedProps }}
                   inputProps={{ maxLength: 12, }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <IconButton
                            aria-label="additional information"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <img src={infoIcon} alt="" />
                          </IconButton>
                          {isHovered && (
                            <div
                              style={{
                                position: "absolute",
                                top: "calc(100% + 8px)",
                                left: "50%",
                                transform: "translateX(-80%)",
                                zIndex: 9999,
                                backgroundColor: "#E3E3E3",
                                padding: "10px",
                                borderRadius: "4px",
                              }}
                              className="tooltip-container"
                            >
                              <Tooltip
                                title="Policy Information"
                                arrow
                                placement="top"
                              >
                                <img
                                  src={PolicyInfoImage}
                                  alt="Policy Information"
                                />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(!errors?.policy_no)}
                  // helperText={error && (error?.message || "Policy not available") }
                  autoComplete="policy_no"
                />


              
              </Grid> */}

              <InputItem
                label="Policy No"
                placeholder="Enter Policy No."
                control={control}
                inputName="policy_no"
                errors={errors}
                xs={12}
                isMandatory={true}
                maxCharacters={12}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <IconButton
                          aria-label="additional information"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <img src={infoIcon} alt="" />
                        </IconButton>
                        {isHovered && (
                          <div
                            style={{
                              position: "absolute",
                              top: "calc(100% + 8px)",
                              left: "50%",
                              transform: "translateX(-80%)",
                              zIndex: 9999,
                              backgroundColor: "#E3E3E3",
                              padding: "10px",
                              borderRadius: "4px",
                            }}
                            className="tooltip-container"
                          >
                            <Tooltip
                              title="Policy Information"
                              arrow
                              placement="top"
                            >
                              <img
                              width={"260px"}
                              height={"140px"}
                                src={FindingPolicyNumber}
                                alt="Policy Information"
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </InputAdornment>
                  ),
                }}
                isValueAndOnChangeFn={true}
                value={policyNumber}
                onValueChange={(value) => policyIdFormatter(value)}
              />

              <Fragment>
                <InputItem
                  label="Zip Code"
                  placeholder="Enter Zip Code"
                  control={control}
                  inputName="zip_code"
                  errors={errors}
                  xs={12}
                  isMandatory={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <IconButton
                            aria-label="additional information"
                            onMouseEnter={() => setIsHoveredZipCode(true)}
                            onMouseLeave={() => setIsHoveredZipCode(false)}
                          >
                            <img src={infoIcon} alt="" />
                          </IconButton>
                          {isHoveredZipCode && (
                            <div
                              style={{
                                position: "absolute",
                                top: "calc(100% + 8px)",
                                left: "50%",
                                transform: "translateX(-80%)",
                                zIndex: 9999,
                                backgroundColor: "#E3E3E3",
                                padding: "10px",
                                borderRadius: "4px",
                              }}
                              className="tooltip-container"
                            >
                              <Tooltip
                                title="Policy Information"
                                arrow
                                placement="top"
                              >
                                <img
                                width={"260px"}
                                height={"140px"}
                                  src={FindingZipCode}
                                  alt="Policy Information"
                                />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  maxCharacters={5}
                />
                <Grid item xs={12}>
                  <DateSelect
                    className="items-start justify-center m-auto"
                    value={dob}
                    onChange={handleDateChange}
                    error={Boolean(errors?.dob)}
                    labelText="Date of Birth"
                    helperText={(errors?.dob?.message as string) || ""}
                  />
                </Grid>

                <Grid item xs={12}>
                  {errors?.dob && (
                    <Typography
                      variant="body2"
                      color="error"
                      textAlign={"center"}
                      marginTop={1}
                      marginBottom={1}
                    >
                      {errors?.dob?.message ?? ""}
                    </Typography>
                  )}
                </Grid> 
              </Fragment>
              {/* <Grid container alignItems="center" direction="row">
                <Grid item xs={12} className="flex flex-col items-center mt-3">
                  <FormControlLabel
                    className="gap-x-2.5"
                    control={
                      <CustomSwitch
                        checked={isSwitchOn}
                        onChange={handleSwitchToggle}
                      />
                    }
                    label="Enrolled in paperless"
                  />
                </Grid>
              </Grid> */}

              <Grid item xs={12}>
                <Button
                  color="secondary"
                  fullWidth
                  type="submit"
                  size="large"
                  // disabled={}
                  className="max-w-[400px] items-center justify-center m-auto flex custom-button h-12"
                >
                  Enroll Policy
                </Button>
              </Grid>
              
              <Grid item xs={12} spacing={2}>
                <div className="assist-grid max-w-[400px] space-y-2 mt-3">
                  <Typography
                    color="#545454"
                    className="max-w-[400px] font-normal text-sm text-center"
                  >
                    Can’t find what you are looking for?
                  </Typography>
                  <Typography
                    color="#0F0F0F"
                    className="max-w-[400px] font-semibold text-[20px] text-center"
                  >
                    +1 833-996-3276
                  </Typography>
                  <Typography
                    color="#545454"
                    className="max-w-[400px] font-normal text-xs text-center"
                  >
                    Available from 8:00 am to 5:00 pm MT
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>

      {/* Confirmation dialog for policy enrollment without enabling the paperless option */}
      <CommonDialog
        open={openEnrollPolicyConfirmDialog}
        onClose={closeEnrollPolicyConfirmDialog}
        title="Alert"
        maxWidth="xs"
        actions={
          <div className="flex justify-evenly gap-3 items-center w-full pb-4">
            <Button
              color="secondary"
              variant="text"
              className="flex items-center w-[164px] h-11 font-semibold text-4 font-inter leading-7 text-center border border-[#545454] border-solid shadow-[0px_2px_8px_0px_rgba(15,15,15,0.06)] hover:bg-[#262626] hover:text-white hover:border-none"
              onClick={handleAcceptEnrollPolicyDialog}
            >
              Yes
            </Button>
            <Button
              color="primary"
              variant="text"
              className="flex items-center w-[164px] h-11 font-semibold text-4 font-inter leading-7 text-center border border-[#545454] border-solid shadow-[0px_2px_8px_0px_rgba(15,15,15,0.06)] hover:bg-[#262626] hover:text-white hover:border-none"
              onClick={closeEnrollPolicyConfirmDialog}
            >
              No
            </Button>
          </div>
        }
        // children
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="#545454"
              className="mt-0 font-normal text-[14px] leading-5 text-[#333333] text-center -mb-2"
            >
              Are you sure you want to enroll in this policy without enabling
              paperless option?
            </Typography>
          </Grid>
        </Grid>
      </CommonDialog>
    </>
  );
};

// Exporting the AddExistingPolicyDialog component
export default AddExistingPolicyDialog;
