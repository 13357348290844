import React, { useEffect, useMemo, useState } from "react";
import ShowBreadCrumb from "./BreadCrumb";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BackAndSaveButton from "pages/Claims/Collision/BackAndSaveButton";
import PaymentMethodIcon from "assets/Images/PaymentMethodIcon.svg"

import { Breadcrumbs } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

const breadcrumbItems = [
    { label: "Bills", href: "/bills" },
    { label: "Make Payment", href: "/select-payment-method" },
  ];

  const allCardData = [{id:1,cardName:"Debit Card/Credit Card"},{id:2,cardName:"ACH"}]
  


const SelectMethod = ()=>{
    const navigate = useNavigate();
  const location = useLocation();
    const [selectedCard, setSelectedCard]=useState<null | number>(null)
    const { userEnteredAmount } =
    location.state || {};

    const handleChange =(e:any)=>{
      setSelectedCard(e.target.value)
        console.log("clicked",e.target.value)
    }


    const handleClick=()=>{
        selectedCard == 1 ?  navigate("/add-card",{
          state: {  userEnteredAmount },
        }) : navigate("/direct-debit", {
          state: { userEnteredAmount },
        })
    
    }

    const navigateToPrevScreen =()=>{
       navigate("/bills")
    }


    return (
        <div>
          <Grid item xs={12}>
            <Breadcrumbs className="mt-4" aria-label="breadcrumb">
              {breadcrumbItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.href}
                  className={index === breadcrumbItems.length - 1 ? "lastLink" : ""}
                  style={
                    index === breadcrumbItems.length - 1
                      ? { outline: "none", color: "#BB3330" , fontWeight:"600"}
                      : { outline: "none" , textDecoration:"none", color:"#545454"}
                  }
                >
                  {item.label}
                </Link>
              ))}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Typography className="font-semibold text-lg leading-7 mt-5">
              Select Payment Method
            </Typography>
          </Grid>
          <FormControl fullWidth>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={selectedCard}
              onChange={handleChange}
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 17,
                },
              }}
            >
              {allCardData?.map((info) => {
                const { id, cardName } = info;
                return (
                  <div
                    key={id}
                    className="border-solid border-gray-100 rounded-lg p-4 mt-5"
                  >
                    <div className="flex justify-between items-center">
                      <FormControlLabel
                        value={id}
                        control={<Radio />}
                        label={
                          <Typography
                            fontSize={14}
                            fontWeight={600}
                          >
                            {cardName}
                          </Typography>
                        }
                      />
                      {/* {cardName=== "Debit Card/Credit Card" &&  <div className="flex items-center justify-center gap-2">
                        <img width="60px" src={PaymentMethodIcon} alt=""/>
                      </div>} */}
                    </div>
                  
                  </div>
                );
              })}
            </RadioGroup>
          </FormControl>
    
          
          <BackAndSaveButton
            isValid={!!selectedCard}
            backButtonHandler={navigateToPrevScreen}
            saveAndNextHandler={handleClick}
            saveAndNextText="Proceed to payment"
          />
        </div>
      );
}

export default SelectMethod;

