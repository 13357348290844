//@ts-nocheck

import React from "react";
import {
  Button,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import InsuranceIcon from "../../assets/Icons/Insurance Icon.svg";
import FarmInsuranceIcon from "../../assets/Icons/Farm Insurance Icon.svg";
import HomeInsuranceIcon from "../../assets/Icons/Home Insurance Icon.svg";
import UmbrellaInsuranceIcon from "assets/Icons/UmbrellaInsuranceIcon.svg";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { FetchBillsAPI } from "queries/BillsQueries";
import { FetchPoliciesAPI, FetchPolicySyncAPI } from "queries/PolicyQuery";
import { FetchClaimsDetailAPI } from "queries/ClaimQueries";

// Color configurations for different claim statuses
const backgroundColors = {
  SUCCESSFUL: "#EDF7F2",
  "IN PROCESS": "#FFF3CC",
  REJECTED: "#FECACA",
};

type colorsKeyType = "SUCCESSFUL" | "IN PROCESS" | "REJECTED";
const colors = {
  SUCCESSFUL: "#07794C",
  "IN PROCESS": "#997400",
  REJECTED: "#FECACA",
};

// Dashboard component for displaying user's policies, bills, and claims
const Dashboard = () => {
  const navigate = useNavigate();

  // Navigate to policy details based on policy number and type
  const navigateToDetails = (policyNumber: any, policyType: string) => {
    navigate(`/policy-details?policyNo=${policyNumber}&type=${policyType}`);
  };

  // Fetching data for policies, bills, and claims
  const { data: policies } = FetchPoliciesAPI();
  const { data: policySync } = FetchPolicySyncAPI();

  const { data: bills } = FetchBillsAPI(1, 50, "ASC", true);
  const { data: claimsData } = FetchClaimsDetailAPI();

  let policyList: any = [];

  // Setting the list of policies if data is available
  if (policies) {
    policyList = policies.data;
  }

  // Calculate the number of days left until a due date
  const calculateDaysLeft = (dueDateString: string) => {
    const dueDate = new Date(dueDateString);
    const currentDate = new Date();
    dueDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    const diffInMilliseconds = dueDate.getTime() - currentDate.getTime();
    const diffInDays = Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24));
    return diffInDays;
  };

  // Format a date into a readable format with a suffix for the day
  function getDaySuffix(day: any) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  function formatDate(dueDateStr: string) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(dueDateStr);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];

    return `${day}${getDaySuffix(day)} ${month}`;
  }

  return (
    <div className="bg-white">
      {/* Main container for the dashboard */}
      <Grid container className=" w-[545px]">
      <Typography
                variant="subtitle1"
                color="text.secondaryInfo"
                className="mt-4 ml-6 font-medium text-16 mb-4"
              >
                My Policies
              </Typography>
        <Grid
          item
          xs={12}
          className="px-4 py-2 flex flex-col justify-center items-center"
        >
          
          {/* Displaying policies if available */}
          {policyList?.length > 0 && (
            <Grid container spacing={2} direction="column" alignItems="stretch">
              {policyList.map((policy: any, index: number) => (
                <ButtonBase className="flex w-[535px]" key={policy.name}>
                  <Grid item className="px-2 container">
                    <Card className="py-2 px-4 shadow-[0px_4px_12px_0px_rgba(15,15,15,0.08)] rounded-lg">
                      <CardContent
                        className="!pb-[1px]"
                        onClick={() =>
                          navigateToDetails(policy.policyNo, policy.type)
                        }
                      >
                        <Grid container>
                          <Grid item className="pt-2 pr-4">
                            {policy.type === "autoPolicy" && (
                              <img src={InsuranceIcon} alt="Auto Insurance"/>
                            )}
                            {policy.type === "homePolicy" && (
                              <img
                                src={HomeInsuranceIcon}
                                alt="Home Insurance"
                              />
                            )}
                            {policy.type === "farmPolicy" && (
                              <img
                                src={FarmInsuranceIcon}
                                alt="Farm Insurance"
                              />
                            )}
                            {policy.type === "umbrellaPolicy" && (
                              <img
                                src={UmbrellaInsuranceIcon}
                                alt="Farm Insurance"
                              />
                            )}
                          </Grid>
                          <Grid item direction="column">
                            <Typography
                              variant="subtitle1"
                              color="text.secondaryInfo"
                              className="font-semibold text-[14px] leading-[20px]"
                              sx={{ textAlign: "initial" }}
                            >
                              {policy.name}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              className="font-normal text-[12px] leading-[20px]"
                            >
                              Policy No. {policy.policyNo}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container alignItems="center" className="mt-2">
                          <Grid
                            container
                            item
                            xs={12}
                            sm={10}
                            direction="row"
                            className="gap-x-4"
                          >
                            <Grid item xs={12} sm={4} direction="column">
                              <Typography
                                color="#757575"
                                className="text-start font-normal text-[12px] leading-[20px]"
                              >
                                Effective through
                              </Typography>
                              <Typography
                                variant="body1"
                                color="#262626"
                                className="text-start font-medium text-[14px] leading-[24px]"
                              >
                                {new Date(
                                  policy.expirationDate
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                              </Typography>
                            </Grid>
                            {policy?.registrationNumber && (
                              <Grid item xs={12} sm={3} direction="column">
                                <Typography
                                  color="#757575"
                                  className="text-start font-normal text-xs"
                                >
                                  Registration No.
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="#262626"
                                  className="text-start font-medium text-14"
                                >
                                  {policy.registrationNumber}
                                </Typography>
                              </Grid>
                            )}

                            {policy?.vechiles?.length > 0 && (
                              <Grid item xs={12} sm={6} direction="column">
                                <Typography
                                  color="#757575"
                                  className="text-start font-normal text-[12px] leading-[20px]"
                                >
                                  Vehicle
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="#262626"
                                  className="text-start font-medium text-[14px] leading-[24px]"
                                >
                                  {policy.vechiles[0].make},{" "}
                                  {policy.vechiles[0].model}{" "}
                                  {policy.vechiles.length > 1 &&
                                    ` +${policy.vechiles.length - 1}`}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                          <Grid container item xs={12} sm={2} direction="row">
                            <Grid item xs={12} sm={12} direction="column">
                              {policy.status === "expired" && (
                                <Typography
                                  variant="body1"
                                  color="#B32306"
                                  style={{
                                    backgroundColor: "#FDF3F2",
                                    textAlign: "center",
                                  }}
                                  className="font-semibold text-sm"
                                >
                                  {policy.status}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </ButtonBase>
              ))}
            </Grid>
          )}
          {bills?.data?.length > 0 && (
            <Grid container spacing={2} alignItems="stretch">
              <Grid xs={12} className="mt-10 pl-2 pr-0">
                <Typography
                  variant="subtitle1"
                  color="text.secondaryInfo"
                  className="mt-4 ml-1 font-medium text-16"
                >
                  Pay Bills
                </Typography>
                <div
                  style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
                  className="px-[1px]"
                >
                  {bills?.data?.map(
                    (
                      {
                        invoiceId,
                        policyNo,
                        policyName,
                        amountDue,
                        dueDate,
                      }: any,
                      index: number
                    ) => (
                      <Card
                        key={policyNo}
                        style={{ display: "inline-block" }}
                        className="w-[297px] h-[134px] mt-2 mr-2 pl-2 pr-0"
                        onClick={() =>
                          navigate("/select-payment-method", {
                            state: {
                              billData: {
                                getBillFromLocation: true,
                                invoiceId,
                                policyNo,
                                amountDue,
                              },
                            },
                          })
                        }
                      >
                        <CardContent>
                          <Grid
                            container
                            spacing={2}
                            className="ml-[-20px] pr-7"
                          >
                            <Grid item xs={12} sm={7.5} direction="column">
                              <Typography className="font-normal text-[14px] leading-[20px] pb-[6px]">
                                Your Insurance Coverage
                              </Typography>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className="font-bold text-[14px] leading-[24px] mb-0 pb-[6px]"
                              >
                                ${amountDue}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                className="font-normal text-[10px] leading-[14px] mb-2"
                              >
                                Policy No. {policyNo}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                className="font-normal text-xs mt-5"
                              >
                                Due Date{" "}
                                {new Date(dueDate).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4.5}
                              direction="column"
                              container
                              justifyContent="flex-end"
                              alignItems="flex-end"
                              className="justify-evenly"
                            >
                              <Button
                                color="primary"
                                className="min-w-[50px] h-[30px] mb-0 border border-[#545454] rounded-[4px]"
                              >
                                <span className="w-[18px] h-[14px] font-semibold text-[10px] leading-[14px]">
                                  Pay
                                </span>
                              </Button>
                              {calculateDaysLeft(dueDate) > 0 ? (
                                <div
                                  style={{
                                    backgroundColor: "#FFF3CC",
                                    textAlign: "center",
                                    borderRadius: "4px",
                                  }}
                                  className="mt-3 h-[24px] w-[83px] justify-center relative top-[14px]"
                                >
                                  <Typography
                                    variant="body1"
                                    color="#997400"
                                    className="w-[67px] h-[14px] font-semibold text-[10px] leading-[14px] pt-[6px] pl-2"
                                  >
                                    {calculateDaysLeft(dueDate)} DAYS LEFT
                                  </Typography>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    backgroundColor: "#FDF3F2",
                                    textAlign: "center",
                                    borderRadius: "4px",
                                  }}
                                  className="mt-3 h-[24px] w-[106px] justify-center "
                                >
                                  <Typography
                                    color="#B32306"
                                    className="w-[67px] h-[14px] font-semibold text-[10px] leading-[14px] pl-[5px] pt-1"
                                  >
                                    Bill Due on {formatDate(dueDate)}
                                  </Typography>
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    )
                  )}
                </div>
              </Grid>
            </Grid>
          )}
          {claimsData?.data?.length > 0 && (
            <Grid
              container
              spacing={2}
              // alignItems="stretch"
              className="mt-2 mb-2 ml-[-5px]  claims-container w-[520px]"
            >
              <Grid item xs={12} className="">
                <Typography
                  variant="subtitle1"
                  color="text.secondaryInfo"
                  className="ml-4 font-medium text-16"
                >
                  Claims
                </Typography>
                <div
                  style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
                  className="px-2"
                >
                  {claimsData?.data?.map(
                    ({
                      name,
                      policyNo,
                      status,
                      amount,
                      claimReferenceNo,
                    }: {
                      name: string;
                      policyNo: string;
                      status: string;
                      claimReferenceNo: string;
                      amount: string;
                    }) => (
                      <Card
                        key={policyNo}
                        style={{ display: "inline-block", minWidth: 320 }}
                        className="m-2 w-[460px] h-[114px]"
                      >
                        <CardContent>
                          <Grid container className="mt-2">
                            <Grid item xs={12} sm={6} direction="column">
                              <Typography
                                variant="body1"
                                color="#333333"
                                className="font-semibold text-base"
                              >
                                {name}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="#757575"
                                className="font-normal text-xs"
                              >
                                Policy No. {policyNo}
                              </Typography>
                              <div
                                style={{
                                  color: colors[status as colorsKeyType] || "",
                                  backgroundColor:
                                    backgroundColors[status as colorsKeyType] ||
                                    "",
                                }}
                                className="h-[24px] w-fit flex justify-start px-2 items-center mt-3"
                              >
                                <Typography
                                  variant="body1"
                                  className=" whitespace-nowrap font-semibold text-[10px] leading-[14px]"
                                >
                                  {status} - {claimReferenceNo}
                                </Typography>
                              </div>
                            </Grid>
                            {amount && status !== "IN PROCESS" && (
                              <Grid item xs={12} sm={6} direction="column">
                                <Typography
                                  color="#545454"
                                  className="font-normal text-xs text-end mr-2"
                                >
                                  Claim Amount
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="#05945B"
                                  className="text-end mr-2"
                                >
                                  {amount}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                    )
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
