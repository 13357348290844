import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import Layout from './components/Layout';
import Routes from './routes';
import PrimaryTheme from './theme/PrimaryTheme';
import { ToastContainer } from 'react-toastify';
import {
  isMobile,
  isAndroid,
  isIOS,
  isMacOs,
  isTablet,
  isDesktop,
} from 'react-device-detect';

function App() {
  // Initialize a QueryClient instance with default options for React Query.
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // Callback function to handle the message payload
        staleTime: Infinity,
      },
    },
  });
  useEffect(() => {
    if (isMobile && !isTablet && !isMacOs && !isDesktop) {
      if (isAndroid) {
        window.location.href =
          'https://play.google.com/store/apps/details?id=com.idfbins.fbhubmobileProd';
      } else if (isIOS) {
        window.location.href =
          'https://apps.apple.com/us/app/fbhub/id1540376285';
      }
    }
  }, []);

  return (
    <div className="App max-h-screen w-full overflow-y-auto">
      {/* Provide the QueryClient to the app using QueryClientProvider. */}
      <QueryClientProvider client={queryClient}>
        {/* StyledEngineProvider is used for customizing Material-UI components. */}
        <StyledEngineProvider injectFirst>
          {/* Apply the theme to the Material-UI components using ThemeProvider. */}
          <ThemeProvider theme={PrimaryTheme}>
            {/* ToastContainer is used to display toast notifications throughout the app. */}
            <ToastContainer />
            {/* Routes component is used to handle routing in the app. */}
            <Routes />
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </div>
  );
}

// Export the App component for use in index.js.
export default App;
