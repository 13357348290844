// Function to format a date string into a more readable format.
export const formatDate = (date?: string) => {
  // Check if a date string is provided. If not, return an empty string.
  if (!date) return "";

  // Convert the input date string into a Date object.
  const inputDate = new Date(date);

  // Array containing month names for conversion.
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // Extract the month index from the Date object.
  const monthIndex = inputDate.getUTCMonth();
  // Format the date into "MMM DD, YYYY" format.
  const formattedDate = `${
    months[monthIndex]
  } ${inputDate.getUTCDate()}, ${inputDate.getUTCFullYear()}`;

  // Return the formatted date string.
  return formattedDate;
};

// Function to format a time string into a 12-hour format with AM/PM.
export const formatTime = (time?: string) => {
  // Check if a time string is provided. If not, return an empty string.
  if (!time) return "";
  // Convert the input time string into a Date object.
  const inputTime = new Date(time);

  // Format the time using Intl.DateTimeFormat in 12-hour format based on 'America/New_York' timezone.
  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "America/New_York",
  }).format(inputTime);

  // Return the formatted time string.
  return formattedTime;
};

// Function to convert a datetime string into a 24-hour format time string.
export const TimeFormatter = (inputDateTime: any) => {
  // Inner function to convert datetime string to 24-hour format.
  const getTimeIn24HourFormat = (inputDateTime: string): string => {
    // Convert the input string to a Date object.
    const date = new Date(inputDateTime);

    // Format the time part of the Date object to 24-hour format using 'en-US' locale.
    const time = date.toLocaleTimeString("en-US", { hour12: false });

    // Return the formatted time.
    return time;
  };

  // Call the inner function with the provided datetime string.
  const formattedTime = getTimeIn24HourFormat(inputDateTime);

  // Return the formatted time in 24-hour format.
  return formattedTime;
};
