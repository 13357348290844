// Importing necessary icons and components from Material-UI
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
// Importing React hooks
import { forwardRef, useState } from 'react';

// Importing types and the LabelledTextField component
import type { ICustomTextFieldProps } from './index';
import LabelledTextField from './index';

/**
 * PasswordField is a functional component that extends the LabelledTextField
 * for password input with the ability to toggle password visibility.
 */
const PasswordField = forwardRef(
  // Destructuring the custom text field properties and setting the default type as 'text'
  ({ type = 'text', ...props }: ICustomTextFieldProps, ref) => {
    // State to toggle password visibility
    const [showPassword, setShowPassword] = useState(false);

    // Handler function to toggle the state of password visibility
    const handleToggleShowPassword = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    // Rendering the LabelledTextField with password visibility toggle feature
    return (
      <LabelledTextField
        type={showPassword ? 'text' : 'password'} // Toggling between text and password types
        {...props}
        InputProps={{
          // Adding an end adornment for the visibility toggle button
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleToggleShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...{ ref }}
      />
    );
  }
);

// Setting a display name for the PasswordField component
PasswordField.displayName = 'PasswordField';

// Default props for the PasswordField component
PasswordField.defaultProps = {
  label: '', // Default label
  variant: 'standard', // Default variant
  ref: null, // Default ref
  type: 'text', // Default type
  error: false, // Default error state
  helperText: '', // Default helper text
  className: '', // Default className
  inputRef: null, // Default input reference
  required: false, // Default required state
};

// Exporting the component
export default PasswordField;
