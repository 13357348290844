// Importing necessary components from Material-UI
import { Grid, TextFieldProps as ITextFieldProps, Typography } from '@mui/material';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { forwardRef } from 'react';

// const BootstrapInput: any = styled(TextField)(({ theme }) => ({}));

/**
 * for hide / show password type input : use "PasswordField" component
 */


// Interface extending Material-UI's TextFieldProps with additional custom properties
export type ICustomTextFieldProps = ITextFieldProps & {
  capitalize?: boolean; // Optional boolean to apply text capitalization
  label?: any; // Optional label for the text field
  variant?: 'standard' | 'outlined' | 'filled' | undefined; // Variant of the text field
  placeholder?: string; // Placeholder text
  required?: boolean; // Indicates if the field is required
  autoComplete?: string; // Autocomplete attribute for the text field
  maxWidth?: string; // Maximum width of the text field
  helperText?: any; // Helper text for additional information or error message
  endAdornment?: ReactNode; // Optional end adornment for the text field
  multiline?: boolean; // Enables multiline input
  minRows?: any; // Minimum number of rows for multiline input
  labelText?: string; // Text label displayed above the text field
  ref?: any; // Ref forwarding
  onChange?: (event: any) => void; // Handler for change events
};


const LabelledTextField: FC<ICustomTextFieldProps> = forwardRef((props, ref) => {
  // Destructuring props to extract relevant properties
  const {
    variant, // Variant of the text field
    type, // Type of the text field (e.g., 'text', 'password')
    error, // Error state of the text field
    helperText, // Helper text (usually for displaying errors)
    className, // Additional CSS class names
    maxWidth, // Maximum width
    size, // Size of the text field
    disabled, // Disabled state
    capitalize, // Apply capitalization
    multiline, // Multiline input
    minRows, // Minimum number of rows for multiline
    labelText, // Label text
    InputProps = {}, // Additional props for the input element
    ...restOfTextFieldProps // Remaining properties
  } = props;

  // Render the LabelledTextField component
  return (
    <FormControl
      variant={variant} // Setting the variant of the FormControl
      className={`${className} ${maxWidth || 'w-full'}`} // Setting custom and maximum width classes
      color="primary" // Setting the primary color theme
    >
      {/* Typography component for displaying the label text */}
      <Typography color="#333333" className='font-normal text-xs leading-4'>
        {labelText}
      </Typography>

      {/* TextField component for the input field */}
      <TextField
        InputProps={InputProps} // Additional input properties
        fullWidth // Full width styling
        type={type} // Type of the input
        id="bootstrap-input" // ID for the input
        margin="dense" // Dense margin for compact appearance
        className={`!rounded-lg  ${capitalize ? 'capitalize' : ''} disabled:bg-gray-300`} // Applying conditional classes
        {...restOfTextFieldProps} // Spreading the rest of the text field properties
        inputRef={ref} // Ref forwarding
        size={size} // Size of the input
        multiline={multiline} // Multiline input
        minRows={minRows} // Minimum rows for multiline
        disabled={disabled} // Disabled state
        color="primary" // Color theme
        error={Boolean(error)} // Error state
      />

      {/* Conditionally rendering FormHelperText for displaying error or helper messages */}
      {Boolean(error) && (
        <FormHelperText
          error={Boolean(error)}
          variant={variant}
          className="ml-1 mt-[-1]"
        >
          {helperText || ' '}
        </FormHelperText>
      )}
    </FormControl>
  );
});

// Setting a display name for the component
LabelledTextField.displayName = 'LabelledTextField';

// Default props for the LabelledTextField component
LabelledTextField.defaultProps = {
  label: '',
  variant: 'standard',
  type: 'text',
  error: false,
  helperText: '',
  className: '',
  inputRef: null,
  size: 'medium',
  disabled: false,
  required: false,
};

// Exporting the component
export default LabelledTextField;
