import { useState, useEffect } from "react";

/**
 * Custom hook to create a debounced value.
 * 
 * This hook is useful for delaying the update of a value until after 
 * a certain amount of time has passed since the last change. This is 
 * particularly beneficial for performance optimization in scenarios 
 * such as search input where you don't want to trigger a function 
 * (like an API call) on every keystroke, but rather when the user 
 * has stopped typing.
 */
export function useDebounceValue(value: string, delay: number) {
  // State for the debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  // Effect to update the debounced value after the specified delay
  useEffect(() => {
    // Set up a timeout to update the debounced value
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Clear the timeout if the value or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  // Return the debounced value
  return debouncedValue;
}
