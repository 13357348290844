import { Grid, Typography, Card, CardContent } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OtherImg from "assets/Images/OtherImg.svg";
import ImpactWithAnAnimal from "assets/Images/ImpactWithAnAnimal.svg"
import CollisionImg from "assets/Images/CollisionImg.svg";
import BrokenGlassImg from "assets/Images/BrokenGlassImg.svg";
import TheftImg from "assets/Images/TheftImg.svg";
import ShowBreadCrumbs from "../Collision/BreadCrumbs";
import BackAndSaveButton from "../Collision/BackAndSaveButton";
import useQueryParams from "utils/useQueryParams";
import { FetchClaimsDraftAPI, UpdateClaimDraftAPI } from "queries/ClaimQueries";

// Options for the different types of claims
const claimOptions = [
  { image: CollisionImg, text: "Impact with an Object", value: "Collision and Rollover", route: '/collision-details' },
  { image: TheftImg, text: "Theft", value: "Theft", route: '/select-theft-type' },
  { image: ImpactWithAnAnimal, text: "Impact with an Animal", value: "Impact with an Animal", route: '/collision-details' },
  { image: BrokenGlassImg, text: "Report Glass or Windshield Damage", value: "Broken Glass", route: '/other-claim-success' },
  { image: OtherImg, text: "Other", value: "Other", route: '/other-claim-success' },
];

// SelectClaim component for choosing the type of claim
const SelectClaim = () => {
  // Retrieving query parameters
  const { policyId, claimId, draftId } = useQueryParams(['policyId', 'claimId', 'draftId']);
  
  // State to track the selected claim type
  const [claimType, setClaimType] = useState<string | null>(null);
  
  // Mutation function for updating claim draft
  const { mutate: draftData } = UpdateClaimDraftAPI();

  const { data: claimsDraftDetail } = FetchClaimsDraftAPI(claimId || "");
  const { data: claimsDraftDetailData } = claimsDraftDetail || {};

  console.log({claimsDraftDetailData})
  
  // Navigation hook
  const navigate = useNavigate();
  
  // Function to navigate to the claim reporting page
  const navigateToClaim = () => {
    navigate(`/report-claim`);
  };

  // Function to navigate to the detail screen based on selected claim type
  const navigateToDetailScreen = () => {
    if (!claimType) return;
    const type = claimType.toLowerCase().split(" ").join("_");
    
    // Finding the route for the selected claim type
    const route = claimOptions.find((item) => item.value === claimType)?.route;

    // Updating the claim draft and navigating to the respective route
    draftData(
      {
        step: 2,
        policyId,
        claimType: claimType,
        draftId: draftId || claimsDraftDetailData?.draftId,
        hasUnknownTime: claimsDraftDetailData?.hasUnknownTime
      },
      {
        onSuccess: () => {
          navigate(`${route}${window.location.search}&claimType=${type}`);
        },
      }
    );

    if (!route) return;
  };

  return (
    <div className=" w-[492px]">
      {/* Breadcrumbs for navigation */}
      <ShowBreadCrumbs />

      {/* Claim type selection instruction */}
      <Grid item xs={12} >
        <Typography className="font-semibold text-lg leading-7 mt-5">
          Tell us what happened
        </Typography>
      </Grid>

      {/* Grid for displaying claim type options */}
      <Grid
        container
        className="w-[500px] mb-4 mt-4 rounded-lg "
        spacing={{ xs: 2, md: 3 }}
        // columns={{ xs: 4, sm: 8, md: 12 }}
        rowGap={2}
      >
        {/* Mapping through claimOptions to render each claim type as a card */}
        {claimOptions.map(({ image, text, value }) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              className="w-[290px] h-[146px]"
              key={image}
            >
              <Card
                onClick={() => {
                  setClaimType(value);
                }}
                className={`w-[239px] h-[154px] border border-[#F5F5F5] border-solid rounded-lg shadow-none cursor-pointer ${
                  claimType === value &&
                  "bg-red-50 border-solid border-red-500 hover:bg-red-50"
                }`}
              >
                <CardContent className="h-full flex-col justify-center items-center">
                  <div className="flex items-center justify-center mb-4">
                    <img src={image} alt="" />
                  </div>
                  <Typography
                    variant="subtitle1"
                    color="slate.900"
                    className="font-medium text-[16px] leading-[28px] text-center"
                  >
                    {text}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* Back and save buttons for navigation and submission */}
      <BackAndSaveButton
        isValid={!!claimType}
        backButtonHandler={navigateToClaim}
        saveAndNextHandler={navigateToDetailScreen}
      />
    </div>
  );
};

export default SelectClaim;
