import React, { useEffect } from "react";
import calender from "assets/Images/calendar.svg";
import clock from "assets/Images/clock.svg";
import phone from "assets/Images/phone.svg";
import markerPin from "assets/Images/markerPin.svg";
import CollisionImg from "assets/Images/CollisionImg.svg";
import ImpactWithAnAnimal from "assets/Images/ImpactWithAnAnimal.svg";
import TheftImg from "assets/Images/TheftImg.svg";
import { AccordionSummary, Typography, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ShowBreadCrumbs from "./BreadCrumbs";
import BackAndSaveButton from "./BackAndSaveButton";
import {
  FetchClaimsDetailAPI,
  FetchClaimsDraftAPI,
  PostCollisionDetailsApi,
  PostTheftApi,
} from "queries/ClaimQueries";
import useQueryParams from "../../../utils/useQueryParams";
import { formatDate, formatTime } from "../../../utils/formatDate";
import HandleErrorResponse from "helper/HandleErrorResponse";
import { getLocalStorageItem } from "hooks/localStorageHelper";

const claimOptions: { [key: string]: string } = {
  collision_and_rollover: "Impact with an Object",
  impact_with_an_animal: "Impact with an Animal",
  theft: "Theft",
  broken_glass: "Broken Glass",
  other: "Other",
};

// Summary component to display and finalize claim details
const Summary: React.FC = () => {
  // Extracting necessary query parameters
  const { policyId, claimId, claimType, policyNo } = useQueryParams([
    "policyId",
    "claimId",
    "claimType",
    "policyNo",
  ]);

  // Fetching claims details and draft data using custom hooks
  const { data: claimsDraftDetail } = FetchClaimsDraftAPI(claimId || "");
  const { data: claimsData } = FetchClaimsDetailAPI();

  // Logging the draft details for debugging
  console.log(claimsDraftDetail?.data, "claimsDraftDetail");
  const { data: claimsDraftDetailData } = claimsDraftDetail || {};

  // Mutation functions to post collision and theft details
  const { mutate: draftData } = PostCollisionDetailsApi();
  const { mutate: draftTheftData } = PostTheftApi();

  // Extracting witness details from claims data
  const { city, state, primaryPhone } = claimsDraftDetail?.data?.witness || {};

  // let unknownTime = getLocalStorageItem('unknownTime');
  // console.log(unknownTime)
  // const {hasUnknownTime, claimId: storedClaimId} = unknownTime || {}

  // Preparing detailed information for display
  const details = [
    { icon: calender, text: formatDate(claimsDraftDetail?.data?.dateOfLoss) },
    {
      icon: clock,
      text: claimsDraftDetail?.data?.hasUnknownTime
        ? ""
        : formatTime(claimsDraftDetail?.data?.dateOfLoss),
    },
    { icon: markerPin, text: `${city}, ${state}, United States` },
    { icon: phone, text: `${primaryPhone}` },
  ];

  // Navigate hook for routing
  const navigate = useNavigate();

  // Function to navigate to the previous screen
  const navigateToPrevScreen = () => {
    navigate(`/contact-details${window.location.search}`);
  };

  // Effect hook to redirect if necessary data is missing
  useEffect(() => {
    console.log({ policyId, claimId, claimsDraftDetailData });
    if (!policyId || !claimId || claimsDraftDetailData === null) {
      navigate("/claims");
    }
  }, [claimId, navigate, policyId, claimsDraftDetailData]);

  // Function to handle the final claim draft submission
  const handlePostClaimDraftAPI = () => {
    let updatedClaimsDraftDetailData = JSON.parse(
      JSON.stringify(claimsDraftDetailData)
    );
    updatedClaimsDraftDetailData.offeringCode = "City";
    delete updatedClaimsDraftDetailData.step;
    delete updatedClaimsDraftDetailData.status;
    delete updatedClaimsDraftDetailData.claimId;

    updatedClaimsDraftDetailData = {
      ...updatedClaimsDraftDetailData,
      primaryContact: {
        address: claimsDraftDetailData?.locationAddress,
      },
    };

    if (claimType === "theft") {
      updatedClaimsDraftDetailData = {
        ...updatedClaimsDraftDetailData,
        // involvedVehicle it should spread vehicles data but as right now we are not taking vehicles data in flow so passing empty object
        involvedVehicle: updatedClaimsDraftDetailData?.involvedVehicle,
      };
      delete updatedClaimsDraftDetailData.vehicles;

      draftTheftData(
        {
          // hasUnknownTime: storedClaimId === claimId ? hasUnknownTime : "",
          ...updatedClaimsDraftDetailData,
        },
        {
          onSuccess: (data) => {
            navigate(
              `/claim-success?referenceNo=${data?.data?.claimReferenceNo || ""}`
            );
          },
          onError: (response) => HandleErrorResponse(response),
        }
      );
    } else if (
      claimType === "collision_and_rollover" ||
      claimType === "impact_with_an_animal"
    ) {
      draftData(
        {
          // hasUnknownTime: storedClaimId === claimId ? hasUnknownTime : "",
          ...updatedClaimsDraftDetailData,
        },
        {
          onSuccess: (data) => {
            console.log(data?.data?.claimReferenceNo, "allData");
            navigate(
              `/claim-success?referenceNo=${data?.data?.claimReferenceNo || ""}`
            );
          },
          onError: (response) => HandleErrorResponse(response),
        }
      );
    }
  };

  const claimTypeText =
    claimsDraftDetail?.data?.claimType?.toLowerCase()?.split(" ")?.join("_") ||
    "";

  return (
    <div>
      {/* Breadcrumbs for navigation */}
      <ShowBreadCrumbs />

      {/* Main grid layout for the summary */}
      <Grid item xs={12}>
        {/* Accordion Summary for page heading */}
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontWeight={600} fontSize={18} marginTop={3}>
            Summary
          </Typography>
        </AccordionSummary>
      </Grid>

      {/* Container for summary details */}
      <Grid item xs={12} marginLeft={-1}>
        <Container className="mt-2">
          <Typography fontSize={14} fontWeight={400}>
            Please review the following information before submitting.
          </Typography>
          <div className="flex justify-between">
            <div>
              <Typography fontSize={14} fontWeight={400} marginTop={3}>
                Type of incident
              </Typography>
              <Typography fontWeight={600} fontSize={18} marginTop={1}>
                {claimOptions[claimTypeText] || ""}
              </Typography>
            </div>
            <img
              width={"130px"}
              src={
                claimType === "impact_with_an_animal"
                  ? ImpactWithAnAnimal
                  : claimType === "theft"
                  ? TheftImg
                  : CollisionImg
              }
              alt="img"
            />
          </div>
          {/* <div className="bg-gray-100 h-fit w-32 px-3 my-4 rounded-sm py-1">
            <Typography fontSize={12} fontWeight={600} color={"grey.700"}>
              NEW CLAIM:584587
            </Typography>
          </div> */}
          <div className="bg-error-100 h-fit w-fit px-3 my-4 rounded-sm py-1">
            <Typography fontSize={12} fontWeight={600} color={"#BB3330"}>
              Policy Number : {policyNo}
            </Typography>
          </div>
          <div className="bg-gray-100 border-solid border-gray-400  w-11/12 px-3 my-4 rounded-lg py-1">
            {details.map((elm, idx) => {
              if (!elm.text) return null;
              return (
                <div key={idx} className="flex gap-2 my-4 mx-2">
                  <img src={elm.icon} alt="img" />
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    className="text-gray-600"
                  >
                    {elm.text}
                  </Typography>
                </div>
              );
            })}
          </div>

          <BackAndSaveButton
            backButtonHandler={navigateToPrevScreen}
            saveAndNextHandler={handlePostClaimDraftAPI}
            isValid={true}
          />
        </Container>
      </Grid>
    </div>
  );
};

export default Summary;
