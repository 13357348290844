import React, { useRef, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";

// Type definition for location data
export type LocationType = {
  internalId: number;
  memberBenefit: string;
  categoryName: string;
  companyName: string;
  contactPerson: string;
  latitude: number;
  longitude: number;
  phone: number | string;
  street: string;
  state: string | number;
  city: string;
  zipCode: number;
  code: string;
  synced: any;
  lastSyncedAt: any;
};

// Style for the map container
const containerStyle = {
  width: "484px",
  height: "500px",
};

// MapContainer component to render a Google Map with markers
function MapContainer({ locations }: { locations: LocationType[] }) {
  // Loading the Google Maps script
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
  });

  // State to track the selected marker
  const [selectedMarker, setSelectedMarker] = useState<LocationType | null>(
    null
  );

  // Initial center of the map
  const [center, setCenter] = useState<any>({
    lat: locations[0]?.latitude || 36.22710405212577,
    lng: locations[0]?.longitude || -115.15267981846368,
  });

  // Ref to store the map instance
  const mapRef = useRef(null);

  // Function to handle map load event
  const onMapLoad = (mapInstance: any) => {
    mapRef.current = mapInstance;
  };

   // const [map, setMap] = React.useState(null);

  // const onLoad = React.useCallback(function callback(map: any) {
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);

  //   setMap(map);
  // }, []);

  // const onUnmount = React.useCallback(function callback(map: any) {
  //   setMap(null);
  // }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onMapLoad}
    >
      {/* Mapping through locations to create markers */}
      {locations?.map((location, i) => (
        <>
          <Marker
            onClick={() => {
              setCenter({
                lat: location.latitude,
                lan: location.longitude,
              });
              setSelectedMarker(location);
            }}
            key={i}
            position={{ lat: location.latitude, lng: location.longitude }}
          />
          {/* Displaying an info window for the selected marker */}
          {selectedMarker?.internalId === location.internalId ? (
            <InfoWindow
              key={i}
              position={{
                lat: selectedMarker.latitude,
                lng: selectedMarker.longitude,
              }}
              onCloseClick={() => setSelectedMarker(null)}
              options={{
                pixelOffset: new window.google.maps.Size(0, -40),
              }}
              onUnmount={() => setSelectedMarker(null)}
            >
              <div className="flex flex-col gap-1">
                <span className="capitalize text-sm font-medium">
                  {selectedMarker.companyName} - {selectedMarker.categoryName}
                </span>
                <span>
                  {selectedMarker.city}, {selectedMarker.state}
                </span>
                <span className="w-full h-[1px] bg-black/50"></span>
                <p className="m-0">
                  {selectedMarker?.phone && (
                    <span>Call - {selectedMarker.phone} , </span>
                  )}
                  {selectedMarker?.code && (
                    <span>Code - {selectedMarker.zipCode}</span>
                  )}
                </p>
              </div>
            </InfoWindow>
          ) : null}
        </>
      ))}
    </GoogleMap>
  ) : (
    <></> // Render nothing until the map script is loaded
  );
}

export default MapContainer;
