import { Button, Dialog, DialogTitle, Typography } from "@mui/material";
import closeImg from "assets/Images/close.svg"

type IOtherPassengerModal = {
  open: boolean;                 // Indicates whether the modal is open or closed
  handleClose: () => void;       // Callback function to close the modal
  heading: string;               // Heading text displayed in the modal
  subHeading: string;            // Subheading text displayed in the modal
  image: string;                 // Image displayed in the modal
  handleYes: () => void;         // Callback function when the "Yes" button is clicked
  handleNo: () => void;          // Callback function when the "No" button is clicked
}

const OtherPassengerModal = ({
  open,
  handleClose,
  image,
  heading,
  subHeading,
  handleYes,
  handleNo
}: IOtherPassengerModal) => {

  return (
    <div >
      <Dialog
        open={open}
        maxWidth="xs"
        sx={{
          zIndex: "9999999",
        }}
      >
        <DialogTitle id="alert-dialog-title" bgcolor={"natural.100"}>
          <div className="flex justify-end">
            <img
              src={closeImg}
              alt="close"
              onClick={handleClose}
              className="cursor-pointer"
            />
          </div>
          <div className="flex flex-col justify-center items-center gap-4">
            <img src={image} alt="carImage" />
            <Typography fontSize={18} fontWeight={500} color="gray.500">
              {heading}
            </Typography>
            <Typography textAlign={"center"}>
              {subHeading}
            </Typography>
            <Button fullWidth onClick={handleYes} className="hover:bg-gray-950 hover:text-gray-25">Yes</Button>
            <Button fullWidth onClick={handleNo} className="hover:bg-gray-950 hover:text-gray-25">No</Button>
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default OtherPassengerModal;
