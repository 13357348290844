function getPrevMonthAndYear(expiryDate: string) {
  // Parse MM/YY string
  const [year, month] = expiryDate.split("-").map(Number) as [number, number];

  // Adjust to the last month and last year
  const prevMonth = month === 1 ? 12 : month - 1;
  const prevYear = month === 1 ? year - 1 : year;
  
  const finalYear = prevYear.toString().slice(2);

  return `${prevMonth}/${finalYear}`;
}

export default getPrevMonthAndYear;



// Examples
//  console.log(getPrevMonthAndYear('2036-11-01T00:00:00.000Z')); // 11/36 Output: ""
// console.log(getPrevMonthAndYear('2043-01-01T00:00:00.000Z')); // 12/42 Output: ""
// console.log(getPrevMonthAndYear('2031-01-01T00:00:00.000Z')); // 12/30 Output: ""