import { Breadcrumbs, Button, Grid, Link } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import {
  FetchCategoriesAPI,
  FetchCategoryBenefitsAPI,
} from "queries/BenefitsQueries";
import SearchIcon from "assets/Icons/search-md.svg";
import MapContainer from "./MapContainer";
import { useJsApiLoader } from "@react-google-maps/api";
import { useDebounceValue } from "./useDebounce";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// Style for the category buttons
const btnStyle = {
  height: "32px",
  padding: "8px 12px",
  borderRadius: "32px",
  border: "1px solid #000",
  gap: "8px",
  fontSize: "13px",
  lineHeight: "16px",
  margin: "4px",
};

// MapMyBenefit component for displaying benefits on a map
const MapMyBenefit = () => {
  // Breadcrumb navigation items
  const breadcrumbItems = [
    { label: "Home", href: "/dashboard" },
    { label: "Benefits", href: "/benefits" },
    { label: "Map My Benefit", href: "/benefits/map-benefits" },
  ];

  // State for query parameters
  const [query, setQuery] = useState({
    page: 1,
    pageSize: 50,
    sort: "ASC",
    column: "",
    search: "",
    categoryId: "",
  });

  // State for search input value and debounced value
  const [searchValue, setSearchValue] = useState("");
  const debounceSearch = useDebounceValue(searchValue, 500);

  // Fetching categories and benefits data
  const { data: categoriesList } = FetchCategoriesAPI();
  const { data: benefits } = FetchCategoryBenefitsAPI(query);

  // State for selected category button
  const [selectedButton, setSelectedButton] = useState("All");

  // Handles category button click
  const handleButtonClick = (option: any) => {
    setSelectedButton(option);
  };

  // Update the query when search input changes
  useEffect(() => {
    setQuery({ ...query, search: debounceSearch });
  }, [debounceSearch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* Breadcrumb navigation */}
        <Breadcrumbs
          className="mt-4 ml-6"
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbItems.map((item, index) => (
            <Fragment key={item.href}>
              {index === breadcrumbItems.length - 1 ? (
                <Link
                  href={item.href}
                  className="lastLink"
                  style={{
                    outline: "none",
                    color: "#BB3330",
                    fontWeight: "600",
                    textDecorationColor: "#bb3330",
                  }}
                >
                  {item.label}
                </Link>
              ) : (
                <Link
                  href={item.href}
                  style={{
                    outline: "none",
                    textDecoration: "none",
                    color: "#545454",
                  }}
                >
                  {item.label}
                </Link>
              )}
            </Fragment>
          ))}
        </Breadcrumbs>
      </Grid>
      <Grid container className="w-[484px] ml-4 mt-2">
        <Grid item>
          {/* Search input */}
          <div className="relative w-[484px] gap-8 mt-1">
            <input
              type="text"
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search name, city, zip"
              className="border border-solid border-[#000] pl-10 px-2 py-1 w-[430px]  h-[24px]"
            />
            <div className="absolute left-2 top-0 mt-1">
              <img src={SearchIcon} alt="Search" />
            </div>
          </div>
        </Grid>
        <Grid item className="h-fit gap-8 mt-1">
          {/* Category buttons */}
          <Button
            style={btnStyle}
            variant={selectedButton === "All" ? "contained" : "outlined"}
            onClick={() => {
              setQuery({ ...query, categoryId: "" });

              handleButtonClick("All");
            }}
          >
            All
          </Button>
          {categoriesList?.data?.map(
            (category: { id: string; name: string }) => (
              <Button
                key={category.id}
                style={btnStyle}
                variant={
                  selectedButton === category.name ? "contained" : "outlined"
                }
                onClick={() => 
                  {
                    setQuery({ ...query, categoryId: category.id });
                    handleButtonClick(category.name);
                  }}
              >
                {category.name}
              </Button>
            )
          )}
          {/* <Button
            style={btnStyle}
            variant={selectedButton === "Travel" ? "contained" : "outlined"}
            onClick={() => handleButtonClick("Travel")}
          >
            Travel
          </Button>
          <Button
            style={btnStyle}
            variant={
              selectedButton === "Agriculture" ? "contained" : "outlined"
            }
            onClick={() => handleButtonClick("Agriculture")}
          >
            Agriculture
          </Button>
          <Button
            style={btnStyle}
            variant={selectedButton === "Health" ? "contained" : "outlined"}
            onClick={() => handleButtonClick("Health")}
          >
            Health
          </Button>
          <Button
            style={btnStyle}
            variant={selectedButton === "Farmstyle" ? "contained" : "outlined"}
            onClick={() => handleButtonClick("Farmstyle")}
          >
            Farmstyle
          </Button>
          <Button
            style={btnStyle}
            variant={selectedButton === "Sports" ? "contained" : "outlined"}
            onClick={() => handleButtonClick("Sports")}
          >
            Sports
          </Button> */}
        </Grid>
      </Grid>

      <Grid item>
        {/* Map container displaying the benefits locations */}
        {benefits?.data ? <MapContainer locations={benefits.data} /> : <></>}
      </Grid>
    </Grid>
  );
};

export default MapMyBenefit;
