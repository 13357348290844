//@ts-nocheck
import { yupResolver } from "@hookform/resolvers/yup";
import { Container } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormValidationSchemas } from "constants/FormValidationSchemas";
import * as yup from "yup";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FetchClaimsDraftAPI,
  FetchStateDetailAPI,
  UpdateClaimDraftAPI,
} from "queries/ClaimQueries";
import BackAndSaveButton from "./BackAndSaveButton";
import SelectDropDownItem from "./SelectDropDownItem";
import InputItem from "./InputItem";
import { ClaimTypeEnums, IPartialAddress } from "../types";
import useQueryParams from "../../../utils/useQueryParams";
import { ClaimSteps } from "../constants";

// Define the validation schema for the form using Yup
const { city, stateCollision: state } = FormValidationSchemas;

const CollisionFormSchema = yup.object().shape({
  city,
  state,
});

// Functional component for handling partial addresses in collision claims
const PartialAddress: React.FC = () => {
  const navigate = useNavigate();

  // Retrieve query parameters for policyId, claimId, and claimType
  const { policyId, claimId, claimType } = useQueryParams([
    "policyId",
    "claimId",
    "claimType",
  ]);

  // Fetch state details and claims draft data using custom hooks
  const { data: state } = FetchStateDetailAPI();
  const { mutate: draftData } = UpdateClaimDraftAPI();

  const { data: claimsDraftDetail } = FetchClaimsDraftAPI(claimId || "");
  const { data: claimsDraftDetailData } = claimsDraftDetail || {};

  // Initialize form handling with react-hook-form
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
     // mode: 'onBlur',
    resolver: yupResolver(CollisionFormSchema),
    defaultValues:{
      state:"ID"
    }
  });

  // Function to navigate to the previous screen
  const navigateToPrevScreen = () => {
    navigate(
      `/select-claim${window.location.search}`
      );
  };

  // Function to handle the submission and update of claim drafts
  const handleUpdateClaimDraftAPI = () => {
    const { city,state } = getValues();
    draftData(
      {
        // Data structure for the update API call
        step: ClaimSteps[claimType as ClaimTypeEnums]["collision-details"],
        policyId,
        draftId: claimsDraftDetailData?.draftId,
        hasUnknownTime: claimsDraftDetailData?.hasUnknownTime,
        locationAddress: {
          city,
          state,
        },
      },
      {
        onSuccess: () => {
          navigate(
            `/describe-incident${window.location.search}`
            );
        },
      }
    );
  };

  // Redirect if necessary data is missing or incorrect
  useEffect(() => {
    // if policyId or claimId doesn't exist or claimId is not correct in that case we are getting claimsDraftDetailData null
    if (!policyId || !claimId || claimsDraftDetailData === null) {
      navigate("/claims");
    }
  }, [claimId, navigate, policyId, claimsDraftDetailData]);

  // Populate form fields with existing data on component mount or update
  useEffect(() => {
    if (
      claimsDraftDetailData && claimsDraftDetailData.locationAddress) {
      for (let key in claimsDraftDetailData.locationAddress) {
        if (
          key === "state" &&
          claimsDraftDetailData.locationAddress[key] === ""
        ) {
          setValue(key, "-- Select --");
          continue;
        }
        console.log("claimsDraftDetailData.locationAddress", {
          key,
          value: claimsDraftDetailData.locationAddress[key],
        });
        setValue(
          key as keyof IPartialAddress,
          claimsDraftDetailData.locationAddress[key] || ""
        );
      }
    }
  }, [claimsDraftDetailData, setValue]);

  // Render the form UI
  return (
    <div className="-ml-5 mt-4">
      <Container className="mx-auto mt-4">
        <form
          onSubmit={handleSubmit(handleUpdateClaimDraftAPI)}
          className="space-y-4"
        >
          {/* Input fields for city and state with validation */}
          <InputItem
            label="City"
            placeholder="Enter City"
            control={control}
            inputName="city"
            errors={errors}
            xs={12}
            isMandatory={true}
          />
          <SelectDropDownItem
            label="State"
            control={control}
            name="state"
            errors={errors}
            data={state?.data}
          />

          {/* Back and Save buttons for form navigation and submission */}
          <BackAndSaveButton
            isValid={isValid}
            backButtonHandler={navigateToPrevScreen}
            saveAndNextHandler={handleSubmit(handleUpdateClaimDraftAPI)}
          />
        </form>
      </Container>
    </div>
  );
};

export default PartialAddress;
