//@ts-nocheck

// Importing necessary React hooks, components, and utility functions
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddPersons from "./AddPersons";
import { FetchClaimsDraftAPI, UpdateClaimDraftAPI } from "queries/ClaimQueries";
import { ClaimTypeEnums, PassengersEntry } from "../types";
import useQueryParams from "../../../utils/useQueryParams";
import { ClaimSteps } from "../constants";

// Headings based on the claim type
const heading = {
  theft: "Other Witnesses",
  collision_and_rollover: "Other Witnesses",
};

// Component for adding witnesses to a claim
const AddWitness = () => {
  // Navigation hook for programmatically controlling navigation
  const navigate = useNavigate();

  // Extracting query parameters from the URL
  const { policyId, claimId, claimType } = useQueryParams([
    "policyId",
    "claimId",
    "claimType",
  ]);

  // Hook for updating claim draft data
  const { mutate: draftData } = UpdateClaimDraftAPI();

  // Fetching claim draft details
  const { data: claimsDraftDetail } = FetchClaimsDraftAPI(claimId || "");

  // State for managing added witnesses and their selection status
  const [addedPersonsDetails, setAddedPersonsDetails] = useState<
    PassengersEntry[]
  >([]);
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});

  // Extracting claims draft detail data
  const { data: claimsDraftDetailData } = claimsDraftDetail || {};

  // Function to handle item selection (witnesses)
  const handleCheckedItems = (id: number) => {
    const selectedId = addedPersonsDetails.find((item) => item.id === id)?.id;
    if (!selectedId) return;
    const copyOfCheckedItems = { ...checkedItems };
    if (copyOfCheckedItems[selectedId]) {
      delete copyOfCheckedItems[selectedId];
    } else {
      copyOfCheckedItems[selectedId] = true;
    }
    setCheckedItems(copyOfCheckedItems);
  };

  // Function to handle deletion of a witness
  const handleDeleteItem = (id: number) => {
    const copyOfCheckedItems = { ...checkedItems };
    delete copyOfCheckedItems[id];
    setCheckedItems(copyOfCheckedItems);
    setAddedPersonsDetails((prev) => prev.filter((item) => item.id !== id));
  };

  // Function to navigate back to the previous screen or a specific screen based on claim type
  const openAboutDetails = () => {
    if (claimType === "theft") {
      navigate(
        `/describe-incident${window.location.search}`
      );
    } else {
      navigate(-1);
      // navigate(`/about-vehicle${window.location.search}`);
    }
  };

  // Function to navigate to the next page in the claim process
  const openNextPage = () => {
    navigate(
      `/contact-details${window.location.search}`
    );
  };

  // Function to handle the submission of updated claim draft data
  const handleUpdateClaimDraftAPI = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const selectedPeople = [];
    for (let key in checkedItems) {
      let person = addedPersonsDetails.find((item) => item.id === +key);
      selectedPeople.push({
        firstName: person?.firstName,
        lastName: person?.lastName,
        phoneNumber: person?.phoneNumber,
      });
    }
    draftData(
      {
        step: ClaimSteps[claimType as ClaimTypeEnums]["add-witness"],
        policyId,
        draftId: claimsDraftDetailData?.draftId,
        hasUnknownTime: claimsDraftDetailData?.hasUnknownTime,
        witnessesOrParties: {
          entry: selectedPeople,
        },
      },
      {
        onSuccess: () => {
          navigate(
            `/contact-details${window.location.search}`
          );
        },
      }
    );
  };

  // Effect hook for redirecting if necessary data is missing
  useEffect(() => {
    // if policyId or claimId doesn't exist or claimId is not correct in that case we are getting claimsDraftDetailData null
    if (!policyId || !claimId || claimsDraftDetailData === null) {
      navigate("/claims");
    }
  }, [claimId, navigate, policyId, claimsDraftDetailData]);

  // Effect hook to initialize the witness details from the claim data
  useEffect(() => {
    console.log({ claimsDraftDetailData });
    if (claimsDraftDetailData?.witnessesOrParties) {
      const allPassengers = claimsDraftDetailData.witnessesOrParties.entry;
      const updatedAllPassengers = allPassengers.map((item, index) => {
        setCheckedItems((prev) => ({ ...prev, [index + 1]: true }));
        return { ...item, id: index + 1 };
      });
      setAddedPersonsDetails(updatedAllPassengers);
    }
  }, [claimsDraftDetailData]);

  // Function to handle adding a witness in the modal
  const handleAddPassengerModal = (withness: PassengersEntry) => {
    setAddedPersonsDetails((prev) => [...prev, withness]);
  };

  return (
    <Fragment>
      {/* AddPersons component for displaying and managing the list of witnesses */}
      <AddPersons
        addedPersonsDetails={addedPersonsDetails}
        checkedItems={checkedItems}
        handleAddPersonModel={handleAddPassengerModal}
        titleText={heading?.[claimType as string]}
        personType="Witness"
        addPersonText="Add Witness"
        handleCheckedItems={handleCheckedItems}
        handleDeleteItem={handleDeleteItem}
        onBack={openAboutDetails}
        saveAndNextClick={handleUpdateClaimDraftAPI}
      />
    </Fragment>
  );
};

export default AddWitness;
