// Disabling TypeScript checks for the entire file
//@ts-nocheck

// Importing necessary React features and components
import React, { FC, ReactNode } from "react";
import TopNav from "../TopNav";
import Sidebar from "components/Sidebar";
import MyAgent from "components/Agent";
import { useLocation } from "react-router-dom";
import { routeKey, routes } from "routes/RouteConstants";

// Interface for LayoutProps to define the expected shape of props
interface LayoutProps {
  children: ReactNode; // Children elements to be rendered within the layout
}

/**
 * Layout is a functional component that renders the application's layout,
 * including header, sidebar, agent, and footer components based on the current route.
 */
const Layout: FC<LayoutProps> = ({ children }) => {
  // Hook to access the current location object
  const location = useLocation();
  // Fetching page details based on the current route
  const pageDetails = routes[routeKey[location.pathname]] || {};

  // Destructuring properties to determine which components to display
  const {
    showFooter = false,
    showHeader = false,
    showAgent = false,
    isDetails = false,
  } = pageDetails;

  // Constructing CSS class names for the content area
  const contentClasses = [
    "content",
    "grow",
    "overflow-y-auto",
    "bg-[#f7f8fa]",
    showAgent
      ? "max-w-[540px] ml-[30px] mr-[20px]"
      : "max-w-[907px] ml-[30px] mr-[20px",
  ].join(" ");

  // Rendering the layout
  return (
    <div className="g-container mx-auto flex flex-col min-h-screen w-[1440px]">
      {/* Conditional rendering of the Header component */}
      {showHeader && <Header />}
      <div className="flex justify-center w-full flex-grow">
        {/* Conditional rendering of the Sidebar component */}
        {showHeader && <SidebarComponent />}
        {/* Content area where children components are rendered */}
        <div className={contentClasses}>{children}</div>
        {/* Conditional rendering of the Agent component */}
        {showAgent && <AgentComponent pageDetails={pageDetails} />}
      </div>
      {/* Conditional rendering of the Footer component */}
      {showFooter && <Footer />}
    </div>
  );
};

// Header component rendering the TopNav component
const Header = () => (
  <div className="header box-border border-b border-gray-300">
    <TopNav />
  </div>
);

// SidebarComponent renders the Sidebar
const SidebarComponent = () => (
  <div className="max-w-[300px] sidebar mt-3">
    <Sidebar />
  </div>
);

// AgentComponent renders the MyAgent component with page details
const AgentComponent: FC<{ pageDetails: any }> = ({ pageDetails }) => (
  <div className="max-w-[384px]">
    <MyAgent currentPageProps={pageDetails} />
  </div>
);

// Footer component (currently empty)
const Footer = () => (
  <div className="footer">
    <footer />
  </div>
);

// Exporting the Layout component
export default Layout;
