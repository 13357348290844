// Importing necessary components and icons
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@mui/icons-material/';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

// Importing custom CSS module for styling
import Styles from './CommonDialog.module.css';

// Styled component for DialogTitle with custom styling
const StyledCommonDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  margin: '0rem 0rem 0rem 0rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 0),
  // Optional: Uncomment below for a bottom border
  // borderBottom: "1px solid #434349",
}));

// CommonDialogTitle component
const CommonDialogTitle: React.FC<any> = (props) => {
  // Destructuring props to extract necessary values
  const { onClose, title, handleShowContent, showContent } = props;

  return (
    <StyledCommonDialogTitle>
      {/* Back arrow button, shown based on the 'showContent' prop */}
      {showContent && (
        <span className="cursor-pointer" onClick={handleShowContent}>
          <KeyboardArrowLeftIcon sx={{ fontSize: 30 }} />
        </span>
      )}
      {/* Title text */}
      <span className={Styles.dialogTitleText}>{title}</span>
      {/* Close button, shown if the 'onClose' function is provided */}
      {onClose ? (
        <span className="mr-2 flex cursor-pointer items-center justify-center pr-4 mt-6">
          <IconButton onClick={onClose}>
            <CloseIcon style={{ color: '#434349' }} />
          </IconButton>
        </span>
      ) : undefined}
    </StyledCommonDialogTitle>
  );
};

// Default props for the component
CommonDialogTitle.defaultProps = {
  onClose: () => null, // Default function for onClose prop
};

// Exporting the component
export default CommonDialogTitle;
