// Importing necessary React features and Material-UI components
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";

// Importing a custom password field component
import PasswordField from "./Common/LabelledTextField/PasswordField";
// Importing hooks and utilities for form handling and validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormValidationSchemas } from "constants/FormValidationSchemas";
// Importing the API hook for password reset
import { useResetPassword } from "queries/AuthQueries";

// Interface defining the props for ChangePasswordDialog component
interface ChangePasswordProps {
  open: boolean; // Boolean to control the visibility of the dialog
  onClose: () => void; // Function to close the dialog
  onSubmit: (old_password: string, password: string) => void; // Function to handle the form submission
  userData: any; // User data, potentially used in the form
}

// Extracting password validation schemas
const { password, confirm_password, old_password } = FormValidationSchemas;

// Defining the validation schema for the change password form
const changePasswordSchema = yup.object().shape({
  old_password,
  password,
  confirm_password,
});

// ChangePasswordDialog is a functional component for changing user's password
const ChangePasswordDialog = ({ open, onClose }: ChangePasswordProps) => {
  // useForm hook for form handling
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  // Watching field values
  const { old_password, password, confirm_password } = watch();

  // API hook for resetting the password
  const { mutate: resetPassword } = useResetPassword();

  // Function to handle the password change
  const handlePasswordChange = handleSubmit((data) => {
    const resetData = {
      currentPassword: data.old_password,
      password: data.password,
      confirmPassword: data.password,
    };

    // Calling the resetPassword function
    resetPassword(resetData, {
      onSuccess: () => {
        reset();
        onClose();
      },
      onError: (error: any) => {
        console.error("Password reset failed:", error.fieldErrors[0].message);
        alert(error.fieldErrors[0].message);
      },
    });
  });

  // Rendering the ChangePasswordDialog component
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{ style: { height: "412px", width: "423px" } }}
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <form onSubmit={handlePasswordChange}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
              <PasswordField
                margin="dense"
                id="old-password"
                label="Current Password"
                type="password"
                fullWidth
                value={old_password}
                {...register("old_password")}
                error={!!errors.old_password}
                helperText={errors.old_password?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordField
                margin="dense"
                id="new-password"
                label="New Password"
                type="password"
                fullWidth
                value={password}
                {...register("password")}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordField
                margin="dense"
                id="confirm-password" // Changed the id
                label="Confirm Password"
                type="password"
                fullWidth
                value={confirm_password}
                {...register("confirm_password")}
                error={!!errors.confirm_password}
                helperText={errors.confirm_password?.message}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {/* Submit button for the form */}
        <Button
          color="secondary"
          fullWidth
          type="submit"
          size="large"
          className="items-center justify-center m-auto flex custom-button h-12 ml-4 mr-4 mb-7"
          onClick={handlePasswordChange}
          disabled={!old_password || !password || !confirm_password}
        >
          Change Password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Exporting the component
export default ChangePasswordDialog;