//@ts-nocheck
import {
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import FullAddress from "./FullAddress";

import PartialAddress from "./PartialAddress";
import ShowBreadCrumbs from "./BreadCrumbs";
import useQueryParams from "utils/useQueryParams";

// Define headings for different claim types
const heading = {
  theft: "Theft Details",
  collision_and_rollover: "Details of the Impact",
  impact_with_an_animal: "Details of the Impact",
};

const CollisionDetails: React.FC = () => {
  // State to track the selected address type (full or partial)
  const [selectedAddress, setSelectedAddress] = useState("full");
  
  // Get the claimType from query parameters
  const { claimType } = useQueryParams(["claimType"]);

  // Handle change when the address type selection changes
  const handleChange = (e: any) => {
    setSelectedAddress(e.target.value);
  };

  return (
    <div>
      {/* Display breadcrumbs */}
      <ShowBreadCrumbs />

      <Grid item xs={12} className="flex justify-between pr-7">
        {/* Display the heading based on the claim type */}
        <Typography className="font-semibold text-lg leading-7 mt-5">
          {heading?.[claimType as string]}
        </Typography>
        <Typography className="font-semibold leading-7 mt-5 text-[#545454] text-[10px] bg-[#F5F5F5] pl-[14px] pr-[14px] rounded">
          New Claim
        </Typography>
      </Grid>

      {/* Radio group to select address type */}
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={selectedAddress}
        onChange={(e) => handleChange(e)}
      >
        <FormControlLabel
          value="full"
          control={<Radio />}
          label={
            <Typography fontWeight={400} fontSize={12}>
              Specify Full Address Manually
            </Typography>
          }
        />
        <FormControlLabel
          value="partial"
          control={<Radio />}
          label={
            <Typography fontWeight={400} fontSize={12}>
              I just know the city
            </Typography>
          }
        />
      </RadioGroup>

      {/* Conditionally render either FullAddress or PartialAddress component based on the selected address type */}
      {selectedAddress === "full" ? <FullAddress /> : <PartialAddress />}
    </div>
  );
};

export default CollisionDetails;
