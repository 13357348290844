// Importing necessary React features and Material-UI components
import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

// Importing icon assets for the sidebar
import homeIcon from "../assets/Icons/HomeIcon.svg";
import insuranceIcon from "../assets/Icons/InsuranceIcon.svg";
import billsIcon from "../assets/Icons/BillsIcon.svg";
import benefitsIcon from "../assets/Icons/BenefitsIcon.svg";
import clamsIcon from "assets/Icons/ClaimIcon.svg";
import selectedHomeIcon from "assets/Icons/home-04.svg";
import selectedInsuranceIcon from "assets/Icons/file-shield-02.svg";
import selectedBillsIcon from "assets/Icons/Bills.svg";
import selectedBenefitsIcon from "assets/Icons/benefits.svg";
import selectedClaimIcon from "assets/Icons/clipboard-plus.svg";
import "./sidebar.css";

/**
 * Sidebar is a functional component for navigation within the application.
 */
function Sidebar() {
  // Using useLocation hook to determine the current route
  const location = useLocation();
  // State for tracking the selected index in the sidebar
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  // Effect to update the selected index based on the current route
  useEffect(() => {
    setSelectedIndex((prevIndex) => {
      switch (location.pathname) {
        case "/dashboard":
          return 0;
        case "/policies":
          return 1;
        case "/bills":
          return 2;
        case "/benefits":
          return 3;
        case "/claims":
          return 4;
        default:
          return prevIndex ?? 0;
      }
    });
  }, [location.pathname]);

  // Function to handle list item click and set the selected index
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  // Rendering the Sidebar component
  return (
    <div className={`drawer h-screen flex flex-col justify-between overflow-hidden rounded`}>
      <List className="m-auto mt-6" style={{ width: "234px" }}>
        {/* NavLink components for each route, changing icon based on selection */}
        <NavLink to="/dashboard" className="flex nav">
          <ListItem
            key="Home"
            button
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
            className={selectedIndex === 0 ? "selectedListItem" : "ListItem"}
          >
            <ListItemIcon className="sidebar-icon">
              {selectedIndex === 0 ? (
                <img src={selectedHomeIcon} />
              ) : (
                <img src={homeIcon} />
              )}
            </ListItemIcon>
            <ListItemText primary={<span>Home</span>} className="ml-[-20px]" />
          </ListItem>
        </NavLink>
        <NavLink to="/policies" className="flex nav">
          <ListItem
            key="Insurance"
            button
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
            className={selectedIndex === 1 ? "selectedListItem" : "ListItem"}
          >
            <ListItemIcon className="sidebar-icon">
              {selectedIndex === 1 ? (
                <img src={selectedInsuranceIcon} />
              ) : (
                <img src={insuranceIcon} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={<span>Insurance</span>}
              className="ml-[-20px]"
            />
          </ListItem>
        </NavLink>
        <NavLink to="/bills" className="flex nav">
          <ListItem
            key="Bills"
            button
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
            className={selectedIndex === 2 ? "selectedListItem" : "ListItem"}
          >
            <ListItemIcon className="sidebar-icon">
              {selectedIndex === 2 ? (
                <img src={selectedBillsIcon} />
              ) : (
                <img src={billsIcon} />
              )}
            </ListItemIcon>
            <ListItemText primary={<span>Bills</span>} className="ml-[-20px]" />
          </ListItem>
        </NavLink>
        <NavLink to="/benefits" className="flex nav">
          <ListItem
            key="Benefits"
            button
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
            className={selectedIndex === 3 ? "selectedListItem" : "ListItem"}
          >
            <ListItemIcon className="sidebar-icon">
              {selectedIndex === 3 ? (
                <img src={selectedBenefitsIcon} />
              ) : (
                <img src={benefitsIcon} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={<span>Benefits</span>}
              className="ml-[-20px]"
            />
          </ListItem>
        </NavLink>
        <NavLink to="/claims" className="flex nav">
        <ListItem
          key="claim"
          button
          selected={selectedIndex === 4}
          onClick={(event) => handleListItemClick(event, 4)}
          className={selectedIndex === 4 ? "selectedListItem" : "ListItem"}
          // disabled={true}
        >
          <ListItemIcon className="sidebar-icon">
            {selectedIndex == 4 ? (
              <img src={selectedClaimIcon} />
            ) : (
              <img src={clamsIcon} />
            )}
          </ListItemIcon>
          <ListItemText
            primary={<span>File a claim</span>}
            className="ml-[-20px]"
          />
        </ListItem>
        </NavLink>
      </List>
    </div>
  );
}

// Exporting the Sidebar component
export default Sidebar;
