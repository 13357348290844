import React, { useMemo } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Importing assets for the component
import AvisIcon from "assets/Images/image 18.png";
import Budget1Icon from "assets/Images/image 19.png";
import MapIcon from "assets/Icons/map-01.svg";
import ChevronRightIcon from "assets/Icons/chevron-right.svg";
import CardBgImage from "assets/Images/membership.png";
// import IdahoLogo from "assets/Images/IdahoLogoNew.png";
import IdahoLogo from "assets/Images/logoIdaho.svg";
import farm from "assets/Images/farm.svg";
import Group from "assets/Images/GroupNew.png";
// Importing API function to fetch user details
import { FetchUserDetailsAPI } from "queries/AuthQueries";
import { FetchOfferDetailAPI } from "queries/BenefitsQueries";

// Predefined offers data
const offers = [
  {
    name: "Avis",
    icon: <img src={AvisIcon} alt="" />,
  },
  {
    name: "Budget 1",
    icon: <img src={Budget1Icon} alt="" />,
  },
];

// Benefits component
const Benefits = () => {
  const navigate = useNavigate();

  const { data: Offers } = FetchOfferDetailAPI("", 6);

  // Logging the fetched categories
  console.log("categories:", Offers);

  // Function to navigate to discounts page
  const navigateToDiscounts = () => {
    navigate(`/benefits/discounts`);
  };

  // Function to navigate to map benefits page
  const navigateToMap = () => {
    navigate(`/benefits/map-benefits`);
  };

  // Fetching user details using API call
  const { data: userDetails, dataUpdatedAt } = FetchUserDetailsAPI();
  // const userData = userDetails?.data;

  const { userData } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        userData: userDetails?.data,
      };
    } else {
      return {
        userData: {},
      };
    }
  }, [dataUpdatedAt]);

  // Styles for the membership card
  const cardStyle = {
    backgroundColor: "white",
    backgroundSize: "contain",
    backgroundPosition: "center",
    width: "400px",
    height: "256px",
    borderRadius: "8px",
    paddingRight: "8px",
  };

  // Style for the logo
  const logoStyle: React.CSSProperties = {
    width: "127px",
    height: "18px",
    marginTop: "24px",
    marginLeft: "22px",
  };

  const handlePrint = () => {
    const printableAreaElement = document.getElementById("printable-area");
    if (printableAreaElement) {
      const printableArea = printableAreaElement.innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printableArea;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    } else {
      console.error("Printable area element not found");
    }
  };

  return (
    <>
      {/* Membership Card Section */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            className="mt-4 ml-2 font-semibold text-lg leading-8"
          >
            Membership Card
          </Typography>
        </Grid>
        <Grid item id="printable-area">
          {/* Custom styled card with user data */}
          <Card style={cardStyle} className="flex">
            <div>
              <img src={Group} alt="Card Logo" className=" w-[200px]" />
            </div>
            {/* User details display */}
            <Grid
              container
              className="flex flex-col justify-around items-end p-2"
            >
              <Grid>
                {/* <Typography className="font-inter text-lg font-bold leading-6 tracking-normal">
                  Idaho Farm Bureau &reg;
                </Typography> */}
                <img src={farm} alt="Farm Bureau" className=" w-46" />
                <Typography className="font-inter text-md text-right font-semibold leading-6 tracking-normal">
                  Member Benefit Card
                </Typography>
              </Grid>

              <Grid>
                <Typography className="font-inter text-xl font-bold leading-8 text-right">
                  {userData?.insuredName}
                </Typography>

                <Typography className="font-inter text-md font-semibold leading-6 tracking-normal">
                  Member No:
                </Typography>
                <Typography className="font-inter text-sm font-semibold text-right leading-8">
                  {userData?.memberId}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Button
        className="h-11 text-sm w-[410px] mt-4 bg-white text-black border-1 border-solid hover:bg-slate-50"
        onClick={handlePrint}
      >
        Print Card
      </Button>

      {/* Member Discounts Section */}
      <Grid container spacing={2} direction="row">
        <Grid item xs={8}>
          <Typography
            variant="subtitle1"
            color="#0F0F0F"
            className="mt-4 ml-2 font-semibold text-lg leading-8"
          >
            Member Discounts
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          container
          justifyContent="flex-end"
          className="cursor-pointer flex items-center"
          onClick={navigateToDiscounts}
        >
          {/* Link to view all discounts */}
          <span color="primary" className="ml-2 font-semibold text-14">
            View All
          </span>
          <img src={ChevronRightIcon} />
        </Grid>
      </Grid>

      {/* Displaying Offers */}
      <Grid container spacing={2}>
        {Offers?.data?.map(
          ({ companyName, companyUrl, companyId, id }: any, index: number) =>
            index < 6 && (
              <Grid item xs={12} sm={6} md={6} key={id}>
                <Card className="flex flex-row m-1 px-4 h-[144px] justify-center items-center">
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <img
                          className="cursor-pointer w-full h-full object-contain"
                          src={companyUrl}
                          alt="companyLogo"
                          onClick={() =>
                            navigate(
                              `/benefits/discounts/offer?id=${companyId}`
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )
        )}
      </Grid>

      {Offers?.data?.length > 0 && (
        <Grid
          xs={12}
          className="rounded-md custom-button ml-[200px] h-11 w-[139px] text-[16px] leading-[28px] font-medium cursor-pointer flex justify-center items-center mt-4"
          onClick={navigateToDiscounts}
        >
          {/* Link to view all discounts */}
          <span color="primary">View All</span>
          <ArrowForwardIosIcon
            style={{ color: "white", fontSize: "medium", marginLeft: "8px" }}
          />
        </Grid>
      )}

      {/* Map My Benefit Section */}
      {/* <Grid
        className="m-auto p-[2px] cursor-pointer justify-center mt-4"
        onClick={navigateToMap}
      >
        <Card className="h-[64px] flex justify-center">
          <Grid container alignItems="center" direction="row">
            <Grid item xs={12} sm={2}>
              <img src={MapIcon} alt="" className="p-2 ml-3" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                className="text-start font-medium text-base leading-[28px]"
              >
                Map My Benefit
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className="text-end m-2">
                <img src={ChevronRightIcon} alt="" />
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid> */}
    </>
  );
};
export default Benefits;
