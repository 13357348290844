import { Breadcrumbs, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// Define breadcrumb items with labels and their corresponding href links.
const breadcrumbItems = [
  { label: "Home", href: "/dashboard" },
  { label: "Claims", href: "/claims" },
  { label: "Report a Claim", href: "/report-claim" },
];

// ShowBreadCrumbs component to display breadcrumb navigation.
const ShowBreadCrumbs: React.FC = () => {
  return (
    <Grid item xs={12}>
      {/* Breadcrumbs component with custom styling and separator */}
      <Breadcrumbs className="mt-4 ml-0" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {/* Map through breadcrumb items and create links */}
        {breadcrumbItems.map((item, index) => (
          <Link
            key={index}
            to={item.href}
            className={index === breadcrumbItems.length - 1 ? 'lastLink' : ''}  // Apply a special class for the last breadcrumb item.
            style={index === breadcrumbItems.length - 1 ? { outline: "none", color: "#BB3330", fontWeight: "600" } : { outline: "none", textDecoration: "none", color: "#545454" }}  // Apply different styles for the last and non-last breadcrumb items.
          >
            {item.label}  {/* Display the breadcrumb label */}
          </Link>
        ))}
      </Breadcrumbs>
    </Grid>
  );
};

export default ShowBreadCrumbs;
