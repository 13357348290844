// @ts-nocheck

import React, { useMemo, useState } from "react";

import {
  Autocomplete,
  TextField,
  Container,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormValidationSchemas } from "constants/FormValidationSchemas";

import * as yup from "yup";
import InputItem from "pages/Claims/Collision/InputItem";
import BackAndSaveButton from "pages/Claims/Collision/BackAndSaveButton";
import { useLocation, useNavigate } from "react-router-dom";
import SelectDropDownItem from "pages/Claims/Collision/SelectDropDownItem";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  FetchPolicyAddressAPI,
  FetchStateDetailAPI,
} from "queries/ClaimQueries";
import {
  useFetchCardDetailsAPI,
  useMakePaymentAPI,
  useUpdateCardDetailsAPI,
} from "../queries";

import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

import { FetchUserDetailsAPI } from "queries/AuthQueries";
import { FetchBillsAPI, FetchNextBillAPI } from "queries/BillsQueries";
import HandleErrorResponse from "helper/HandleErrorResponse";

// Breadcrumbs for navigation
const breadcrumbItems = [
  { label: "Bills", href: "/bills" },
  { label: "Bank Details", href: "/direct-debit" },
];

const inputFields1 = [
  {
    label: "Account Holder Name",
    inputName: "account_holder_name",
    xs: 12,
    isMandatory: true,
    placeholder: "Account Holder Name",
  },
  {
    label: "Bank ABA Number",
    inputName: "bank_aba_number",
    xs: 6,
    isMandatory: true,
    // isValueAndOnChangeFn: true,
    placeholder: "Bank ABA Number",
    maxCharacters: 9,
  },
  {
    label: "Bank Account Number",
    inputName: "bank_account_number",
    xs: 6,
    // isValueAndOnChangeFn: true,
    isMandatory: true,
    placeholder: "Bank Account Number",
    // maxCharacters: 9,
  },
  {
    label: "Bank Account Type",
    inputName: "bank_account_type",
    xs: 12,
    isMandatory: true,
    placeholder: "Account Type",
  },
  {
    label: "Bank Name",
    inputName: "bank_name",
    xs: 12,
    isMandatory: true,
    placeholder: "Bank Name",
  }
]

const inputFields2 = [
  {
    label: "Address Line 1",
    inputName: "addressLine1",
    xs: 12,
    isMandatory: true,
    placeholder: "Enter Address Line 1",
  },
  {
    label: "Address Line 2",
    inputName: "addressLine2",
    xs: 12,
    isMandatory: false,
    placeholder: "Enter Address Line 2",
  },
  {
    label: "Zip Code",
    inputName: "zipCode",
    xs: 6,
    isMandatory: true,
    placeholder: "Enter Zip Code",
    // maxCharacters: 5,
    isValueAndOnChangeFn: true,
  },
  {
    label: "City",
    inputName: "city",
    xs: 6,
    isMandatory: true,
    placeholder: "Enter City",
  },
];

const bankAccountType = [
  { id: 1, name: "BUSINESSCHECKING" },
  { id: 2, name: "BUSINESSSAVINGS" },
  { id: 3, name: "PERSONALCHECKING" },
  { id: 4, name: "PERSONALSAVINGS" },
];

const {
  account_holder_name,
  address_1,
  address_2,
  bank_name,
  bank_aba_number,
  bank_account_number,
  bank_account_type,
  city,
  zip_code: zipCode,
} = FormValidationSchemas;

const schema = yup.object().shape({
  addressLine1: address_1,
  account_holder_name,
  bank_name,
  bank_aba_number,
  bank_account_number,
  bank_account_type: yup
    .string()
    .test(
      "conditional-validation",
      "Account Type is required",
      function (value) {
        if (!value || value === "-- Select --") {
          return false;
        }
        return true;
      }
    ),
  zipCode,
  city,
  addressLine2: address_2,
  // primaryPhone: yup
  //   .string()
  //   .nullable()
  //   .required("Contact Number is required")
  //   .matches(/^[0-9]{10}$/, "Invalid Phone Number"),
  state: yup
    .string()
    .test("conditional-validation", "State is required", function (value) {
      if (!value || value === "-- Select --") {
        return false;
      }
      return true;
    }),
});

const DirectDebit = () => {
  // Hook initializations and state declarations
  const navigate = useNavigate();
  const location = useLocation();

  const [zipCodeState, setZipCodeState] = useState("");
  const [bankNumbers, setBankNumbers] = useState({
    abaNumber: "",
    accNumber: "",
  });

  const { data: state } = FetchStateDetailAPI();
  const { mutate: makePayment } = useMakePaymentAPI();
  const { data: receivedBills } = FetchBillsAPI(1, 50, "ASC", false);
  const { userEnteredAmount } =
    location.state || {};

  const getInvoiceId = () => {
    if (getBillFromLocation) {
      return billInvoiceId;
    }
    return invoiceId || receivedBills?.data?.[0]?.invoiceId || "invoice id";
  };
  const getPolicyNumber = () => {
    if (getBillFromLocation) {
      return billPolicyNo;
    }
    return policyNo || receivedBills?.data?.[0]?.policyNo || "policy No";
  };
  const getAmountPaid = () => {
    if (getBillFromLocation) {
      return billAmountDue;
    }
    return amountDue || Number(userEnteredAmount) || "amount";
  };

  const { data: policyAddressesDetail } = FetchPolicyAddressAPI();
  const policyAddressesData = useMemo(() => {
    return policyAddressesDetail?.data.map((item) => {
      const addrKeys = ["address_line_1", "address_line_2", "city", "zipCode"];
      let addrStr = "";
      console.log({ item });
      for (let key in item) {
        console.log(item[key] && addrKeys.includes(key), "item");
        if (item[key] && addrKeys.includes(key)) {
          addrStr += `${item[key]} `;
        }
      }
      return { value: item, label: addrStr.trim() };
    });
  }, [policyAddressesDetail?.data]);

  const { data: bill } = FetchNextBillAPI();
  const { amountDue, policyNo, invoiceId } = bill?.data || {};
  const {
    getBillFromLocation,
    invoiceId: billInvoiceId,
    policyNo: billPolicyNo,
    amountDue: billAmountDue,
  } = location.state?.billData || {};

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors,isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      addressLine1: "",
      city: "",
      zipCode: "",
      state: "ID",
      bank_name: "",
    },
  });

  const [addressLine1] = watch(["addressLine1"]);

  // Navigation and formatting functions
  const navigateToPrevScreen = () => {
    navigate("/select-payment-method");
  };
  const { data: userDetails, dataUpdatedAt } = FetchUserDetailsAPI();

  const { userData } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        userData: userDetails?.data,
      };
    } else {
      return {
        userData: {},
      };
    }
  }, [dataUpdatedAt]);

  const handleMakePayment = () => {
    const {
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      bank_name,
      bank_aba_number,
      bank_account_number,
      bank_account_type,
      // primaryPhone
    } = getValues();
    makePayment(
      {
        amountPaid: getAmountPaid(),
        // invoiceIds: {
        //   entry: [getInvoiceId()],
        // },
        paymentInstrument: {
          billingAddress: {
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            addressLine3:"",
            city: city,
            country: "US",
            postalCode: zipCode,
            state: state,
          },
          contactEmail: userData?.email,
          // contactPhone: primaryPhone,
          // creditCardData:null,
          // creditCardData: {
          //   creditCardExpDate: '',
          //   creditCardHolderName: "name",
          //   creditCardIssuer: '',
          //   creditCardNumber: '',
          //   securityCode: '',
          // },
          bankAccountData: {
            bankABANumber: bank_aba_number,
            bankAccountNumber: bank_account_number,
            bankAccountType: bank_account_type,
            bankName: bank_name,
          },
          paymentMethod: "ACH",
        },
        policyNumber: getPolicyNumber(),
      },
      {
        onSuccess: (data) => {
          const status = data?.data?.status;

          if (status === "success") {
            navigate("/payment-success", {
              state: { responseData: data?.data },
            });
          } else {
            navigate("/payment-denied", {
              state: { responseData: data?.data },
            });
          }

          console.log("Payment Successful", data?.data);
        },
        onError: (err) => {
          HandleErrorResponse(err);
        },
      }
    );
  };

  const handleZipCode = (value: string) => {
    // Ensure only numeric characters are allowed
    const updatedValue = value.replace(/[^0-9]/g, "");

    // // Restrict to 5 characters
    // const limitedValue = updatedValue.slice(0, 5);

    // Handle your state or other logic here
    setZipCodeState(updatedValue);
  };

  const handleBankNumbers = (value: string) => {
    const updatedValue = value.replace(/[^0-9]/g, "");
    const limitedValue = updatedValue.slice(0, 9);
    return limitedValue;
  };

  const handleValueAndOnChangeFn = (key: string) => {
    if (key === "zipCode") {
      return {
        value: zipCodeState,
        onValueChange: (value: string) => handleZipCode(value),
      };
    }
  };

  console.log({ errors });

  return (
    <div className="!w-[530px]">
      <Grid item xs={12}>
        <Breadcrumbs
          className="mt-4"
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          {breadcrumbItems.map((item, index) => (
            <Link
              id={index}
              key={index}
              to={item.href}
              className={index === breadcrumbItems.length - 1 ? "lastLink" : ""}
              style={
                index === breadcrumbItems.length - 1
                  ? { outline: "none", color: "#BB3330", fontWeight: "600" }
                  : {
                      outline: "none",
                      textDecoration: "none",
                      color: "#545454",
                    }
              }
            >
              {item.label}
            </Link>
          ))}
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} className="mb-4">
        <Typography className="font-semibold text-lg leading-7 mt-5">
          Bank Details
        </Typography>
      </Grid>

      <Container className="-ml-5">
        <form onSubmit={handleSubmit(handleMakePayment)} className="space-y-2">
          <Grid container spacing={3}>
            {inputFields1.map((item) =>
              item.label === "Bank Account Type" ? (
                
                  <SelectDropDownItem
                    label="Bank Account Type"
                    control={control}
                    name="bank_account_type"
                    errors={errors}
                    data={bankAccountType}
                  />
                
              ) : (
                <InputItem {...item} control={control} errors={errors} />
              )
            )}
          </Grid>
          <Grid>
            <Typography fontSize={14} fontWeight={500} marginTop={2}>
              Billing Information
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {inputFields2.map((item) => {
              const { label, inputName, isValueAndOnChangeFn } = item;
              return label === "Address Line 1" ? (
                <Grid item xs={12}>
                  <Typography marginBottom={1} fontWeight={400} fontSize={12}>
                    Address Line 1<span className="text-error-500">*</span>
                  </Typography>
                  <Controller
                    name="addressLine1"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Autocomplete
                        options={policyAddressesData || []}
                        getOptionLabel={(option) => option.label}
                        freeSolo
                        inputValue={addressLine1 || ""}
                        onChange={(event, newInputValue = {}) => {
                          const matchingOption = policyAddressesData.find(
                            (option) => option.label === newInputValue
                          );
                          const selectedValue =
                            newInputValue?.value || matchingOption?.value || {};

                          const keyValue = {
                            addressLine1: "address_line_1",
                            addressLine2: "address_line_2",
                            zipCode: "zipCode",
                            city: "city",
                            // state: "state",
                          };

                          for (let key in keyValue) {
                            if (keyValue[key]) {
                              let value = selectedValue[keyValue[key]];
                              if (key === "zipCode") {
                                value =
                                  selectedValue[keyValue[key]]?.split("-")[0];
                                setZipCodeState(value);
                              }
                              setValue(key, value);
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="Enter Address Line 1"
                            fullWidth
                            {...field}
                            type="string"
                            error={!!errors?.addressLine1}
                            helperText={errors?.addressLine1?.message || ""}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <InputItem
                  {...item}
                  control={control}
                  errors={errors}
                  {...(isValueAndOnChangeFn
                    ? handleValueAndOnChangeFn(inputName)
                    : {})}
                />
              );
            })}
          </Grid>

          <SelectDropDownItem
            label="State"
            control={control}
            name="state"
            errors={errors}
            data={state?.data}
          />

          <BackAndSaveButton
            isValid={isValid}
            backButtonHandler={navigateToPrevScreen}
            saveAndNextHandler={handleSubmit(handleMakePayment)}
            saveAndNextText={"Proceed to payment"}
          />
        </form>
      </Container>
    </div>
  );
};

export default DirectDebit;
