// Importing necessary React features and external libraries
import React, { useEffect, useRef } from 'react';
// Importing CSS for styling
import './loader.css';
// Importing the lottie-web library for animations
import lottie from 'lottie-web';
// Importing loader animation data
import loaderData from 'assets/loaderlottie.json';
// Importing components from Material-UI for UI design
import { Card, CardContent, Grid, Typography } from '@mui/material';

/**
 * Loader is a functional component that displays a loading animation
 * using lottie-web library and presents a message to the user.
 */
const Loader = () => {
    // Using useRef hook to reference the container DOM element
    const containerRef = useRef(null);

    // useEffect hook to manage the lifecycle of the lottie animation
    useEffect(() => {
        // Loading the animation into the container
        const anim = lottie.loadAnimation({
            container: containerRef.current!, // Assigning the ref to the container
            renderer: 'svg', // Using SVG renderer for the animation
            loop: true, // Looping the animation
            autoplay: true, // Autoplaying the animation
            animationData: loaderData, // Using imported animation data
        });

        // Cleanup function to destroy the lottie animation on unmount
        return () => anim.destroy();
    }, []);

    // Rendering the loader component
    return (
        // Overlay div for the popup loader
        <div className="popup-overlay">
            <div className="loader">
                <Card>
                    <CardContent>
                        {/* Grid container for aligning the loader and text */}
                        <Grid container direction="column" alignItems="center" spacing={2}>
                            {/* Grid item for the lottie animation container */}
                            <Grid item className='container-ref'>
                                <div ref={containerRef}></div>
                            </Grid>
                            {/* Grid item for the loader text */}
                            <Grid item>
                                {/* Typography for "Please wait..." message */}
                                <Typography variant="body1" color="#333333" className='font-semibold text-2xl! leading-9' style={{fontSize: '24px'}}>Please wait...</Typography>
                                {/* Typography for additional informative text */}
                                <Typography variant="body2" color="#333333" className='font-normal text-base leading-6'>We are fetching your data</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

// Exporting the Loader component
export default Loader;
