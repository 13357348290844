import React from "react";
import { Checkbox, ListItem, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

// Type definition for the props passed to the PersonDetailListItem component
type IPersonDetailListItem = {
  id: number;
  isChecked: boolean | undefined;
  isDriver: boolean;
  name: string;
  personType: string;
  handleCheckedItems: (id: number) => void;
  handleDeleteItem: (id: number) => void;
};

// Functional component to render a list item representing a person's details
export const PersonDetailListItem = ({
  id,
  isDriver,
  isChecked,
  name,
  personType,
  handleCheckedItems,
  handleDeleteItem,
}: IPersonDetailListItem) => {
  // Debugging log to check the isChecked state
  console.log({ isChecked });

  return (
    // ListItem component with conditional styling based on isChecked state
    <ListItem
      className={`rounded-lg border border-solid border-1 p-4 m-2 ml-[-14px]`}
      style={{
        ...(isChecked
          ? {
              borderColor: "#bb3330",
              background: "#fdf3f2",
            }
          : { borderColor: "#f5f5f5" }),
      }}
    >
      {/* Checkbox component to represent the checked state of the item */}
      <Checkbox
        style={{
          border: "1px solid #ededed",
          color: isChecked ? "#bb3330" : "transparent",
          marginRight: "12px",
          borderRadius: "4px",
          width: "24px",
          height: "24px",
        }}
        defaultChecked={isChecked}
        checked={isChecked}
        onChange={() => handleCheckedItems(id)}
      />

      {/* Displaying person's name and type (driver or other) */}
      <ListItemText
        primary={
          <div className="flex items-center">
            <span className="text-gray-900 text-base font-semibold leading-5">
              {name}
            </span>
            <span className="ml-1 text-xs font-semibold text-gray-400 px-2 py-1 rounded uppercase">
              {isDriver ? "Driver" : `Added ${personType}`}
            </span>
          </div>
        }
      />

      {/* Conditionally render the delete icon for non-driver items */}
      {!isDriver && (
        <span onClick={() => handleDeleteItem(id)}>
          <DeleteIcon />
        </span>
      )}
    </ListItem>
  );
};
