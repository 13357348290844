import "./index.css"; // Importing global CSS styles.

import React from "react"; // Importing React library for building user interfaces.
import ReactDOM from "react-dom/client"; // Importing ReactDOM for rendering the app in the DOM.
import { BrowserRouter } from "react-router-dom"; // Importing BrowserRouter to handle routing.

import App from "./App"; // Importing the main App component.
import reportWebVitals from "./reportWebVitals"; // Importing a utility to measure performance.
import { GoogleOAuthProvider } from "@react-oauth/google"; // Importing GoogleOAuthProvider for Google OAuth integration.

// Creating a root element to render the React application.
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // React.StrictMode is a tool for highlighting potential problems in an application.
  <React.StrictMode>
    {/* BrowserRouter is a router implementation for HTML5 browsers (vs HashRouter). */}
    <BrowserRouter>
      {/* GoogleOAuthProvider wraps the App component to provide Google OAuth functionality. */}
      <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      >
        {/* Rendering the main App component. */}
        <App />
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// This line is for measuring the performance of the app. 
// It's optional and can be used for debugging and optimization purposes.
reportWebVitals();

// The ReactDOM.createRoot() method is used to initialize the React application 
// and attach it to the root DOM element (with id 'root').
