import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

type IInputItemProps = {
  label: string;                 // Label for the input field
  control: any;                 // React Hook Form control object
  inputName: string;            // Name of the input field
  placeholder: string | undefined; // Placeholder text for the input field
  errors?: any;                 // Form validation errors
  xs: number;                   // Grid size for responsiveness
  maxCharacters?: number;       // Maximum allowed characters for the input
  isMandatory: boolean;         // Indicates whether the input is mandatory
  textFieldProps?: Record<string, string | boolean | number>; // Additional TextField props
  defaultValue?: string;        // Default value for the input field
  onValueChange?: (value: string) => void; // Callback function for value change
  value?: string;               // Current value of the input
  isValueAndOnChangeFn?: boolean; // Indicates whether both value and onChange should be used
  type?: string;                // Input type (e.g., "string", "number")
  InputProps?: Record<string, any>;   // Additional Input props
  inputProps?: Record<string, any>;   // Additional inputProps
};

const InputItem = ({
  label,
  control,
  errors,
  inputName,
  placeholder,
  xs,
  isMandatory,
  maxCharacters,
  textFieldProps = {},
  defaultValue,
  isValueAndOnChangeFn,
  value,
  type,
  InputProps,
  inputProps,
  onValueChange
}: IInputItemProps) => {

  return (
    <Grid item xs={xs}>
      <Typography marginBottom={1} fontWeight={400} fontSize={12}>
        {label}
        {isMandatory && <span className="text-error-500">*</span>}
      </Typography>

      <Controller
        name={inputName}
        control={control}
        defaultValue={defaultValue || ""}
        render={({ field }) => (
          <TextField
            size="small"
            placeholder={placeholder}
            fullWidth
            {...field}
            type={type || "string"}
            inputProps={{ ...inputProps, ...(maxCharacters ? { maxLength: maxCharacters } : {}) }}
            InputProps={{ ...(InputProps ? InputProps : {}) }}
            error={!!errors?.[inputName]}
            helperText={errors?.[inputName]?.message || ''}
            {...textFieldProps}
            {...(isValueAndOnChangeFn ? { value } : {})}
            onChange={(e) => {
              const newValue = e.target.value;
              field.onChange(e);
              if (isValueAndOnChangeFn && onValueChange) {
                onValueChange(newValue);
              }
            }}
          />
        )}
      />
    </Grid>
  );
};

export default InputItem;
