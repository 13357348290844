import React, { useState } from "react";
import car from "assets/Images/carSuccessPage.svg";

import { 
  Button, 
  Typography, 
  Grid 
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ShowBreadCrumbs from "./BreadCrumbs";
import useQueryParams from "utils/useQueryParams";

// Functional component to display the success page after a claim submission
const ClaimSuccessful = () => {
  // Retrieving the reference number from the query parameters
  const { referenceNo = "" } = useQueryParams(["referenceNo"]);

  // Hook for navigating between routes
  const navigate = useNavigate();

  // Function to navigate back to the homepage/dashboard
  const redirectToHomePage = () => {
    navigate("/dashboard");
  };

  // Function to handle the print action
  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      {/* Breadcrumbs component to show the navigation path */}
      <ShowBreadCrumbs />

      {/* Main content of the success page */}
      <Grid className="flex flex-col justify-center items-center gap-4 mt-8">
        <div className="flex flex-col gap-12 justify-center items-center">
          {/* Image to indicate successful submission */}
          <img src={car} alt="img" />
          <div className="flex flex-col gap-3 justify-center items-center">
            {/* Typography for various texts */}
            <Typography fontWeight={600} fontSize={20}>
              Claim was successfully submitted{" "}
            </Typography>
            <Typography fontWeight={400} fontSize={14}>
              Claim reference number
            </Typography>
            <Typography fontWeight={600} fontSize={14}>
              {referenceNo}
            </Typography>
            <Typography fontWeight={400} fontSize={12} textAlign={"center"}>
            Thank you for submitting a claim. An adjuster will be assigned and will be contacting you soon.
            </Typography>
          </div>
        </div>

        {/* Buttons for navigation and printing */}
        <Button className="h-12 text-white bg-black hover:bg-slate-900" fullWidth onClick={redirectToHomePage}>
          Back to Homepage
        </Button>
        <Button className="h-12" fullWidth onClick={handlePrint}>
          Print Confirmation
        </Button>
      </Grid>
    </div>
  );
};

export default ClaimSuccessful;
