import React from "react";
import {
  Grid,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

// Type definition for the props passed to the ShowToggleButton component
type IShowToggleButtonPropes = {
  handleOnChange: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    value: boolean
  ) => void;
  text: string;
  key: string;
  keyValue: boolean;
};

// Functional component to render a toggle button
const ShowToggleButton = ({
  handleOnChange,
  text,
  key,
  keyValue,
}: IShowToggleButtonPropes) => {
  return (
    // Grid layout for the toggle button
    <Grid className="flex" xs={12}>
      <Grid>
        {/* Displaying the label for the toggle button */}
        <Typography marginBottom={1} fontWeight={400} fontSize={12}>
          {text}
          <span className="text-error-500">*</span>
        </Typography>

        {/* Container for the toggle button group */}
        <div className=" bg-gray-200 w-[230px] h-[46px] rounded-lg">
          {/* Toggle button group with exclusive selection */}
          <ToggleButtonGroup
            color="primary"
            exclusive
            onChange={handleOnChange}
            aria-label="Platform"
          >
            {/* 'Yes' Toggle Button */}
            <ToggleButton
              value={true}
              name={key}
              className={`w-[106px] h-7 mt-2 mb-1 ml-2 border-none ${
                keyValue &&
                "bg-gray-950 text-gray-25 hover:bg-slate-950 hover:text-gray-25"
              }`}
              style={{
                textTransform: "none",
                borderRadius: "4px",
                fontSize: "10px",
                fontWeight: "400",
                lineHeight: "14px",
                padding: "15px",
              }}
            >
              Yes
            </ToggleButton>

            {/* 'No' Toggle Button */}
            <ToggleButton
              value={false}
              name={key}
              className={`w-[106px] h-7 mt-2 mb-1 border-none ${
                !keyValue &&
                "bg-gray-950 text-gray-25 hover:bg-slate-950 hover:text-gray-25"
              }`}
              style={{
                textTransform: "none",
                borderRadius: "4px",
                fontSize: "10px",
                fontWeight: "400",
                lineHeight: "14px",
                padding: "15px",
              }}
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Grid>
    </Grid>
  );
};

export default ShowToggleButton;
