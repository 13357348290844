import { useState } from "react";
import { Select, MenuItem, Typography } from "@mui/material";


// Interface for component props
interface DateSelectProps {
  value?: Date;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  className?: any;
  maxWidth?: any;
  labelText?: string;
}

// DateSelect component definition

const DateSelect: React.FC<DateSelectProps> = ({
  value,
  onChange,
  className,
  maxWidth,
  labelText,
}) => {
  // State hooks for day, month, year, and error handling
  const [day, setDay] = useState(value ? value.getDate() : 0);
  const [month, setMonth] = useState(value ? value.getMonth() + 1 : 0);
  const [year, setYear] = useState(value ? value.getFullYear() : 0);
  const [error, setError] = useState<string | null>(null);

  // Function to calculate the number of days in the selected month and year

  const daysInMonth = new Date(year, month, 0).getDate();
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

   // Handler for day change

  const handleChangeDay = (event: any) => {
    const newDay = parseInt(event.target.value);

    const errorMessage = isFutureDate(year, month, newDay);
    setError(errorMessage);
    
    if (!errorMessage) {
      setDay(newDay);
      if (onChange) {
        const newDate = new Date(year, month - 1, newDay);
        const customEvent = { target: { value: newDate } };
        onChange(customEvent as React.ChangeEvent<{ value: unknown }>);
      }
    }

    if (isFutureDate(year, month, newDay)) {
      // Ignore selection of future date
      return;
    }
    setDay(newDay);
    if (onChange) {
      const newDate = new Date(year, month - 1, newDay);
      const customEvent = { target: { value: newDate } };
      onChange(customEvent as React.ChangeEvent<{ value: unknown }>);
    }
  };

  // Handler for month change

  const handleChangeMonth = (event: any) => {
    const newMonth = parseInt(event.target.value);
    if (isFutureDate(year, newMonth, day)) {
      // Ignore selection of future date
      return;
    }
    setMonth(newMonth);
    if (onChange) {
      const newDay = Math.min(day, new Date(year, newMonth, 0).getDate());
      const newDate = new Date(year, newMonth - 1, newDay);
      const customEvent = { target: { value: newDate } };
      onChange(customEvent as React.ChangeEvent<{ value: unknown }>);
    }
  };

  // Handler for year change

  const handleChangeYear = (event: any) => {
    const newYear = parseInt(event.target.value);
    const currentDate = new Date();
    if (newYear > currentDate.getFullYear()) {
      // Restrict selection to current year or past years
      setYear(currentDate.getFullYear());
      return;
    }
    setYear(newYear);
    if (onChange) {
      const newDay = Math.min(day, new Date(newYear, month, 0).getDate());
      const newDate = new Date(newYear, month - 1, newDay);
      const customEvent = { target: { value: newDate } };
      onChange(customEvent as React.ChangeEvent<{ value: unknown }>);
    }
  };

  const isFutureDate = (year: number, month: number, day: number): string | null => {
    const currentDate = new Date();
    const selectedDate = new Date(year, month - 1, day);
  
    return selectedDate > currentDate ? "Selected date cannot be in the future" : null;
  };

  // Render the DateSelect component

  return (
    <div className={` ${className} ${maxWidth || "w-full"}`}>
      <Typography
        variant="subtitle1"
        color="text.secondaryInfo"
        className="mr-4"
      >
        <span className="font-inter text-[12px] font-normal leading-[16px] tracking-normal text-left">
          {labelText}
        </span>
      </Typography>
      <div className="w-full flex">
      <Select
          // label="Month"
          value={month}
          onChange={handleChangeMonth}
          className="w-36 h-12 mr-2"
        >
          {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
            <MenuItem
              key={m}
              value={m}
              disabled={new Date(year, month - 1, m) > new Date()}
            >
              {new Date(2000, m, 0).toLocaleString("default", {
                month: "long",
              })}
            </MenuItem>
          ))}
        </Select>
        <Select
          // label="Day"
          value={day}
          onChange={handleChangeDay}
          className="w-28 h-12 mr-2"
        >
          {days.map((d) => (
            <MenuItem
              key={d}
              value={d}
              disabled={new Date(year, month - 1, d) > new Date()}
            >
              {d}
            </MenuItem>
          ))}
        </Select>
        <Select
          // label="Year"
          value={year}
          onChange={handleChangeYear}
          className="w-36 h-12"
        >
          {Array.from(
            { length: 100 },
            (_, i) => new Date().getFullYear() - i
          ).map((y) => (
            <MenuItem key={y} value={y}>
              {y}
            </MenuItem>
          ))}
        </Select>
      </div>
      
    </div>
  );
};

export default DateSelect;
