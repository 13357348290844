import { ToastOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Defines the structure of an error object.
interface ErrorObject {
  message: string;
  error_messages: { message: string }[];
}

// Function to format and return a list of error messages.
const returnListOfErrors = (error: ErrorObject) => {
  const { message = "Validation Error", error_messages: errors } = error;

  return (
    <>
      {errors.length > 1 ? (
        <>
          <span className="font-bold">{message}&nbsp;:</span>
          <ul>
            {errors.map((errorMessage, errorIndex) => (
              <li className="ml-2 text-left" key={errorMessage.message}>
                &ensp;
                {errorIndex + 1}
                {")"}&nbsp;{errorMessage.message}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>{errors[0]?.message}</>
      )}
    </>
  );
};

// Common configuration for toast notifications.
const commonToastConfig: ToastOptions = {
  position: "bottom-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

// Defines the structure for toast notification functions.
interface ToastFunctions {
  toastSuccess: (message: string) => void;
  toastError: (message: string, error?: ErrorObject | null) => void;
  toastInfo: (message: string) => void;
}

// Custom hook for handling toast notifications.
const useToast = (): ToastFunctions => {
  const toastSuccess = (message: string) => {
    if (message) toast.success(message, commonToastConfig);
  };

  const toastError = (message: string, error: ErrorObject | null = null) => {
    if (
      error &&
      "status" in error &&
      "error_messages" in error &&
      error.error_messages.length > 0
    ) {
      // If there's a validation error with multiple messages, display them all.
      toast.error(returnListOfErrors(error), commonToastConfig);
    } else if (message) {
      // Display the error message.
      toast.error(message, commonToastConfig);
    }
  };

  const toastInfo = (message: string) => {
    if (message) toast.info(message, commonToastConfig);
  };

  return {
    toastSuccess,
    toastInfo,
    toastError,
  };
};

export default useToast;
