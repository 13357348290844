import React from 'react';
import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ReactComponent as Emptyarrow } from "../../../assets/Icons/emptyarrow.svg";

// Type definition for the props passed to the SelectDropDownItem component
type ISelectDropDownItemProps = {
  label: string;
  control: any;
  name: string;
  errors: any;
  data: any;
  defaultValue?: string;
};

// Functional component to render a dropdown (select) input field
const SelectDropDownItem = ({
  label,
  control,
  name,
  errors,
  data,
  defaultValue=''
}: ISelectDropDownItemProps) => {
  return (
    // Grid item layout for the component
    <Grid item xs={12} className={`-mt-6 ${label === "Bank Account Type" && "mt-0"}`}>
      {/* Typography for the label of the dropdown */}
      <Typography marginBottom={1} fontWeight={400} fontSize={12} style={{ width: '100%' }} className={`${(label==="Country" || label==="State") && "mt-6"}`}>
        {label}
        <span className='text-error-500'>*</span>
      </Typography>

      {/* Controller from react-hook-form to manage form state */}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <div style={{ width: '100%' }}>
            {/* Select input with custom styling and error handling */}
            <Select {...field} 
              error={!!errors?.[name]} 
              fullWidth
              style={{ color: field.value === '' ||  field.value === '-- Select --' ? 'gray' : 'black' }}
              IconComponent={Emptyarrow}
            >
              {/* Default disabled option */}
              <MenuItem value={'-- Select --' || ''} disabled>
                  -- Select --
              </MenuItem>

              {/* Dynamic menu items based on the data prop */}
              {data?.map((elem: any) => {
                return (
                  <MenuItem key={elem.id} value={elem.name}>
                    {elem.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}
      />

      {/* Error message display if there is an error related to this field */}
      {errors?.[name] && (
        <div className='text-red-600'>{errors?.[name]?.message}</div>
      )}
    </Grid>
  );
};

export default SelectDropDownItem;
