//@ts-nocheck
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FormValidationSchemas } from "constants/FormValidationSchemas";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  FetchClaimsDraftAPI,
  FetchPolicyAddressAPI,
  FetchStateDetailAPI,
  UpdateClaimDraftAPI,
} from "queries/ClaimQueries";
import BackAndSaveButton from "./BackAndSaveButton";
import InputItem from "./InputItem";
import SelectDropDownItem from "./SelectDropDownItem";
import { ClaimTypeEnums, LocationAddress } from "../types";
import useQueryParams from "../../../utils/useQueryParams";
import { ClaimSteps } from "../constants";

const {
  address_1: addressLine1,
  address_2: addressLine2,
  address_3: addressLine3,
  city,
  stateCollision: state,
  zip_code: zipCode,
} = FormValidationSchemas;

// Define the validation schema for the Collision Form
const CollisionFormSchema = yup.object().shape({
  addressLine1,
  addressLine2,
  addressLine3,
  city,
  state: yup
    .string()
    .test("conditional-validation", "State is required", function (value) {
      if (!value || value === "-- Select --") {
        return false;
      }
      return true;
    }),
  zipCode,
});

const FullAddress: React.FC = () => {
  const navigate = useNavigate();
  const { policyId, claimId, claimType } = useQueryParams([
    "policyId",
    "claimId",
    "claimType",
  ]);

  const { data: state } = FetchStateDetailAPI();
  const { mutate: draftData } = UpdateClaimDraftAPI();

  const [zipCodeState, setZipCodeState] = useState('');

  const { data: policyAddressesDetail } = FetchPolicyAddressAPI();
  const policyAddressesData = useMemo(() => {
    console.log({});
    return policyAddressesDetail?.data.map((item) => {
      const addrKeys = [
        "address_line_1",
        "address_line_2",
        "city",
        // "state",
        "zipCode",
      ];
      let addrStr = "";
      console.log({ item });
      for (let key in item) {
        console.log(item[key] && addrKeys.includes(key), "item");
        if (item[key] && addrKeys.includes(key)) {
          addrStr += `${item[key]} `;
        }
      }
      return { value: item, label: addrStr.trim() };
    });
  }, [policyAddressesDetail?.data]);

  console.log({ policyAddressesData });

  const { data: claimsDraftDetail } = FetchClaimsDraftAPI(claimId ?? "");
  const { data: claimsDraftDetailData } = claimsDraftDetail ?? {};

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    getValues,
  } = useForm({
    // mode: 'onBlur',
    resolver: yupResolver(CollisionFormSchema),
    defaultValues: {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      state: "ID",
      city: "",
      zipCode: "",
    },
  });

  // Function to navigate to the previous screen
  const navigateToPrevScreen = () => {
    if (claimType === "theft") {
      navigate(`/select-theft-type${window.location.search}`);
    } else {
      navigate(`/select-claim${window.location.search}`);
    }
  };

  useEffect(() => {
    // Check if policyId, claimId, or claimsDraftDetailData is missing or incorrect, then navigate to the claims page
    if (!policyId || !claimId || claimsDraftDetailData === null) {
      navigate("/claims");
    }
  }, [claimId, navigate, policyId, claimsDraftDetailData]);

  useEffect(() => {
    if (claimsDraftDetailData?.locationAddress) {
      for (let key in claimsDraftDetailData.locationAddress) {
        if (
          key === "state" &&
          claimsDraftDetailData.locationAddress[key] === ""
        ) {
          setValue(key, "-- Select --");
          continue;
        }
        if(key === 'zipCode'){
          setZipCodeState(claimsDraftDetailData.locationAddress[key] || "")
        }
        setValue(
          key as keyof LocationAddress,
          claimsDraftDetailData.locationAddress[key] || ""
        );
      }
    }
  }, [claimsDraftDetailData, setValue]);

  const handleZipCode = (value) => {
     // Ensure only numeric characters are allowed
     const updatedValue = value.replace(/[^0-9]/g, '');

     // Restrict to 5 characters
     const limitedValue = updatedValue.slice(0, 5);
 
     // Handle your state or other logic here
     setZipCodeState(limitedValue)
  }

  // Function to handle the submission of the Collision Form
  const handleUpdateClaimDraftAPI = () => {
    const { addressLine1, addressLine2, addressLine3, city, state, zipCode } =
      getValues();
    draftData(
      {
        step: ClaimSteps[claimType as ClaimTypeEnums]["collision-details"],
        policyId,
        draftId: claimsDraftDetailData.draftId,
        hasUnknownTime: claimsDraftDetailData?.hasUnknownTime,
        locationAddress: {
          addressLine1,
          addressLine2,
          addressLine3,
          city,
          state,
          zipCode,
        },
      },
      {
        onSuccess: () => {
          navigate(`/describe-incident${window.location.search}`);
        },
      }
    );
  };

  const [addressLine1] = watch(["addressLine1"]);

  return (
    <div className="-ml-5">
      <Container className="mx-auto mt-2">
        <form
          onSubmit={handleSubmit(handleUpdateClaimDraftAPI)}
          className="space-y-2"
        >
          <Grid container spacing={3}>
            {/* Input fields for address details */}
            <Grid item xs={12}>
              <Typography marginBottom={1} fontWeight={400} fontSize={12}>
                Address Line 1<span className="text-error-500">*</span>
              </Typography>
              <Controller
                name="addressLine1"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  // <TextField
                  //   size="small"
                  //   placeholder="Enter Address Line 1"
                  //   fullWidth
                  //   {...field}
                  //   type="string"
                  //   error={!!errors?.addressLine1}
                  //   helperText={errors?.addressLine1?.message || ''}
                  // />
                  <Autocomplete
                    options={policyAddressesData || []}
                    getOptionLabel={(option) => option.label}
                    freeSolo
                    inputValue={addressLine1 || ""}
                    onChange={(event, newInputValue = {}) => {
                      const matchingOption = policyAddressesData.find(
                        (option) => option.label === newInputValue
                      );
                      const selectedValue =
                        newInputValue?.value || matchingOption?.value || {};

                      const keyValue = {
                        addressLine1: "address_line_1",
                        addressLine2: "address_line_2",
                        zipCode: "zipCode",
                        city: "city",
                        // state: "state",
                      };

                      for (let key in keyValue) {
                        if (keyValue[key]) {
                          let value = selectedValue[keyValue[key]];
                          if (key === "zipCode") {
                            value = selectedValue[keyValue[key]]?.split("-")[0];
                            setZipCodeState(value)
                          }
                          setValue(key, value);
                        }
                      }
                      // setValue('addressLine1', selectedValue.address_line_1 || "")
                      // setValue('addressLine2', selectedValue.address_line_2 || "")
                      // setValue('zipCode', selectedValue.zipCode || "")
                      // setValue('city', selectedValue.city || "")
                      // setValue('state', selectedValue.state || "-- Select --")
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Enter Address Line 1"
                        fullWidth
                        {...field}
                        type="string"
                        error={!!errors?.addressLine1}
                        helperText={errors?.addressLine1?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <InputItem
              label="Address Line 2"
              placeholder="Enter Address Line 2"
              control={control}
              inputName="addressLine2"
              errors={errors}
              xs={12}
              isMandatory={false}
            />

            <InputItem
              label="Address Line 3"
              placeholder="Enter Address Line 3"
              control={control}
              inputName="addressLine3"
              errors={errors}
              xs={12}
              isMandatory={false}
            />

            <InputItem
              label="Zip Code"
              placeholder="Enter Zip Code"
              control={control}
              inputName="zipCode"
              errors={errors}
              xs={6}
              isMandatory={true}
              maxCharacters={5}
              isValueAndOnChangeFn
              value={zipCodeState}
              onValueChange={(value) => handleZipCode(value)}
            />

            <InputItem
              label="City"
              placeholder="Enter City"
              control={control}
              inputName="city"
              errors={errors}
              xs={6}
              isMandatory={true}
            />

            <SelectDropDownItem
              label="State"
              control={control}
              name="state"
              errors={errors}
              data={state?.data}
            />
          </Grid>

          {/* Back and Save button component */}
          <BackAndSaveButton
            backButtonHandler={navigateToPrevScreen}
            saveAndNextHandler={handleSubmit(handleUpdateClaimDraftAPI)}
            isValid={isValid}
          />
        </form>
      </Container>
    </div>
  );
};

export default FullAddress;
