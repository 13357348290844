import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useIsFetching, useIsMutating } from "react-query";

// ApiBackdrops: A React component that displays a backdrop with a loading indicator when API calls are in progress
const ApiBackdrops = () => {
  // useIsFetching: Hook from react-query that returns the number of ongoing 'fetching' requests
  const isfetching = useIsFetching();

  // useIsMutating: Hook from react-query that returns the number of ongoing 'mutating' requests (like POST, PATCH, DELETE)
  const isMutating = useIsMutating();

  // The backdrop is rendered only if there are ongoing fetching or mutating requests.
  // It uses Material-UI's Backdrop and CircularProgress components to show a loading indicator.
  return (
    <Backdrop
      sx={{
        color: "#fff", 
        zIndex: (theme) => theme.zIndex.modal + 100, // Adjusts the z-index to ensure the backdrop is above other elements
      }}
      open={Boolean(isfetching || isMutating)} 
    >
      {/* CircularProgress component to show a spinning loader */}
      <CircularProgress color="error" /> 
    </Backdrop>
  );
};

export default ApiBackdrops;
