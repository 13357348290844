import useToast from "hooks/useToast";

/**
 * HandleErrorResponse is a function that processes an error response
 * and displays relevant error messages using a toast notification system.
 */
export default function HandleErrorResponse(response) {
  // Initialize the toast notification hook.
  const responseToast = useToast();

  // Log the entire response for debugging purposes.
  console.log({ response });

  // Display a general error message if the specific error message is not available.

  // Check if the response contains specific field fieldErrors.
  if (response.fieldErrors) {
    // Loop through each error in the fieldErrors array.
    response.fieldErrors.forEach((error) => {
      const field = error.field; // Extract the field name from the error object.
      const message = error.message; // Extract the error message.

      // If both field and message are present, display the error message.
      if (field && message) {
        responseToast.toastError(message);
        // Uncomment and use the setError function to set form fieldErrors if needed.
        // setError(field, { message: message });
      } else {
        // Log a message for an unknown error structure.

        console.log("Unknown error occurred.");
      }
    });
  } else {
    // Log a message if the error response structure is unknown.
    console.log(response?.message, "toastt");
    responseToast.toastError(response?.message || "error");

    console.log("Unknown error response.");
  }
}
