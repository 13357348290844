import React, { useEffect, useState } from "react";
import ShowBreadCrumbs from "../Collision/BreadCrumbs";
import { Button, Grid, Typography } from "@mui/material";
import BackAndSaveButton from "../Collision/BackAndSaveButton";
import { useNavigate } from "react-router-dom";
import useQueryParams from "utils/useQueryParams";
import { FetchClaimsDraftAPI, UpdateClaimDraftAPI } from "queries/ClaimQueries";
import { ClaimTypeEnums } from "../types";
import { ClaimSteps } from "../constants";

// Array of buttons for selecting the type of theft
const buttonsArray = ["Theft of Audio or other Auto Parts","Theft of Entire Vehicle"];

// TheftType component for selecting the type of theft in a claim
const TheftType = () => {
  // Retrieving necessary query parameters
  const { policyId, claimId, claimType } = useQueryParams([
    "policyId",
    "claimId",
    "claimType",
  ]);

  // Hook for updating claim drafts
  const { mutate: draftData } = UpdateClaimDraftAPI();

  // Fetching claim draft details
  const { data: claimsDraftDetail } = FetchClaimsDraftAPI(claimId || "");
  const { data: claimsDraftDetailData } = claimsDraftDetail || {};

  // State for tracking the selected theft type
  const [selectedType, setSelectedType] = useState("");

  // Navigate hook for routing
  const navigate = useNavigate();

  // Function to navigate to the previous screen
  const navigateToPrevScreen = () => {
    navigate(`/select-claim${window.location.search}`);
  };

  // Effect to set the selected type based on fetched data
  useEffect(() => {
    if (claimsDraftDetailData && claimsDraftDetailData.typeOfTheft) {
      setSelectedType(claimsDraftDetailData.typeOfTheft);
    }
  }, [claimsDraftDetailData]);

  // Function to handle the update of claim draft
  const handleUpdateClaimDraftAPI = () => {
    draftData(
      {
        step: ClaimSteps[claimType as ClaimTypeEnums.theft][
          "select-theft-type"
        ],
        draftId: claimsDraftDetailData?.draftId,
        policyId,
        typeOfTheft: selectedType,
        hasUnknownTime: claimsDraftDetailData?.hasUnknownTime,
      },
      {
        onSuccess: () => {
          navigate(`/collision-details${window.location.search}`);
        },
      }
    );
  };

  // Logging for debugging
  console.log(
    { selectedType, typeOfTheft: claimsDraftDetailData?.typeOfTheft },
    selectedType && buttonsArray.includes(selectedType)
  );

  return (
    <div>
      {/* Breadcrumbs for navigation */}
      <ShowBreadCrumbs />

      {/* Instruction for selecting theft type */}
      <Grid item xs={12}>
        <Typography className="font-semibold text-xl leading-8 mt-5">
          What type of theft occurred?
        </Typography>
      </Grid>

      {/* Buttons for selecting theft type */}
      <Grid className="flex flex-col gap-4 mt-5 justify-center items-center ">
        {buttonsArray.map((text) => (
          <Button
            onClick={() => setSelectedType(text)}
            fullWidth
            className={`${
              selectedType === text
                ? "border-red-700 bg-red-100 border-[1px] border-solid text-sm font-semibold leading-[27px] text-[#333333] hover:border-red-700 hover:bg-red-100 hover:border-[1px] hover:border-solid"
                : " border-[1px] border-solid text-sm font-semibold leading-[27px]"
            }`}
          >
            {text}
          </Button>
        ))}
      </Grid>

      {/* Back and save buttons for navigation and submission */}
      <BackAndSaveButton
        isValid={!!(selectedType && buttonsArray.includes(selectedType))}
        backButtonHandler={navigateToPrevScreen}
        saveAndNextHandler={handleUpdateClaimDraftAPI}
      />
    </div>
  );
};

export default TheftType;
