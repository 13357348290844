import AvisIcon from "assets/Images/image 18.png";
import Budget1Icon from "assets/Images/image 19.png";
import Budget2Icon from "assets/Images/image 20.png";
import Budget3Icon from "assets/Images/image 20 (1).png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchCategoryDetailAPI, FetchOfferDetailAPI } from "queries/BenefitsQueries";


// Array of offers, each with a name and an associated icon
const offers = [
  {
    name: "Avis",
    icon: <img src={AvisIcon} alt="" />,
  },
  {
    name: "Budget 1",
    icon: <img src={Budget1Icon} alt="" />,
  },
  {
    name: "Budget 2",
    icon: <img src={Budget2Icon} alt="" />,
  },
  {
    name: "Budget 3",
    icon: <img src={Budget3Icon} alt="" />,
  },
  {
    name: "Avis",
    icon: <img src={AvisIcon} alt="" />,
  },
  {
    name: "Budget 1",
    icon: <img src={Budget1Icon} alt="" />,
  },
  {
    name: "Budget 2",
    icon: <img src={Budget2Icon} alt="" />,
  },
  {
    name: "Budget 3",
    icon: <img src={Budget3Icon} alt="" />,
  },
];


// Travel component: Displays travel-related offers in a card layout
const Travel = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const categoryName = queryParams.get('categoryName');
  const navigate = useNavigate();

  console.log(categoryName,"categoryID");

  const {data : categoryDetail, error} = FetchOfferDetailAPI(categoryName || '');

  const handleClick =(companyId:any)=>{

navigate(`/benefits/discounts/offer?id=${companyId}`);

  }

  console.log(categoryDetail,"categoryID");

  // Breadcrumb items for navigation
const breadcrumbItems = [
  { label: "Benefits", href: "/benefits" },
  { label: `${categoryName}`, href: `/benefits/travel?categoryName=${categoryName}` },
];


  return (
    <Grid container spacing={2}>
      {/* Breadcrumbs for navigation */}
      <Grid item xs={12}>
        <Breadcrumbs
          className="mt-4 ml-6"
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {/* Mapping through breadcrumbItems to render each breadcrumb */}
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={item.label}>
              {/* Last breadcrumb item is styled differently */}
              {index === breadcrumbItems.length - 1 ? (
                <Link
                  href={item.href}
                  className="lastLink font-bold"
                  style={{ outline: "none", color: "#BB3330" }}
                >
                  {item.label}
                </Link>
              ) : (
                <Link
                  href={item.href}
                  style={{
                    outline: "none",
                    textDecoration: "none",
                    color: "#545454",
                  }}
                >
                  {item.label}
                </Link>
              )}
            </React.Fragment>
          ))}
        </Breadcrumbs>
      </Grid>

      {/* Mapping through the offers to display each one in a card */}
      {categoryDetail?.data?.map(({id,companyId,companyUrl}:any) => (
        <Grid item xs={12} sm={6} md={6} key={id} className="mt-4">
          <Card className="flex flex-row m-1 px-4 h-[265px]  justify-center items-center">
            <CardContent >
              <Grid container className="cursor-pointer">
                <Grid item xs={12} className="flex justify-center items-center">
                  {/* Render the icon associated with the offer */}
                  <img className="w-[150px] h-full"  src={companyUrl} alt="logo"onClick={()=>handleClick(companyId)} />   
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Travel;
