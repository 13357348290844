//@ts-nocheck

// Importing necessary components and hooks from Material-UI, React, and other libraries.
import {
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import OtherPassengerModal from "./OtherPassengerModal";
import BackAndSaveButton from "./BackAndSaveButton";
import ShowBreadCrumbs from "./BreadCrumbs";
import ShowToggleButton from "./ShowToggleButton";
import {
  FetchClaimsDraftAPI,
  FetchDriverVehicleDetailAPI,
  UpdateClaimDraftAPI,
} from "queries/ClaimQueries";
import useQueryParams from "../../../utils/useQueryParams";
import { ClaimTypeEnums } from "../types";
import { ClaimSteps } from "../constants";
import carImg from "assets/Images/carImg.svg";
import InputItem from "./InputItem";
import { FormValidationSchemas } from "constants/FormValidationSchemas";

// Form validation schema using Yup

const {
  // Destructuring necessary validation schemas
  first_name,
  last_name,
} = FormValidationSchemas;

const AboutVehicleSchema = yup.object().shape({
  vehicleNumber: yup
    .string()
    .test("required", "Vehicle Name is required", function (value) {
      // Custom validation logic to check if the value is not an empty string or 'Select'
      if (value === "" || value === "-- Select --") {
        return this.createError({
          path: "vehicleNumber",
          message: "Vehicle Name is required",
        });
      }
      return true;
    }),
  driverNumber: yup
    .string()
    .test("required", "Driver Name is required", function (value) {
      // Custom validation logic to check if the value is not an empty string or 'Select'
      if (value === "" || value === "-- Select --") {
        return this.createError({
          path: "driverNumber",
          message: "Driver Name is required",
        });
      }
      return true;
    }),
  // driverName: yup
  // .string()
  // .test("required", "Driver Name is required", function (value) {
  //   const selectedDriverNumber = this.parent.driverNumber;
  //   // Custom validation logic to check if the value is not an empty string or 'Select'
  //   if (value === "" && selectedDriverNumber === "Other") {
  //     return this.createError({
  //       path: "driverName",
  //       message: "Driver Name is required",
  //     });
  //   }
  //   return true;
  // }),
  firstName: yup
    .string()
    .test("required", "Driver First Name is required", function (value) {
      const selectedDriverNumber = this.parent.driverNumber;
      // Custom validation logic to check if the value is not an empty string or 'Select'
      if (value === "" && selectedDriverNumber === "Other") {
        return this.createError({
          path: "firstName",
          message: "Driver First Name is required",
        });
      }
      return true;
    }),
  lastName: yup
    .string()
    .test("required", "Driver Last Name is required", function (value) {
      const selectedDriverNumber = this.parent.driverNumber;
      // Custom validation logic to check if the value is not an empty string or 'Select'
      if (value === "" && selectedDriverNumber === "Other") {
        return this.createError({
          path: "lastName",
          message: "Driver Last Name is required",
        });
      }
      return true;
    }),
});

// Initial state for vehicle information
const initVehicleInfo = {
  isVehicleOperable: false,
  areAirbagsDeployed: false,
  doesEquipmentFailureExists: false,
  doesVehicleNeedTowed: false,
  isRentalNeeded: false,
};

// Information for modals displayed during form interaction

const modalInfo = [
  {
    heading: "Other Vehicles Involved",
    subHeading: "Were there any other vehicles involved in this incident?",
    img: carImg,
    route: "/other-vehicle",
  },
  {
    heading: "Other Passengers in the Vehicle",
    subHeading: "Were there other passengers in the car during the incident?",
    img: carImg,
    route: "/add-passenger",
  },
];

const AboutVehicle: React.FC = () => {
  // Hooks for navigation and accessing query parameters
  const navigate = useNavigate();
  const { policyId, claimId, claimType } = useQueryParams([
    "policyId",
    "claimId",
    "claimType",
  ]);

  // State and form handling
  const [vehicleInfo, setVehicleInfo] = useState(initVehicleInfo);

  const { data } = FetchDriverVehicleDetailAPI();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AboutVehicleSchema),
    defaultValues: {
      vehicleNumber: "-- Select --",
      driverNumber: "-- Select --",
    },
  });

  // Hooks for fetching and updating claim draft data
  const { mutate: draftData } = UpdateClaimDraftAPI();
  const { data: claimsDraftDetail } = FetchClaimsDraftAPI(claimId || "");
  const { data: claimsDraftDetailData } = claimsDraftDetail || {};
  const vehiclesData = claimsDraftDetailData?.vehicles?.entry?.[0];
  const [showOtherDriverInput, setShowOtherDriverInput] = useState(false);

  const handleOtherDriver = () => {
    setShowOtherDriverInput(true);
  };

  // State and handlers for modal interaction
  const [openModal, setOpenModal] = useState<number>(0);

  const handleOpenModal = () => {
    setOpenModal(1);
  };
  const handleCloseModal = () => {
    setOpenModal(0);
  };

  // Navigation functions for previous screen and modal decisions
  const navigateToPrevScreen = () => {
    navigate(`/describe-incident${window.location.search}`);
  };

  const handleNo = () => {
    if (openModal === modalInfo.length) {
      navigate(`/add-witness${window.location.search}`);
    }
    setOpenModal((prev) => prev + 1);
  };

  const handleYes = () => {
    navigate(`${modalInfo[openModal - 1]?.route}${window.location.search}`);
  };

  // Effect hook for redirecting if necessary data is missing
  useEffect(() => {
    // if policyId or claimId doesn't exist or claimId is not correct in that case we are getting claimsDraftDetailData null
    if (!policyId || !claimId || claimsDraftDetailData === null) {
      navigate("/claims");
    }
  }, [claimId, navigate, policyId, claimsDraftDetailData]);

  // Effect hook for setting up form data based on fetched vehicle data
  useEffect(() => {
    const aboutVehicleToggleKeys = [
      "isVehicleOperable",
      "areAirbagsDeployed",
      "doesEquipmentFailureExists",
      "doesVehicleNeedTowed",
      "isRentalNeeded",
    ];

    if (vehiclesData) {
      let answerObject = { ...initVehicleInfo };
      for (let key of aboutVehicleToggleKeys) {
        answerObject[key] = vehiclesData[key];
      }
      setVehicleInfo(answerObject);
    }

    const { VIN, driverFirstName, driverLastName } = vehiclesData || {};

    const vechileId = data?.data?.find((item) => item.vin === VIN)?.vechileId;
    const driverId = data?.data?.find(
      (item) =>
        `${item.firstName} ${item.lastName}` ===
        `${driverFirstName} ${driverLastName}`
    )?.driverId;

    setValue(
      "vehicleNumber",
      vechileId ? vechileId?.toString() : "-- Select --"
    );
    setValue("driverNumber", driverId ? driverId?.toString() : "-- Select --");
  }, [vehiclesData, setValue, data?.data]);

  // Function to handle the update of claim draft
  const handleUpdateClaimDraftAPI = () => {
    const {
      vehicleNumber,
      driverNumber,
      firstName: fName,
      lastName: lName,
    } = getValues();

    if (!vehicleNumber || !driverNumber) return;

    const selectedVechile = data?.data?.find(
      (item) => item.vechileId === +vehicleNumber
    );
    const selectedDriver = data?.data?.find(
      (item) => item.driverId === +driverNumber
    );

    if (!selectedVechile || (!selectedDriver && driverNumber !== "Other"))
      return;

    const { firstName, lastName, driverId, licenseNo } = selectedDriver || {};
    const { make, model, vin } = selectedVechile;

    draftData(
      {
        step: ClaimSteps[claimType as ClaimTypeEnums.collision_and_rollover][
          "about-vehicle"
        ],
        policyId,
        draftId: claimsDraftDetailData?.draftId,
        hasUnknownTime: claimsDraftDetailData?.hasUnknownTime,
        vehicles: {
          entry: [
            {
              passengers: {
                entry: [],
              },
              ...claimsDraftDetailData?.vehicles?.entry?.[0],
              ...(driverId
                ? {
                    driverFirstName: firstName || fName,
                    driverLastName: lastName || lName,
                    licensePlate: licenseNo,
                    driverId,
                  }
                : {}),
              ...(driverNumber === "Other"
                ? {
                    driverFirstName: firstName || fName,
                    driverLastName: lastName || lName,
                  }
                : {}),
              vin: vin,
              make,
              model,
              ...vehicleInfo,
            },
            {
              ...(claimsDraftDetailData?.vehicles?.entry?.[1] || {}),
            },
          ],
        },
      },
      {
        onSuccess: () => {
          handleOpenModal();
        },
      }
    );
  };

  // Render component
  return (
    <div>
      {/* OtherPassengerModal component for displaying modal */}
      <OtherPassengerModal
        open={!!openModal}
        handleClose={handleCloseModal}
        heading={modalInfo[openModal - 1]?.heading || ""}
        subHeading={modalInfo[openModal - 1]?.subHeading || ""}
        image={modalInfo[openModal - 1]?.img || ""}
        handleYes={handleYes}
        handleNo={handleNo}
      />
      {/* Breadcrumbs display */}
      <ShowBreadCrumbs />

      {/* Page title and form layout */}
      <Grid item xs={12} className="flex justify-between pr-7">
        <Typography className="font-semibold text-lg leading-7 mt-5">
          About Vehicle
        </Typography>
        <Typography className="font-semibold leading-7 mt-5 text-[#545454] text-[10px] bg-[#F5F5F5] pl-[14px] pr-[14px] rounded">
          New Claim
        </Typography>
      </Grid>
      <Container className="mx-auto mt-4 -ml-6">
        <form
          onSubmit={handleSubmit(handleUpdateClaimDraftAPI)}
          className="space-y-4"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography marginBottom={1} fontWeight={400} fontSize={12}>
                Which vehicle was damaged
                <span className="text-error-500">*</span>
              </Typography>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="vehicleNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      defaultValue={""}
                      {...field}
                      style={{
                        color:
                          field.value === "" || field.value === "-- Select --"
                            ? "gray"
                            : "black",
                      }}
                    >
                      <MenuItem value={"-- Select --" || ""} disabled>
                        -- Select --
                      </MenuItem>
                      {data?.data?.map((vehicle: any) => {
                        return (
                          <MenuItem
                            key={vehicle.vechileId}
                            value={vehicle.vechileId?.toString()}
                          >
                            {vehicle.make + " " + vehicle.model}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography marginBottom={1} fontWeight={400} fontSize={12}>
                Who was driving<span className="text-error-500">*</span>
              </Typography>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="driverNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      defaultValue={"spclCycle"}
                      {...field}
                      style={{
                        color:
                          field.value === "" || field.value === "-- Select --"
                            ? "gray"
                            : "black",
                      }}
                    >
                      <MenuItem value={"-- Select --" || ""} disabled>
                        -- Select --
                      </MenuItem>
                      {data?.data?.map((driver: any) => {
                        if (driver?.firstName) {
                          return (
                            <MenuItem
                              key={driver.driverId}
                              value={driver.driverId?.toString()}
                              onClick={() => setShowOtherDriverInput(false)}
                            >
                              {`${driver.firstName || ""} ${
                                driver.middleName || ""
                              } ${driver.lastName || ""}`}
                            </MenuItem>
                          );
                        }
                      })}
                      <MenuItem value={"Other"} onClick={handleOtherDriver}>
                        Other
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              {showOtherDriverInput && (
                <div className="mt-4 flex justify-between items-center">
                  <div className="w-[550px] mr-4">
                    <InputItem
                      label="First Name"
                      placeholder="Enter First Name"
                      control={control}
                      inputName="firstName"
                      errors={errors}
                      // xs={5}
                      isMandatory={true}
                    />
                  </div>
                  <div className="w-[550px]">
                    <InputItem
                      label="Last Name"
                      placeholder="Enter Last Name"
                      control={control}
                      inputName="lastName"
                      errors={errors}
                      // xs={5}
                      isMandatory={true}
                    />
                  </div>
                </div>
              )}
            </Grid>

            <div className="flex pl-6 mt-6 gap-5">
              <ShowToggleButton
                handleOnChange={(_event, newValue) => {
                  setVehicleInfo((prev) => ({
                    ...prev,
                    isVehicleOperable: newValue,
                  }));
                }}
                text="Is the vehicle safe to drive?"
                key="isVehicleOperable"
                keyValue={vehicleInfo.isVehicleOperable}
              />

              <ShowToggleButton
                handleOnChange={(_event, newValue) => {
                  setVehicleInfo((prev) => ({
                    ...prev,
                    areAirbagsDeployed: newValue,
                  }));
                }}
                text="Did the airbag deploy?"
                key="areAirbagsDeployed"
                keyValue={vehicleInfo.areAirbagsDeployed}
              />
            </div>

            <div className="flex pl-6 mt-6 gap-5">
              {/* <ShowToggleButton
                handleOnChange={(_event, newValue) => {
                  setVehicleInfo((prev) => ({
                    ...prev,
                    doesEquipmentFailureExists: newValue,
                  }));
                }}
                text="Was there equipment failure?"
                key="doesEquipmentFailureExists"
                keyValue={vehicleInfo.doesEquipmentFailureExists}
              /> */}

              <ShowToggleButton
                handleOnChange={(_event, newValue) => {
                  setVehicleInfo((prev) => ({
                    ...prev,
                    doesVehicleNeedTowed: newValue,
                  }));
                }}
                text="Was the vehicle towed?"
                key="doesVehicleNeedTowed"
                keyValue={vehicleInfo.doesVehicleNeedTowed}
              />
            </div>

            <div className="pl-6 mt-6">
              <ShowToggleButton
                handleOnChange={(_event, newValue) => {
                  setVehicleInfo((prev) => ({
                    ...prev,
                    isRentalNeeded: newValue,
                  }));
                }}
                text="Is a rental car needed?"
                key="isRentalNeeded"
                keyValue={vehicleInfo.isRentalNeeded}
              />
            </div>
          </Grid>
          {/* Back and Save button component */}
          <BackAndSaveButton
            backButtonHandler={navigateToPrevScreen}
            saveAndNextHandler={handleSubmit(handleUpdateClaimDraftAPI)}
            isValid={isValid}
          />
        </form>
      </Container>
    </div>
  );
};

export default AboutVehicle;
