import React from "react";
import {
  Breadcrumbs,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import avisIcon from "../../assets/Images/avis.png";
import { FetchCompanyDetailAPI } from "queries/BenefitsQueries";
import useQueryParams from "utils/useQueryParams";
import { useLocation } from "react-router-dom";

// Array of breadcrumb items used for navigation links
const breadcrumbItems = [
  { label: "Benefits", href: "/benefits" },
  { label: "Member Discounts", href: "/benefits/discounts" },
  { label: "Benefit Detail", href: "/benefits/discounts" },
];

// Avis component displays details about Avis rental benefits
const Avis = () => {
  const location = useLocation();

  console.log("Current URL:", location.pathname);

  return (
    <>
      {/* Grid container for layout */}
      <Grid container spacing={2}>
        {/* Breadcrumb navigation */}
        <Grid item xs={12}>
          <Breadcrumbs
            className="mt-4 ml-6"
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbItems.map((item, index) => (
              <React.Fragment key={item.label}>
                {/* Conditional styling for the last breadcrumb item */}
                {index === breadcrumbItems.length - 1 ? (
                  <Link
                    href={item.href}
                    className="lastLink"
                    style={{
                      outline: "none",
                      color: "#BB3330",
                      fontWeight: "600",
                      textDecorationColor: "#bb3330",
                    }}
                  >
                    {item.label}
                  </Link>
                ) : (
                  <Link
                    href={item.href}
                    style={{
                      outline: "none",
                      textDecoration: "none",
                      color: "#545454",
                    }}
                  >
                    {item.label}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </Breadcrumbs>
        </Grid>
        {/* Avis logo and title */}
        <Grid
          item
          container
          xs={12}
          className="mb-4 mt-3 items-center gap-x-3.5"
        >
          <img
            src={avisIcon}
            alt={"Avis"}
            loading="lazy"
            className="pt-[7px] pb-[7px] pl-7 pr-7 rounded-lg ml-[6px] shadow-[0px_2px_8px_0px_rgba(15,15,15,0.06)]"
          />
          <Typography
            fontSize={20}
            fontWeight={600}
            lineHeight="32px"
            fontFamily="Inter"
          >
            AVIS
          </Typography>
        </Grid>
      </Grid>

      {/* Main content describing Avis benefits */}
      <Grid container>
        <Grid item xs={11} sm={11} md={11} lg={11}>
          <Typography className='text-[#333333] font-medium font-family: "Inter" text-base mb-3'>
            PAY NOWTM AND SAVE UP TO 35% OFF AVIS RENTALS
          </Typography>
          {/* Multiple typography elements to display different sections of the text */}
          <Typography
            fontFamily="Inter"
            fontSize={16}
            fontWeight={400}
            lineHeight="24px"
          >
            Idaho Farm Bureau members can save up to 35 percent off Avis base
            rates with PAY NOW* for max savings with Avis Worldwide Discount
            (AWD) number A298812. In addition, enjoy additional offers** like
            dollars off, a complimentary upgrade, or a free weekend day. Visit
            www.Avis.com/ IDFB or call 1 800-331-1212 to make a reservation.
          </Typography>
          <Typography
            fontFamily="Inter"
            fontSize={16}
            fontWeight={400}
            lineHeight="24px"
          >
            Avis Car Rental operates one of the world's best-known car rental
            brands with approximately 5,450 locations in more than 165
            countries.
          </Typography>
          <Typography
            fontFamily="Inter"
            fontSize={16}
            fontWeight={400}
            lineHeight="24px"
          >
            If you have member benefit questions, please call Betty @
            208-239-4279 or visit your local FB office. Discount rates may vary
            between 10 and 35 percent, depending on time of year, availability,
            selection of PAY NOWTM, and other factors. **Farm Bureau discount
            may NOT be stacked with other AWD corporate or membership discounts
          </Typography>
        </Grid>
      </Grid>

      {/* Button at the bottom of the page */}
      <Grid container lg={12} sm={12} md={12} xs={12}>
        <Grid item lg={12} sm={12} md={11} xs={12} className="mt-7">
          <Button
            disabled
            variant="contained"
            href="#"
            sx={{
              width: "100%",
              height: "44px",
              fontSize: 16,
              fontWeight: 500,
              fontFamily: "Inter",
              lineHeight: "28px",
              backgroundColor: "#0F0F0F",
              boxShadow: "0px 2px 8px 0px rgba(15, 15, 15, 0.06) !important",
              "&:hover": {
                backgroundColor: "#0F0F0F",
              },
            }}
          >
            Discounted Rates
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Avis;
