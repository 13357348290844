// Importing necessary Firebase modules
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase configuration object containing project-specific settings
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.MEASUREMENT_ID,
};

// Initialize Firebase with the config object
export const firebaseInt = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging with the Firebase app instance
export const messaging = getMessaging(firebaseInt);

// Function to request the FCM token for the client
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPI_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        // Save the FCM token in local storage or handle it as needed
        localStorage.setItem("requestFcmToken", currentToken);
        // Perform any other neccessary action with the token
        return currentToken;
      } else {
        // Handle the case where no token is available
        return console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      // Handle errors in token retrieval
      console.log("An error occurred while retrieving token. ", err);
    });
};

// Function to set up a listener for incoming FCM messages
export const onMessageListener = (refetch: any) =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      refetch(); 
      resolve(payload);
    });
  });
