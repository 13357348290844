//@ts-nocheck

// Define the steps for the claims process for both collision_and_rollover and theft claims
export const ClaimSteps = {
  collision_and_rollover: {
    "report-claim": 1,
    "select-claim": 2,
    "collision-details": 3,
    "describe-incident": 4,
    "about-vehicle": 5,
    "other-vehicle": 6,
    "add-passenger": 7,
    "add-witness": 8,
    "contact-details": 9,
    summary: 10,
  },
  impact_with_an_animal: {
    "report-claim": 1,
    "select-claim": 2,
    "collision-details": 3,
    "describe-incident": 4,
    "about-vehicle": 5,
    "other-vehicle": 6,
    "add-passenger": 7,
    "add-witness": 8,
    "contact-details": 9,
    summary: 10,
  },
  theft: {
    "report-claim": 1,
    "select-claim": 2,
    "select-theft-type": 3,
    "collision-details": 4,
    "describe-incident": 5,
    "add-witness": 6,
    "contact-details": 7,
    summary: 8,
  },
};

// Function to swap keys and values of a nested object
const swapKeysAndValues = (originalObject: any) => {
  const newObj = {};

  // Iterate over the top-level keys (categories) of the original object
  for (const category in originalObject) {
    if (Object.hasOwnProperty.call(originalObject, category)) {
      newObj[category] = {};

      // Iterate over the nested object keys (steps) to swap key and value
      for (const key in originalObject[category]) {
        if (Object.hasOwnProperty.call(originalObject[category], key)) {
           // Swap key and value
          newObj[category][originalObject[category][key]] = key;
        }
      }
    }
  }

  return newObj;
};

// Create a new object with swapped keys and values for redirecting based on step number
export const RedirectFromStep = swapKeysAndValues(ClaimSteps);

// Enumerations for the steps in the collision and rollover claim process
export enum CollisionAndRolloverClaimSteps {
  SelectPolicy = 1,
  SelectPolicyType,
  CollisionDetails,
  DescribeIncident,
  AboutVehicle,
  OtherVehicle,
  AddPassengers,
  AddWitness,
  ContactDetails,
  Summary,
}

// Enumerations for the steps in the theft claim process
export enum TheftClaimSteps {
  SelectPolicy = 1,
  SelectPolicyType,
  TheftType,
  CollisionDetails,
  DescribeIncident,
  AddWitness,
  ContactDetails,
  Summary,
}
