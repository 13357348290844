/* eslint-disable @typescript-eslint/no-unused-expressions */

// Importing necessary Material-UI components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

// Importing custom styles and the CommonDialogTitle component
import Styles from "./CommonDialog.module.css";
import CommonDialogTitle from "./CommonDialogTitle";

// Defining the prop types for the CommonDialog component
interface CommonDialogProps {
  "aria-labelledby"?: string;
  "aria-describedby"?: string;
  children: React.ReactNode;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  onClose?: () => void;
  title: string | JSX.Element;
  actions?: React.ReactNode;
  open: boolean;
  scroll?: "body" | "paper" | undefined;
  handleShowContent?: () => void;
  showContent?: boolean;
  useCommonTitle?: boolean;
}

// The CommonDialog component definition
const CommonDialog: React.FC<CommonDialogProps> = (props) => {
  // Destructuring props for easier access
  const {
    "aria-labelledby": ariaLabelledBy,
    "aria-describedby": ariaDescribedBy,
    children,
    disableEscapeKeyDown,
    disableBackdropClick,
    fullWidth,
    maxWidth,
    onClose,
    title,
    actions,
    open,
    scroll,
    handleShowContent,
    showContent,
    useCommonTitle,
  } = props;

  // Handle close events for the dialog
  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === "backdropClick") {
      !disableBackdropClick && onClose && onClose();
    } else {
      onClose && onClose();
    }
  };

  // Rendering the dialog with appropriate configurations and children
  return (
    <Dialog
      className="max-h-screen"
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      classes={
        maxWidth === "md"
          ? {
              paperWidthMd: Styles.paperWidthMd,
            }
          : undefined
      }
      disableEscapeKeyDown={disableEscapeKeyDown}
      scroll={scroll}
    >
      {/* Conditionally rendering the title based on `useCommonTitle` prop */}
      {useCommonTitle ? (
        <CommonDialogTitle
          handleShowContent={handleShowContent}
          showContent={showContent}
          title={title}
          onClose={onClose}
        />
      ) : (
        <DialogTitle className={Styles.unCommonDialogTitle}>
          {title}
        </DialogTitle>
      )}
      {/* Content area for the dialog */}
      <DialogContent>{children}</DialogContent>
      {/* Dialog actions, typically used for buttons */}
      {Boolean(actions) && (
        <DialogActions className={Styles.dialogActionsRoot}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

// Default props for the CommonDialog component
CommonDialog.defaultProps = {
  "aria-labelledby": undefined,
  "aria-describedby": undefined,
  actions: undefined,
  onClose: () => null,
  fullWidth: true,
  maxWidth: "lg",
  useCommonTitle: true,
  disableEscapeKeyDown: false,
  disableBackdropClick: true,
  scroll: "body",
};

// Exporting the component
export default CommonDialog;
