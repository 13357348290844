import React from "react";
import PaymentDetail from "./PaymentDetail";
import paymentSuccess from "assets/Images/paymentSuccess.svg";
import { useLocation } from "react-router-dom";
import formatCreditCardNumber from "../utils/formatCreditCardNumber";

// PaymentSuccessful Functional Component
const PaymentSuccessful = () => {
  // useLocation hook to access the state passed from the previous route
  const location = useLocation();
  // Destructuring necessary details from the location state
  const { amount, txnId, insuredName, paymentDate, policyNumber } =
    location.state?.responseData || {};

  // Component rendering successful payment details
  return (
    <div>
      <PaymentDetail
        // Icon indicating the payment success
        icon={paymentSuccess}
        // Status of the payment
        status={"Payment Successful"}
        // The amount that was successfully transacted
        amount={amount || ""}
        // Message to show to the user
        quote={
          "Your payment has been successfully processed. Thank you for your purchase!"
        }
        // Transaction ID of the successful payment
        // transcID={txnId || ""}
        // Name of the bank used for the payment
        insuredName={insuredName || ""}
        // Formatted credit card number used for the payment
        paymentDate={paymentDate || ""}
        // Policy number associated with the payment (if any)
        policyNumber={policyNumber || ""}
      />
    </div>
  );
};

export default PaymentSuccessful;
