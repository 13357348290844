//@ts-nocheck
import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import OtherPassengerModal from "./OtherPassengerModal";
import BackAndSaveButton from "./BackAndSaveButton";
import ShowBreadCrumbs from "./BreadCrumbs";
import ShowToggleButton from "./ShowToggleButton";
import { FetchClaimsDraftAPI, UpdateClaimDraftAPI } from "queries/ClaimQueries";
import useQueryParams from "../../../utils/useQueryParams";
import { ClaimTypeEnums } from "../types";
import { ClaimSteps } from "../constants";
import InputItem from "./InputItem";
import carImg from "assets/Images/carImg.svg";
import { FormValidationSchemas } from "constants/FormValidationSchemas";

const {
  // Destructuring necessary validation schemas
  first_name,
  last_name,
} = FormValidationSchemas;
// Validation schema for the vehicle form
const AboutVehicleSchema = yup.object().shape({
  vehicleName: yup.string().nullable().required("Vehicle Name is required"),
  first_name,
  last_name,
  vinNumber: yup.string().optional(),
  makeNumber: yup.string().optional(),
  modelNumber: yup.string().optional(),
});

// Initial state for vehicle information
const initVehicleInfo = {
  isVehicleOperable: false,
  areAirbagsDeployed: false,
  doesEquipmentFailureExists: false,
  doesVehicleNeedTowed: false,
  isRentalNeeded: false,
};

// Main component for handling other vehicle details
const OtherVehicle: React.FC = () => {
  const navigate = useNavigate();
  const { policyId, claimId, claimType } = useQueryParams([
    "policyId",
    "claimId",
    "claimType",
  ]);

  const [vehicleInfo, setVehicleInfo] = useState(initVehicleInfo);

  // Setup for form handling using react-hook-form
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(AboutVehicleSchema),
    defaultValues: {
      vehicleName: "",
    },
  });

  // API calls for updating and fetching claims draft data
  const { mutate: draftData } = UpdateClaimDraftAPI();
  const { data: claimsDraftDetail } = FetchClaimsDraftAPI(claimId || "");
  const { data: claimsDraftDetailData } = claimsDraftDetail || {};
  const otherVehicleData = claimsDraftDetailData?.vehicles?.entry?.[1];

  const [openOtherPassengerModal, setOpenOtherPassengerModal] = useState(false);

  // Handlers for opening and closing the modal
  const handleOpenModal = () => {
    setOpenOtherPassengerModal(true);
  };
  const handleCloseModal = () => {
    setOpenOtherPassengerModal(false);
  };

  // Navigation functions for different routes
  const openPassengerRoute = () => {
    navigate(`/add-passenger${window.location.search}`);
  };
  const openWitnessRoute = () => {
    navigate(`/add-witness${window.location.search}`);
  };
  const navigateToPrevScreen = () => {
    navigate(`/about-vehicle${window.location.search}`);
  };

  // Effect for redirecting if required data is missing
  useEffect(() => {
    // if policyId or claimId doesn't exist or claimId is not correct in that case we are getting claimsDraftDetailData null
    if (!policyId || !claimId || claimsDraftDetailData === null) {
      navigate("/claims");
    }
  }, [claimId, navigate, policyId, claimsDraftDetailData]);

  // Effect for setting form values based on fetched data
  useEffect(() => {
    const aboutVehicleToggleKeys = [
      "isVehicleOperable",
      "areAirbagsDeployed",
      "doesEquipmentFailureExists",
      "doesVehicleNeedTowed",
      "isRentalNeeded",
    ];

    if (otherVehicleData) {
      let answerObject = { ...initVehicleInfo };
      for (let key of aboutVehicleToggleKeys) {
        answerObject[key] = otherVehicleData[key];
      }
      setVehicleInfo(answerObject);

      const { driverFirstName, driverLastName, make, VIN, model } =
        otherVehicleData;
      setValue("first_name", driverFirstName);
      setValue("last_name", driverLastName);
      setValue("vinNumber", VIN);
      setValue("makeNumber", make);
      setValue("modelNumber", model);
    }
  }, [otherVehicleData, setValue]);

  // Function to handle submission and update the claim draft
  const handleUpdateClaimDraftAPI = () => {
    const {
      vehicleName,
      first_name,
      last_name,
      vinNumber,
      makeNumber,
      modelNumber,
    } = getValues();
    if (!vehicleName || !first_name || !last_name) return;

    draftData(
      {
        step: ClaimSteps[claimType as ClaimTypeEnums.collision_and_rollover][
          "other-vehicle"
        ],
        policyId,
        draftId: claimsDraftDetailData?.draftId,
        hasUnknownTime: claimsDraftDetailData?.hasUnknownTime,
        vehicles: {
          entry: [
            {
              ...claimsDraftDetailData?.vehicles?.entry?.[0],
            },
            {
              ...(claimsDraftDetailData?.vehicles?.entry?.[1] || {}),
              driverFirstName: first_name,
              driverLastName: last_name,
              vin: vinNumber,
              make: makeNumber,
              model: modelNumber,
              ...vehicleInfo,
            },
          ],
        },
      },
      {
        onSuccess: () => {
          handleOpenModal();
        },
      }
    );
  };

  // JSX for rendering the component UI
  return (
    <div>
      <OtherPassengerModal
        open={openOtherPassengerModal}
        handleClose={handleCloseModal}
        heading="Other Passengers in the Vehicle"
        subHeading="Were there other passengers in the car during the incident?"
        image={carImg}
        handleYes={openPassengerRoute}
        handleNo={openWitnessRoute}
      />
      <ShowBreadCrumbs />

      <Grid item xs={12} className="flex justify-between pr-7">
        <Typography className="font-semibold text-lg leading-7 mt-5">
          Other Vehicle
        </Typography>
        <Typography className="font-semibold leading-7 mt-5 text-[#545454] text-[10px] bg-[#F5F5F5] pl-[14px] pr-[14px] rounded">
          New Claim
        </Typography>
      </Grid>
      <Container className="mx-auto mt-4 -ml-6">
        <form
          onSubmit={handleSubmit(handleUpdateClaimDraftAPI)}
          className="space-y-4"
        >
          <Grid container spacing={3}>
            <InputItem
              label="Which vehicle was damaged"
              placeholder="Enter Vehicle Name"
              control={control}
              inputName="vehicleName"
              errors={errors}
              xs={12}
              isMandatory={true}
            />
            <div className="mt-4 flex justify-between items-center">
              <div className="w-[238px] mr-4 ml-6">
                <InputItem
                  label="Driver's First Name"
                  placeholder="Enter First Name"
                  control={control}
                  inputName="first_name"
                  errors={errors}
                  // xs={5}
                  isMandatory={true}
                />
              </div>
              <div className="w-[238px]">
                <InputItem
                  label="Driver's Last Name"
                  placeholder="Enter Last Name"
                  control={control}
                  inputName="last_name"
                  errors={errors}
                  // xs={5}
                  isMandatory={true}
                />
              </div>
            </div>
            <InputItem
              label="VIN Number"
              placeholder="Enter VIN Number"
              control={control}
              inputName="vinNumber"
              errors={errors}
              xs={12}
            />
            <InputItem
              label="Make Number"
              placeholder="Enter Make"
              control={control}
              inputName="makeNumber"
              errors={errors}
              xs={12}
            />
            <InputItem
              label="Model"
              placeholder="Enter Model"
              control={control}
              inputName="modelNumber"
              errors={errors}
              xs={12}
            />

            <div className="flex pl-6 mt-6 gap-5">
              <ShowToggleButton
                handleOnChange={(_event, newValue) => {
                  setVehicleInfo((prev) => ({
                    ...prev,
                    isVehicleOperable: newValue,
                  }));
                }}
                text="Is the vehicle safe to drive?"
                key="isVehicleOperable"
                keyValue={vehicleInfo.isVehicleOperable}
              />

              <ShowToggleButton
                handleOnChange={(_event, newValue) => {
                  setVehicleInfo((prev) => ({
                    ...prev,
                    areAirbagsDeployed: newValue,
                  }));
                }}
                text="Did the airbag deploy?"
                key="areAirbagsDeployed"
                keyValue={vehicleInfo.areAirbagsDeployed}
              />
            </div>

            <div className="flex pl-6 mt-6 gap-5">
              {/* <ShowToggleButton
                handleOnChange={(_event, newValue) => {
                  setVehicleInfo((prev) => ({
                    ...prev,
                    doesEquipmentFailureExists: newValue,
                  }));
                }}
                text="Was there equipment failure?"
                key="doesEquipmentFailureExists"
                keyValue={vehicleInfo.doesEquipmentFailureExists}
              /> */}

              <ShowToggleButton
                handleOnChange={(_event, newValue) => {
                  setVehicleInfo((prev) => ({
                    ...prev,
                    doesVehicleNeedTowed: newValue,
                  }));
                }}
                text="Was the vehicle towed?"
                key="doesVehicleNeedTowed"
                keyValue={vehicleInfo.doesVehicleNeedTowed}
              />
            </div>

            <div className="pl-6 mt-6">
              <ShowToggleButton
                handleOnChange={(_event, newValue) => {
                  setVehicleInfo((prev) => ({
                    ...prev,
                    isRentalNeeded: newValue,
                  }));
                }}
                text="Is a rental car needed?"
                key="isRentalNeeded"
                keyValue={vehicleInfo.isRentalNeeded}
              />
            </div>
          </Grid>
          <BackAndSaveButton
            backButtonHandler={navigateToPrevScreen}
            saveAndNextHandler={handleSubmit(handleUpdateClaimDraftAPI)}
            isValid={isValid}
          />
        </form>
      </Container>
    </div>
  );
};

export default OtherVehicle;
