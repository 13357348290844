import axios from "services/axiosInstance";
import { getLocalStorageItem } from "hooks/localStorageHelper";
import localstorageKeys from "hooks/localstorageKeys";
import { useQuery } from "react-query";
import { Category } from "@mui/icons-material";

// Function to fetch categories from the server
export const FetchCategories = () => {
  const method = "GET";
  const url = `/categories`;
  // Axios request for fetching categories
  return axios({ method, url });
};

// React Query hook for fetching categories
export const FetchCategoriesAPI = () => {
  // Hook returns useQuery result for fetching categories
  return useQuery(["GET_CATEGORIES"], () => FetchCategories(), {
    // Ensure query runs only if the auth token is available
    enabled: Boolean(getLocalStorageItem(localstorageKeys.AUTH_TOKEN)),
    onSuccess: (res) => {
      // Optional success handler
    },
    onError: (error) => {
      // Optional error handler
      if (error) {
        console.log("Error:", error);
      }
    },
  });
};

// Function to fetch category benefits
export const FetchCategoryBenefits = (
  page?: number,
  pageSize?: number,
  sort?: "ASC" | "DESC",
  column?: string,
  search?: string,
  categoryId?: string
) => {
  const method = "GET";
  // Parameters for the API request
  const params = {
    page,
    pageSize,
    sort,
    column,
    search,
    categoryId,
  };
  const url = `/categories/benefits`;

  // Axios request for fetching category benefits
  return axios({ method, url, params });
};

// React Query hook for fetching category benefits
export const FetchCategoryBenefitsAPI = (query: any) => {
  const { page, pageSize, sort, column, search, categoryId } = query;
  // Hook returns useQuery result for fetching category benefits
  return useQuery(
    ["GET_BENEFITS", page, pageSize, sort, column, search, categoryId],
    () =>
      FetchCategoryBenefits(page, pageSize, sort, column, search, categoryId),
    {
      // Ensure query runs only if the auth token is available
      enabled: Boolean(getLocalStorageItem(localstorageKeys.AUTH_TOKEN)),
      onSuccess: (res) => {
        // Optional success handler
      },
      onError: (error) => {
        // Optional error handler
        if (error) {
          console.log("Error:", error);
        }
      },
      cacheTime: 0, // Disable cache for the query
    }
  );
};


// Function to fetch offer details via a GET request
export const FetchOfferDetail = (categoryName?:string,pageSize?: number) => {
  const queryParams1 = pageSize ? `?pageSize=${pageSize}` : ''
  const queryParams2 = categoryName? `?search=${categoryName}` : ''
  const method = "GET";
  const url = `/benefits/offers${queryParams1}${queryParams2}`;
  return axios({ method, url });
};

// React Query hook for fetching offer details
export const FetchOfferDetailAPI = (categoryName?:string,pageSize?: number) => {
  const queryvar = categoryName ? `${categoryName.toUpperCase()}_GET_OFFER_DETAIL` : "GET_ALL_OFFER_DETAIL"
  return useQuery([queryvar], () => FetchOfferDetail(categoryName,pageSize), {
    onSuccess: (res) => {},
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to fetch offer details via a GET request
export const FetchCompanyDetail = (companyId:string) => {
  const method = "GET";
  const url = `/benefits/${companyId}/offers`;
  return axios({ method, url });
};

// React Query hook for fetching offer details
export const FetchCompanyDetailAPI = (companyId:string) => {
  return useQuery(["GET_COMPANY_DETAIL"], () => FetchCompanyDetail(companyId), {
    onSuccess: (res) => {},
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to fetch category details via a GET request
export const FetchCategoryDetail = (categoryId:string) => {
  const method = "GET";
  const url = `/categories/${categoryId}`;
  return axios({ method, url });
};

// React Query hook for fetching category details
export const FetchCategoryDetailAPI = (categoryId:string) => {
  return useQuery(["GET_CATEGORY_DETAIL"], () => FetchCategoryDetail(categoryId), {
    onSuccess: (res) => {
      console.log(res,"response")
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};