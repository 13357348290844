import { Button, Grid, IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Define the type for the BackAndSaveButton component's props.
type IBackButtonHandler = {
    backButtonHandler: () => void;             // Function to handle the back button click.
    saveAndNextHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;  // Function to handle the save and next button click.
    isValid: boolean;                         // Flag to determine if the form is in a valid state.
    saveAndNextText?: string;                 // Optional text for the save and next button.
    backButtonText?: string;                  // Optional text for the back button.
}

// BackAndSaveButton component that displays back and save buttons.
const BackAndSaveButton = ({ backButtonHandler, saveAndNextHandler, isValid, saveAndNextText, backButtonText }: IBackButtonHandler) => {

    return (
        <Grid
            item
            xs={12}
            className="w-full pt-7 mt-8 flex justify-between border border-gray-600"
        >
            {/* Back Button */}
            <Button variant="text" className="h-12 text-md -ml-5 text-[#333333] font-medium" onClick={backButtonHandler}>
                <IconButton>
                    <ArrowBackIcon className="text-[#333333] w-5 h-5" />
                </IconButton>
                {backButtonText || 'Back'}
            </Button>

            {/* Save and Next Button */}
            <Button
                color="secondary"
                fullWidth
                type="submit"
                className="custom-button h-12 w-fit text-[16px] leading-[28px]"
                disabled={!isValid}  // Disable the button if the form is not valid.
                onClick={saveAndNextHandler}  // Call the save and next handler when clicked.
            >
                {saveAndNextText || 'Save and Next'}
            </Button>
        </Grid>
    )

}

export default BackAndSaveButton;