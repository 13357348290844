// Importing necessary Material UI components and icons
import {
  AccordionSummary,
  Container,
  Grid,
  Typography,
  List,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { Fragment, FunctionComponent, useState } from "react";

// Importing custom components
import AddPassengerModal from "./AddPassengerModal";
import BackAndSaveButton from "./BackAndSaveButton";
import ShowBreadCrumbs from "./BreadCrumbs";
import { PersonDetailListItem } from "./PersonDetailListItem";
import { PassengersEntry } from "../types";

// Type definition for the AddPersons component props
type IAddPersons = {
  addedPersonsDetails: PassengersEntry[],
  handleAddPersonModel: (person: PassengersEntry) => void;
  titleText: string;
  personType: string;
  addPersonText: string;
  checkedItems: Record<string, boolean>,
  handleCheckedItems: (id: number) => void;
  handleDeleteItem: (id: number) => void;
  onBack: () => void;
  saveAndNextClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

// Functional component for adding persons (like passengers)
const AddPersons: FunctionComponent<
  IAddPersons
> = ({
  addedPersonsDetails,
  handleAddPersonModel,
  checkedItems,
  titleText,
  personType,
  addPersonText,
  handleCheckedItems,
  handleDeleteItem,
  onBack,
  saveAndNextClick,
}: IAddPersons) => {
  // State for controlling the visibility of the Add Passenger modal
  const [showAddPassengerModal, setShowAddPassengerModal] = useState(false);

  // Handler for form submission in Add Passenger modal
  const handleFormSubmit = (person: PassengersEntry) => {
    handleAddPersonModel(person);
    setShowAddPassengerModal((prev) => !prev);
  };

  // Handler for toggling the Add Passenger modal
  const handleShowAddPassengerModal = () => {
    setShowAddPassengerModal((prev) => !prev);
  };

  return (
    <Fragment>
      {/* Add Passenger Modal Component */}
      <AddPassengerModal
      open={showAddPassengerModal}
      handleClose={handleShowAddPassengerModal}
      handleFormSubmit={handleFormSubmit}
      heading={addPersonText}
      personType={personType.toLowerCase()}
      />
      <div>
        {/* Breadcrumbs Component */}
        <ShowBreadCrumbs />
        {/* Title and New Claim Tag */}
        <Grid item xs={12} className="flex justify-between pr-7 items-center">
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="font-semibold text-lg leading-7 mt-5">
              {titleText}
            </Typography>
          </AccordionSummary>
          <Typography className='font-semibold leading-7 mt-5 text-[#545454] text-[10px] bg-[#F5F5F5] pl-[14px] pr-[14px] rounded'>
        New Claim 
        </Typography>
        </Grid>
        <Container className="mx-auto mt-4">
          <form className="space-y-4">
            {/* Display for Person Type (Passengers/Divers etc.) */}
            <Grid item xs={12}>
            <Typography
                marginBottom={1}
                fontWeight={400}
                fontSize={16}
                marginLeft={-1}
              >
                {personType}
                {personType==="Passengers" &&<span className="text-error-500">*</span> }
              </Typography>
            </Grid>
            {/* List of added persons with details */}
            <div className="border border-gray-300 p-3 rounded w-full">
              <List>
                {/* Mapping over added persons to create list items */}
                {addedPersonsDetails.map(
                  ({ id, firstName, lastName }, index) => {
                    return <PersonDetailListItem personType={personType} isChecked={checkedItems[id]} isDriver={false} name={`${firstName} ${lastName}`} id={id} handleCheckedItems={handleCheckedItems}
                    handleDeleteItem={handleDeleteItem} />
                  }
                )}
                {/* Add Person Button */}
                <div>
                  <div
                    className="ml-[-16px] text-center flex items-center justify-center h-12 cursor-pointer mt-2 mx-auto"
                    onClick={handleShowAddPassengerModal}
                  >
                    {/* Icon and text for adding a new person */}
                    <AddIcon />
                    <Typography className="text-gray-700 font-bold">
                      {addPersonText}
                    </Typography>
                  </div>
                </div>
              </List>
            </div>

            {/* Back and Save Button Component */}
            <BackAndSaveButton
              backButtonHandler={onBack}
              saveAndNextHandler={saveAndNextClick}
              isValid={personType==="Passengers"? Object.keys(checkedItems).length!==0:true}
            />
          </form>
        </Container>
      </div>
    </Fragment>
  );
};

export default AddPersons;
