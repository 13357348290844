import { useLocation } from "react-router-dom";

// Type definition for an array of query parameter names.
type QueryParamNames = string[];

// Custom hook to extract query parameters from the URL.
const useQueryParams = (paramNames: QueryParamNames) => {
  // Use the useLocation hook from react-router-dom to get the current location object.
  const location = useLocation();
  // Create a URLSearchParams object based on the search part of the URL (query string).
  const queryParams = new URLSearchParams(location.search);

  // Reduce the array of parameter names to an object containing key-value pairs of parameters.
  return paramNames.reduce((params, paramName) => {
    // For each parameter name, get its value from queryParams.
    // If the parameter is not found, use an empty string as the default value.
    params[paramName] = queryParams.get(paramName) ?? "";
    // Return the accumulated params object.
    return params;
  }, {} as Record<string, string>); 
};

// Export the custom hook for use in other components.
export default useQueryParams;
