// Accepts item (string) and data (JSON)

/**
 * Utility function to set an item in localStorage.
 */
export const setLocalStorageItem = (item: string, data: any) => {
  // Check if localStorage is available
  if (localStorage) {
    // Set the item in localStorage. If the data is an object, stringify it first.
    localStorage.setItem(
      item,
      typeof data === 'object' ? JSON.stringify(data) : data
    );
  }
};

// Accepts item (string) and returns JSON data

/**
 * Utility function to retrieve an item from localStorage.
 */
export const getLocalStorageItem = (item: string) => {
  // Check if the window object and localStorage are available, and the item exists in localStorage
  const receivedItem =
    !(typeof window === 'undefined') && localStorage && item in localStorage
      ? localStorage.getItem(item)
      : '';

  // Try to parse the item as JSON. If parsing fails, return the raw string.
  try {
    return receivedItem ? JSON.parse(receivedItem) : '';
  } catch (e) {
    return receivedItem;
  }
};
