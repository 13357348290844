import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { FetchCompanyDetailAPI } from "queries/BenefitsQueries";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Array of breadcrumb items used for navigation links
const breadcrumbItems = [
  { label: "Benefits", href: "/benefits" },
  { label: "Member Discounts", href: "/benefits/discounts" },
  { label: "Benefit Detail", href: "/benefits/discounts" },
];

// Avis component displays details about Avis rental benefits
const Offers = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get the value of the 'offer' parameter
  const id = queryParams.get("id");

  const { data: offerDetail, refetch, error } = FetchCompanyDetailAPI(id || "");

  const offerDetailData = offerDetail?.data || {};

  const { companyId, companyUrl, companyName, memberBenefit, description } =
    offerDetailData || {};

  useEffect(() => {
    // Trigger API call whenever companyId changes
    if (id && companyId !== Number(id) && !error) {
      refetch();
    }
  }, [refetch, companyId, id, error]);

  function formatDescription(description:any) {
    // Ensure that the description is provided and a string
    if (!description) return "";

    let desc= description
    .split("\n").map((data:any) => `<p>${data}</p>` ).join("")

      console.log(desc)
      return desc
  }

  return (
    <>
      {/* Grid container for layout */}
      <Grid container spacing={2}>
        {/* Breadcrumb navigation */}
        <Grid item xs={12}>
          <Breadcrumbs
            className="mt-4 ml-6"
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbItems.map((item, index) => (
              <React.Fragment key={item.label}>
                {/* Conditional styling for the last breadcrumb item */}
                {index === breadcrumbItems.length - 1 ? (
                  <Link
                    href={item.href}
                    className="lastLink"
                    style={{
                      outline: "none",
                      color: "#BB3330",
                      fontWeight: "600",
                      textDecorationColor: "#bb3330",
                    }}
                  >
                    {item.label}
                  </Link>
                ) : (
                  <Link
                    href={item.href}
                    style={{
                      outline: "none",
                      textDecoration: "none",
                      color: "#545454",
                    }}
                  >
                    {item.label}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </Breadcrumbs>
        </Grid>
        {/* Avis logo and title */}
        <Grid
          item
          container
          xs={12}
          className="mb-4 mt-3 items-center gap-x-3.5"
        >
          <img
            src={companyUrl}
            alt={"Logo"}
            width={"120px"}
            // loading="lazy"
            // className="pt-[7px] pb-[7px] pl-7 pr-7 rounded-lg ml-[6px] shadow-[0px_2px_8px_0px_rgba(15,15,15,0.06)]"
          />
          <Typography
            fontSize={20}
            fontWeight={600}
            lineHeight="32px"
            fontFamily="Inter"
          >
            {companyName}
          </Typography>
        </Grid>
      </Grid>

      {/* Main content describing Avis benefits */}
      <Grid container>
        <Grid item xs={11} sm={11} md={11} lg={11}>
          <Typography className='text-[#333333] font-medium font-family: "Inter" text-base mb-3'>
            {memberBenefit}
          </Typography>
          {/* Multiple typography elements to display different sections of the text */}
          <Typography
            fontFamily="Inter"
            fontSize={16}
            fontWeight={400}
            lineHeight="24px"
          >
            <div
              className="p-2"
              dangerouslySetInnerHTML={{
                __html: formatDescription(description),
              }}
            />
            {/* <div>{formatDescription(description)}</div> */}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Offers;
