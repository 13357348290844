// Importing necessary React hooks and navigation hook from react-router-dom
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Importing custom components and utility functions
import AddPersons from "./AddPersons";
import { FetchClaimsDraftAPI, UpdateClaimDraftAPI } from "queries/ClaimQueries";
import { ClaimTypeEnums, PassengersEntry } from "../types";
import useQueryParams from "../../../utils/useQueryParams";
import { ClaimSteps } from "../constants";

// Component for adding passengers to a claim
const AddPassengers = () => {
  const navigate = useNavigate();
  const { policyId, claimId, claimType } = useQueryParams(['policyId', 'claimId', 'claimType']);
  const { mutate: draftData } = UpdateClaimDraftAPI();

  // Fetching claim draft details
  const { data: claimsDraftDetail } = FetchClaimsDraftAPI(claimId || '');
  const { data: claimsDraftDetailData } = claimsDraftDetail || {};

  // State for managing added persons and checked items
  const [addedPersonsDetails, setAddedPersonsDetails] = useState<PassengersEntry[]>([]);
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  

  // Function to handle item selection
  const handleCheckedItems = (id: number) => {
    const selectedId = addedPersonsDetails.find((item) => item.id === id)?.id;
     if(!selectedId) return;
     const copyOfCheckedItems = {...checkedItems};
     if(copyOfCheckedItems[selectedId]){
       delete copyOfCheckedItems[selectedId]
     }else{
       copyOfCheckedItems[selectedId] = true;
     }
     setCheckedItems(copyOfCheckedItems)
  };

  // Function to handle item deletion

  const handleDeleteItem = (id: number) => {
    const copyOfCheckedItems = {...checkedItems};
    delete copyOfCheckedItems[id]
    setCheckedItems(copyOfCheckedItems)
    setAddedPersonsDetails((prev)=>prev.filter((item) => item.id !== id));
  }

  // Function to navigate back to the previous screen
  const openAboutDetails = () => {
    navigate(-1);
  };

  // Function to handle the submission of the claim draft data
  const handleUpdateClaimDraftAPI = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const selectedPeople = [];
    for(let key in checkedItems){
      let person = addedPersonsDetails.find((item) => item.id === +key);
      selectedPeople.push({firstName: person?.firstName, lastName: person?.lastName, phoneNumber: person?.phoneNumber})
    }
    draftData(
      {
        step: ClaimSteps[claimType as ClaimTypeEnums.collision_and_rollover]['add-passenger'],
        policyId,
        draftId: claimsDraftDetailData?.draftId,
        hasUnknownTime: claimsDraftDetailData?.hasUnknownTime,
        vehicles: {
          entry: [
            {
              ...claimsDraftDetailData?.vehicles?.entry?.[0],
              passengers: {
                entry: selectedPeople
              },
            },
            {
              ...(claimsDraftDetailData?.vehicles?.entry?.[1] || {}),
            }
          ]
        }
      },
      {
        onSuccess: () => {
          navigate(`/add-witness${window.location.search}`);
        },
      }
    );
  };

  // Function to handle adding a passenger in the modal
  const handleAddPassengerModal = (passenger: PassengersEntry) => {
    setAddedPersonsDetails((prev) => ([...prev, passenger]))
  };

  // Effect hook for navigation control based on policy and claim data
  useEffect(() => {
     // if policyId or claimId doesn't exist or claimId is not correct in that case we are getting claimsDraftDetailData null 
     if(!policyId || !claimId || claimsDraftDetailData === null){
      navigate('/claims');
    }
  }, [claimId, navigate, policyId, claimsDraftDetailData])

  // Effect hook to initialize the passenger details from the claim data
  useEffect(() => {
    if(claimsDraftDetailData?.vehicles?.entry[0]?.passengers?.entry){
      const allPassengers = claimsDraftDetailData.vehicles.entry[0].passengers.entry;
      const updatedAllPassengers = allPassengers.map((item, index) => {setCheckedItems((prev => ({...prev,[index+1]: true}))) ;return {...item, id: index+1}})
      setAddedPersonsDetails(updatedAllPassengers);
    }
  }, [claimsDraftDetailData]);

  return (
    <Fragment>
      {/* Component to display and manage the list of added persons */}
      <AddPersons
        // Passing props to the AddPersons component
        addedPersonsDetails={addedPersonsDetails}
        checkedItems={checkedItems}
        handleAddPersonModel={handleAddPassengerModal}
        titleText="Other Passengers"
        personType="Passengers"
        addPersonText="Add Passenger"
        handleCheckedItems={handleCheckedItems}
        handleDeleteItem={handleDeleteItem}
        onBack={openAboutDetails}
        saveAndNextClick={handleUpdateClaimDraftAPI}
      />
    </Fragment>
  );
};

export default AddPassengers;
