import axios from "services/axiosInstance";
import { getLocalStorageItem } from "hooks/localStorageHelper";
import localstorageKeys from "hooks/localstorageKeys";
import { useQuery } from "react-query";

// FetchBills: Function to fetch bill data from the server with optional query parameters
export const FetchBills = (
  page?: number,
  pageSize?: number,
  sort?: "ASC" | "DESC",
  isFuture?: boolean,
  column?: string,
  search?: string
) => {
  const method = "GET";
  const params = {
    page,
    pageSize,
    sort,
    isFuture,
    column,
    search,
  };
  const url = `/bills`;

  // Axios GET request with query parameters
  return axios({ method, url, params });
};

// FetchBillsAPI: React Query hook to fetch bill data, depends on authentication token
export const FetchBillsAPI = (
  page?: number,
  pageSize?: number,
  sort?: "ASC" | "DESC",
  isFuture?: boolean,
  column?: string,
  search?: string
) => {
  // 'useQuery' to fetch data and manage the fetching state
  return useQuery(
    ["GET_BILLS", page, pageSize, sort, isFuture, column, search],
    () => FetchBills(page, pageSize, sort, isFuture, column, search),
    {
      // Only fetch if the auth token is present
      enabled: Boolean(getLocalStorageItem(localstorageKeys.AUTH_TOKEN)),
      onError: (error) => {
        if (error) {
          console.log("Error:", error);
        }
      },
    }
  );
};

// FetchNextBill: Function to fetch the next bill due
export const FetchNextBill = () => {
  const method = "GET";
  const url = `/bills/next-bill`;

  // Axios GET request for the next bill
  return axios({ method, url });
};

// FetchNextBillAPI: React Query hook to fetch the next bill data
export const FetchNextBillAPI = () => {
  // 'useQuery' to fetch next bill data
  return useQuery(["GET_NEXT_BILL"], () => FetchNextBill(), {
    // Only fetch if the auth token is present
    enabled: Boolean(getLocalStorageItem(localstorageKeys.AUTH_TOKEN)),
    onSuccess: (res) => {
      // Success handling
    },
    onError: (error) => {
      if (error) {
        console.log("Error:", error);
      }
    },
  });
};
