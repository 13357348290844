import { useState, useEffect } from "react";
import imageA from "assets/Images/loginImageA.png";
import imageB from "assets/Images/loginImageB.png";
import imageC from "assets/Images/loginImageC.png";
import imageD from "assets/Images/loginImageD.png";

// Slider component for displaying a series of images in a slideshow
const Slider = () => {
  // Array containing the images to be displayed in the slider
  const sliderData: string[] = [imageA, imageB, imageC, imageD];
  // State to keep track of the current slide
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  // Total number of slides
  const slideLength = sliderData.length;

  // Flag for enabling auto-scrolling of slides
  const autoScroll = true;
  // Variable to store the interval for auto-scroll
  let slideInterval: any;
  // Time delay between each slide for auto-scroll
  let intervalTime = 3000;

  // Function to move to the next slide
  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  // Function to start the auto-slide feature
  function autoSlide() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  // useEffect to handle the auto-scroll feature
  useEffect(() => {
    if (autoScroll) {
      autoSlide();
    }
    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  // Render the slider with images
  return (
    <div className="w-full h-full max-h-screen flex relative overflow-hidden">
      <div
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
        className={`transition-all duration-300 w-full h-full flex`}
      >
        {sliderData.map((slide, index) => {
          return (
            <img
              src={slide}
              key={index}
              alt="slide"
              className={` min-w-full object-cover object-center h-full`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Slider;
