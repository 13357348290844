import React, { lazy, Suspense, useMemo } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import { routes } from "./RouteConstants";
import { Backdrop, CircularProgress } from "@mui/material";
import ApiBackdrops from "./ApiBackdrop";
import { getLocalStorageItem } from "hooks/localStorageHelper";
import localstorageKeys from "hooks/localstorageKeys";
import Layout from "components/Layout";

const DefaultScreen = lazy(() => import("../pages/DefaultScreen"));

// RoutesPage Component: Manages routing for the entire application.
const RoutesPage = () => {
  // PublicRoutes: Dynamically creates routes that are publicly accessible.
  const PublicRoutes = useMemo(
    () => (
      <>
        {Object.values(routes)
          .filter(
            (routeDetails) => routeDetails.isPublic && routeDetails.element
          )
          .map((routeDetails) => (
            <Route
              key={`public-route-${routeDetails.pathname}`}
              path={routeDetails.pathname}
              element={
                <Suspense fallback={null}>{routeDetails.element}</Suspense>
              }
            />
          ))}
      </>
    ),
    []
  );

  // PrivateRoutes: Creates routes that require authentication.
  const PrivateRoutes = useMemo(
    () => (
      <>
        {/* <Suspense fallback={null}> */}
        {Object.values(routes)
          .filter(
            (routeDetails) => !routeDetails.isPublic && routeDetails.element
          )
          .map((routeDetails) => (
            <Route
              key={`private-route-${routeDetails.pathname}`}
              path={routeDetails.pathname}
              element={
                <Suspense fallback={null}>{routeDetails.element}</Suspense>
              }
            />
          ))}
        {/* </Suspense> */}
      </>
    ),
    []
  );

  // Suspense used to display a loader (CircularProgress) while components are being loaded.
  return (
    <div>
      <Suspense
        fallback={
          <Backdrop
            open={true}
            sx={{
              zIndex: (theme) => theme.zIndex.modal + 100,
              backgroundColor: "rgba(0,0,0,0.3)",
            }}
          >
            <CircularProgress />
          </Backdrop>
        }
      >
        <Routes>
          {PublicRoutes}
          <Route element={<ProtectedRoutes />}>
            {PrivateRoutes}
            </Route>
          {/* Default screen for unmatched paths */}
          <Route path="*" element={<DefaultScreen />} />
        </Routes>
        <ApiBackdrops /> {/* Overlay for API calls */}
      </Suspense>
    </div>
  );
};

// ProtectedRoutes Component: Redirects to login if the user is not authenticated.
const ProtectedRoutes = () => {
  const location = useLocation();
  const isLoggedIn = getLocalStorageItem(localstorageKeys.AUTH_TOKEN);

  return isLoggedIn ? (
    <Layout>
      <Outlet />
    </Layout> // If logged in, display the route content within the layout.
  ) : (
    <Navigate
      to={routes.LOGIN?.pathname as string}
      replace
      state={{ from: location }}
    />
  );
};

export default RoutesPage;
