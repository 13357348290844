import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "hooks/localStorageHelper";
import localstorageKeys from "hooks/localstorageKeys";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { routes } from "routes/RouteConstants";
import axios from "services/axiosInstance";

// Function to handle the refreshing of authentication tokens

export const handleRefreshAuthtokens = (response: any) => {
  const { access_token, refresh_token } = response?.data;
  setLocalStorageItem(localstorageKeys.AUTH_TOKEN, access_token);
  setLocalStorageItem(localstorageKeys.REFRESH_TOKEN, refresh_token);
};

// Function to set new Auth token  - Latest Key Names
export const handleGetNewAuthtokens = (response: any) => {
  const { oauthToken,oauthRefreshToken} = response?.data;
  setLocalStorageItem(localstorageKeys.AUTH_TOKEN, oauthToken);
  setLocalStorageItem(localstorageKeys.REFRESH_TOKEN, oauthRefreshToken);
};

// Function to refresh the access token using the refresh token
export const RefreshAccessToken = () => {
  const method = "POST";
  const url = `auth/refresh-token`;
  const refreshToken = getLocalStorageItem(localstorageKeys.REFRESH_TOKEN);
  const data = {
    refreshToken: refreshToken,
  };
  return axios({ method, url, data });
};

// Function to sign up a new user
const SignupUser = (data: any) => {
  const method = "POST";
  const url = `/auth/register`;
  return axios({ method, url, data });
};

// React Query hook for signing up a user
export const useSignupUserQuery = () => {
  //   const responseToast = useToast();
  return useMutation(SignupUser, {
    onSuccess: (res: any) => {
      setLocalStorageItem(
        localstorageKeys.AUTH_TOKEN,
        res.data.oauth.oauthToken
      );
      setLocalStorageItem(
        localstorageKeys.REFRESH_TOKEN,
        res.data.oauth.oauthRefreshToken
      );
      setLocalStorageItem(localstorageKeys.USER_DATA, res.data.user);
    },
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};

// Function to log in a user
const LoginUser = (data: any) => {
  const method = "POST";
  const url = `/auth/login`;
  return axios({ method, url, data });
};

// React Query hook for logging in a user
export const useLoginUserQuery = () => {
  //   const responseToast = useToast();
  const navigate = useNavigate();
  return useMutation(LoginUser, {
    onSuccess: (res: any) => {
      const access_token = res.data.oauth.oauthToken;
      const refresh_token = res.data.oauth.oauthRefreshToken;
      setLocalStorageItem(localstorageKeys.AUTH_TOKEN, access_token);
      setLocalStorageItem(localstorageKeys.REFRESH_TOKEN, refresh_token);
      setLocalStorageItem(localstorageKeys.USER_DATA, res.data.user);
    },
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};

// Function to check if a user already exists before signup
const GetExistingUser = (data: any) => {
  const method = "POST";
  const url = `/users/check-email-exists`;
  return axios({ method, url, data });
};

// React Query hook for checking if an email exists
export const useEmailExistQuery = () => {
  const navigate = useNavigate();
  return useMutation(GetExistingUser, {
    onSuccess: (res: any) => {},
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function for social login
const SocialLogin = (data: any) => {
  const method = "POST";
  const url = `/auth/login-with-social`;
  return axios({ method, url, data });
};

// React Query hook for social login
export const useSocialLoginQuery = () => {
  return useMutation(SocialLogin, {
    onSuccess: (res: any) => {
      const access_token = res.data.oauth.oauthToken;
      const refresh_token = res.data.oauth.oauthRefreshToken;
      setLocalStorageItem(localstorageKeys.AUTH_TOKEN, access_token);
      setLocalStorageItem(localstorageKeys.REFRESH_TOKEN, refresh_token);
      setLocalStorageItem(localstorageKeys.USER_DATA, res.data.user);
    },
    onError: (error) => {
      console.log("Error:", error);
    },
  });
};

// Function to verify a user's code
const VerificationCode = (data: any) => {
  const method = "PUT";
  // /api/v1/auth/verify-code
  const url = `/auth/verify-code`;
  return axios({ method, url, data });
};

// React Query hook for code verification
export const useVerificationCodeQuery = () => {
  const navigate = useNavigate();
  //   const responseToast = useToast();
  return useMutation(VerificationCode, {
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};

// Function to send a verification code
const SendCode = (data: any) => {
  const method = "POST";
  const url = `/auth/resend-code`;
  return axios({ method, url, data });
};

// React Query hook for sending a verification code
export const useSendCodeQuery = () => {
  //   const responseToast = useToast();
  return useMutation(SendCode, {
    onSuccess: () => {
      //   responseToast.toastSuccess('Successfully code sent.');
    },
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};

// Function to send a code for password reset
const SendForgotPassCode = (data: any) => {
  const method = "PATCH";
  // /api/v1/auth/verify-code
  const url = `/auth/forget`;
  return axios({ method, url, data });
};

// React Query hook for sending a code for password reset
export const useSendForgotPassCode = () => {
  const navigate = useNavigate();
  //   const responseToast = useToast();
  return useMutation(SendForgotPassCode, {
    onSuccess: () => {
      //   navigate(routes.EMAIL_SENT.pathname);
    },
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};

// Function to reset the user's password
const ResetPassword = (data: any) => {
  const method = "PATCH";
  const url = `/auth/password`;
  return axios({ method, url, data });
};

// React Query hook for resetting the password
export const useResetPassword = () => {
  const navigate = useNavigate();
  //   const responseToast = useToast();
  return useMutation(ResetPassword, {
    onSuccess: () => {
      navigate(routes.LOGIN?.pathname as string);
    },
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};

// Function to fetch user details
export const FetchUserDetails = () => {
  const method = "GET";
  const url = `/auth/me`;
  return axios({ method, url });
};

// React Query hook for fetching user details
export const FetchUserDetailsAPI = () => {
  return useQuery("GET_LOGGED_IN_USER_DETAILS", FetchUserDetails, {
    enabled: Boolean(getLocalStorageItem(localstorageKeys.AUTH_TOKEN)),
    onSuccess: (res) => {
      setLocalStorageItem(
        localstorageKeys.USER_DATA,
        JSON.stringify(res.data.user)
      );
    },
    onError: (error) => {
      if (error) {
        console.log("Error:", error);
      }
    },
  });
};

// Function to update paperless enrollment
export const UpdatePaperlessEnrolled = ({ data, uuid }: any) => {
  const method = "PATCH";
  const url = `/users/${uuid}`;
  return axios({ method, url, data });
};

// React Query hook for updating paperless enrollment
export const UpdatePaperlessEnrolledAPI = () => {
  return useMutation(UpdatePaperlessEnrolled, {
    onSuccess: () => {},
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};

// Function to handle user logout
export const Logout = (data: any) => {
  const method = "DELETE";
  const url = `/auth/revoke`;
  return axios({ method, url, data });
};

// React Query hook for handling user logout
export const useLogoutQuery = () => {
  const navigate = useNavigate();
  //   const responseToast = useToast();
  return useMutation(Logout, {
    onSuccess: () => {
      navigate(routes.LOGIN?.pathname as string);
      localStorage.removeItem(localstorageKeys.AUTH_TOKEN);
      localStorage.removeItem(localstorageKeys.REFRESH_TOKEN);
      localStorage.removeItem(localstorageKeys.USER_DATA);
      localStorage.removeItem("GOOGLE_ACCESS_TOKEN");
      // localStorage.setItem("userLoggedOut", "true");
    },
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};

// Function to send a device token
const sendDeviceToken = (data: any) => {
  const method = "PATCH";
  const url = `devices`;
  return axios({ method, url, data });
};

// React Query hook for sending device token
export const useSendDeviceToken = () => {
  return useMutation(sendDeviceToken, {
    onSuccess: () => {},
    onError: (error) => {},
  });
};


// Function to get notifications
const getNotifications = () => {
  const method = "GET";
  const url = `notifications?readStatus=unread`;
  return axios({ method, url });
};

// React Query hook for getting notifications
export const useGetNotifications = () => {
  return useQuery({
    queryFn: () => getNotifications(),
    queryKey: ["device-notifications-info"],
  });
};

// Function to mark notifications as read
const readNotifications = (notificationId: string) => {
  const method = "PATCH";
  const url = `notifications/${notificationId}`;
  return axios({ method, url });
};

// React Query hook for marking notifications as read
export const useReadNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation(readNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries(["device-notifications-info"]);
    },
    onError: (error) => {},
  });
};

const SendResetLink = (data: any) => {
  const { email } = data || {}
  const method = "POST";
  const url = `/auth/verify?email=${email}`;
  return axios({ method, url});
};

// React Query hook for resetting the password
export const useSendResetLink = (props: any) => {

  const navigate = useNavigate();
  //   const responseToast = useToast();
  return useMutation(SendResetLink, {
    onSuccess: () => {
      // navigate(`${routes.LOGIN?.pathname}?fillNewPass=true` as string);
    },
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};

const ResetPasswordRequest = (data: any) => {
  const method = "PATCH";
  const url = `/auth/change-password`;
  return axios({ method, url, data});
};
export const useResetPasswordRequest = (props: any) => {

  const navigate = useNavigate();
  //   const responseToast = useToast();
  return useMutation(ResetPasswordRequest, {
    onSuccess: () => {
      // navigate(`${routes.LOGIN?.pathname}` as string);
    },
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};


// Function to update paperless enrollment
export const GetUserById = ({  uuid }: any) => {
  const method = "GET";
  const url = `/users/${uuid}`;
  return axios({ method, url });
};

// React Query hook for updating paperless enrollment
export const useGetUserById = () => {
  return useMutation(GetUserById, {
    onSuccess: () => {},
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};


// Function to create the user's password
const createPassword = (data: any) => {
  const method = "POST";
  const url = `/auth/password`;
  return axios({ method, url, data });
};

// React Query hook for creating the password
export const useCreatePassword = () => {
  const navigate = useNavigate();
  //   const responseToast = useToast();
  return useMutation(createPassword, {
    onSuccess: () => {
      navigate(routes.LOGIN?.pathname as string);
    },
    onError: (error) => {
      //   responseToast.toastError(error, 'Something went wrong');
    },
  });
};




