import DinnersCard from "assets/Images/diners-card.svg";
import DiscoverCard from "assets/Images/diners-card.svg";
import Mastercard from "assets/Images/mastercard.svg";
import Visa from "assets/Images/visa.svg";
import PaymentCard from "assets/Images/payment-card.svg";
function getCardTypeFromNumber(input: string) {
  if (
    /^((5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12})))/.test(
      input
    )
  ) {
    return {
      name: "MASTERCARD",
      img: <img src={Mastercard} height={22} width={24} alt="" />,
    };
  } else if (/^(4[0-9]{12}(?:[0-9]{3})?)/.test(input)) {
    return {
      name: "VISA",
      img: <img src={Visa} height={22} width={24} alt="" />,
    };
  } else if (/^(3[47][0-9]{13})/.test(input)) {
    return {
      name: "AMEX",
      img: (
        <img
          src="https://via.placeholder.com/24x24/200"
          height={22}
          width={24}
          alt=""
        />
      ),
    };
  } else if (/^(3(?:0[0-5]|[68][0-9])[0-9]{11})/.test(input)) {
    return {
      name: "DINNERCLUB",
      img: <img src={DinnersCard} height={22} width={24} alt="" />,
    };
  } else if (
    /^(65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10}))/.test(
      input
    )
  ) {
    return {
      name: "DISCOVER",
      img: <img src={DiscoverCard} height={22} width={24} alt="" />,
    };
  } else if (/^((506(0|1))|(507(8|9))|(6500))/.test(input)) {
    return {
      name: "VERVE",
      img: (
        <img
          src="https://via.placeholder.com/24x24/200"
          height={22}
          width={24}
          alt=""
        />
      ),
    };
  } else {
    return {
      name: "OTHERS",
      img: <img src={PaymentCard} height={22} width={24} alt="" />,
    };
  }
}
export default getCardTypeFromNumber;
