// Importing necessary React features and Material-UI components
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";

// Importing a custom password field component
import PasswordField from "./Common/LabelledTextField/PasswordField";
// Importing hooks and utilities for form handling and validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormValidationSchemas } from "constants/FormValidationSchemas";
// Importing the API hook for password reset
import { useResetPassword, useGetUserById, FetchUserDetailsAPI, useCreatePassword } from "queries/AuthQueries";

// Interface defining the props for CreatePasswordDialog component
interface ChangePasswordProps {
  open: boolean; // Boolean to control the visibility of the dialog
  onClose: () => void; // Function to close the dialog
  onSubmit: (old_password: string, password: string) => void; // Function to handle the form submission
  userData: any; // User data, potentially used in the form
}

// Extracting password validation schemas
const { password, confirm_password} = FormValidationSchemas;

// Defining the validation schema for the change password form
const changePasswordSchema = yup.object().shape({
  
  password,
  confirm_password,
});

// CreatePasswordDialog is a functional component for changing user's password
const CreatePasswordDialog = ({ open, onClose }: ChangePasswordProps) => {
  // useForm hook for form handling
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  // Watching field values
  const watchedFields = watch();
  const { password, confirm_password } = watchedFields;



  // API hooks
  const { mutate: resetPassword } = useResetPassword();
  const { mutate: createPassword } = useCreatePassword();
  const { mutate: getUserById } = useGetUserById();
  const { data: userDetails } = FetchUserDetailsAPI();
  const uuid = userDetails?.data?.uuid;

  

  // Function to handle the password change
  const handlePasswordChange = handleSubmit((formData) => {
    // console.log("Form data:", formData); 
    const createtData = {
      password: formData.password,
      confirmPassword: formData.password,
    };

 
      createPassword(
        createtData,
        {
          onSuccess: () => {
            reset();
            onClose();
          },
          onError: (error: any) => {
            console.error("Password creation failed:", error);
            alert(error?.response?.data?.message || "Password creation failed.");
          },
        }
      );
    
  });

  // Rendering the CreatePasswordDialog component
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{ style: { height:"335px", width: "423px" } }}
    >
      <DialogTitle> Create Password</DialogTitle>
      <DialogContent>
        <form onSubmit={handlePasswordChange}>
          <Grid container spacing={2}>
            {/* Show old password field only if password exists */}
           
            <Grid item xs={12}>
              <PasswordField
                margin="dense"
                id="new-password"
                label="New Password"
                type="password"
                fullWidth
                {...register("password")}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordField
                margin="dense"
                id="confirm-password"
                label="Confirm Password"
                type="password"
                fullWidth
                {...register("confirm_password")}
                error={!!errors.confirm_password}
                helperText={errors.confirm_password?.message}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              color="secondary"
              fullWidth
              type="submit"
              size="large"
              className="items-center justify-center m-auto flex custom-button h-12 ml-4 mr-4 mb-7"
              disabled={
                 !password || !confirm_password
                  
              }
            >
               Create Password
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

// Exporting the component
export default CreatePasswordDialog;
