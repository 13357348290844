//@ts-nocheck

import {
  Container,
  Grid,
  MenuItem,
  Select,
  Typography,
  FormControl,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormValidationSchemas } from "constants/FormValidationSchemas";
import BackAndSaveButton from "./BackAndSaveButton";
import InputItem from "./InputItem";
import ShowBreadCrumbs from "./BreadCrumbs";
import {
  FetchClaimsDraftAPI,
  FetchDriverVehicleDetailAPI,
  UpdateClaimDraftAPI,
} from "queries/ClaimQueries";
import useQueryParams from "../../../utils/useQueryParams";
import { ClaimSteps } from "../constants";
import { ClaimTypeEnums } from "../types";
import HandleErrorResponse from "helper/HandleErrorResponse";

// Define the validation schemas for the form fields using Yup.

const DescribeIncidentSchema = yup.object().shape({
  description: yup
    .string()
    .test(
      "conditional-validation",
      "Description is required",
      function (value) {
        const selectedClaimType = this.parent.selectedClaimType;
        console.log(this.parent, "parent");
        console.log({ selectedClaimType, value });
        if (
          !value &&
          (selectedClaimType === "collision_and_rollover" ||
            selectedClaimType === "impact_with_an_animal")
        ) {
          return false;
        }
        return true;
      }
    ),
  briefDescription: yup
    .string()
    .test(
      "conditional-validation",
      "Brief Description is required",
      function (value) {
        const selectedClaimType = this.parent.selectedClaimType;
        if (selectedClaimType === "theft" && !value) {
          return false;
        }
        return true;
      }
    ),
  vehicleNumber: yup
    .string()
    .test("required", "Vehicle Name is required", function (value) {
      // Custom validation logic to check if the value is not an empty string or 'Select'
      const selectedClaimType = this.parent.selectedClaimType;
      if (
        selectedClaimType === "theft" &&
        (value === "" || value === "-- Select --")
      ) {
        return this.createError({
          path: "vehicleNumber",
          message: "Vehicle Name is required",
        });
      }
      return true;
    }),
  selectedClaimType: yup.string().optional(),
});

const DescribeIncident: React.FC = () => {
  const { policyId, claimId, claimType } = useQueryParams([
    "policyId",
    "claimId",
    "claimType",
  ]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(DescribeIncidentSchema),
    defaultValues: {
      vehicleNumber: "-- Select --",
      selectedClaimType: claimType,
    },
  });

  const navigate = useNavigate();

  const { data } = FetchDriverVehicleDetailAPI();

  const { mutate: draftData } = UpdateClaimDraftAPI();
  const { data: claimsDraftDetail } = FetchClaimsDraftAPI(claimId || "");
  const { data: claimsDraftDetailData } = claimsDraftDetail || {};

  // Function to navigate to the previous screen.
  const navigateToPrevScreen = () => {
    navigate(`/collision-details${window.location.search}`);
  };

  useEffect(() => {
    // Redirect to the claims page if necessary conditions are not met.
    if (!policyId || !claimId || claimsDraftDetailData === null) {
      navigate("/claims");
    }
  }, [claimId, navigate, policyId, claimsDraftDetailData]);

  useEffect(() => {
    setValue("selectedClaimType", claimType);
  }, [claimType, setValue]);

  useEffect(() => {
    // Populate form fields with data if available in the draft.
    if (claimsDraftDetailData && claimsDraftDetailData.describeWhatHappened) {
      setValue("description", claimsDraftDetailData.describeWhatHappened);
    }
    if (
      claimsDraftDetailData &&
      claimsDraftDetailData.descriptionOfPropertyDamage
      // (claimsDraftDetailData && claimsDraftDetailData.anyPropertyDamaged)
    ) {
      setValue(
        "briefDescription",
        claimsDraftDetailData.descriptionOfPropertyDamage
      );
    }

    if (claimType === "theft" && claimsDraftDetailData?.involvedVehicle) {
      const { VIN } = claimsDraftDetailData?.involvedVehicle;
      const vechileId = data?.data?.find((item) => item.vin === VIN)?.vechileId;
      setValue(
        "vehicleNumber",
        vechileId ? vechileId?.toString() : "-- Select --"
      );
    }

    const vehiclesData = claimsDraftDetailData?.vehicles?.entry?.[0];
    if (vehiclesData) {
      const { VIN } = vehiclesData;
      const vechileId = data?.data?.find((item) => item.vin === VIN)?.vechileId;
      setValue(
        "vehicleNumber",
        vechileId ? vechileId?.toString() : "-- Select --"
      );
    }

    setValue("selectedClaimType", claimType);
  }, [claimsDraftDetailData, setValue, claimType, data?.data]);

  // Function to handle the form submission and update the claim draft.
  const handleUpdateClaimDraftAPI = () => {
    const { description, briefDescription, vehicleNumber } = getValues();

    console.log({
      description: description,
      briefDescription: briefDescription,
      vehicleNumber: vehicleNumber,
    });

    if (!vehicleNumber) return;

    const selectedVechile = data?.data?.find(
      (item) => item.vechileId === +vehicleNumber
    );
    const { make, model, vin } = selectedVechile || {};

    draftData(
      {
        step: ClaimSteps[claimType as ClaimTypeEnums]["describe-incident"],
        policyId,
        hasUnknownTime: claimsDraftDetailData?.hasUnknownTime,
        draftId: claimsDraftDetailData?.draftId,
        ...(claimType === "collision_and_rollover" ||
        claimType === "impact_with_an_animal"
          ? { describeWhatHappened: description, anyPropertyDamaged: "NO" }
          : {}),
        ...(claimType === "theft"
          ? {
              describeWhatHappened: briefDescription,
              descriptionOfPropertyDamage: description,
              anyPropertyDamaged: "YES",
              involvedVehicle: {
                // entry: [
                // {
                // ...claimsDraftDetailData?.vehicles?.entry?.[0],
                vin: vin,
                make,
                model,
                // },
                // {
                //   ...(claimsDraftDetailData?.vehicles?.entry?.[1] || {}),
                // },
                // ],
              },
            }
          : {}),
      },
      {
        onSuccess: () => {
          if (claimType === "theft") {
            navigate(`/add-witness${window.location.search}`);
          } else {
            navigate(`/about-vehicle${window.location.search}`);
          }
        },
        onError: (err) => {
          return HandleErrorResponse(err);
        },
      }
    );
  };

  return (
    <div>
      {/* Display breadcrumbs */}
      <ShowBreadCrumbs />
      <Grid item xs={12} className="flex justify-between pr-7">
        {/* Display section title */}
        <Typography className="font-semibold text-lg leading-7 mt-5">
          Describe Incident
        </Typography>
        <Typography className="font-semibold leading-7 mt-5 text-[#545454] text-[10px] bg-[#F5F5F5] pl-[14px] pr-[14px] rounded">
          New Claim
        </Typography>
      </Grid>

      <Container className="mt-4">
        <form onSubmit={handleSubmit(handleUpdateClaimDraftAPI)}>
          <div className="-ml-6">
            {/* Input field for describing what happened */}
            {(claimType === "collision_and_rollover" ||
              claimType === "impact_with_an_animal") && (
              <InputItem
                label="Describe what happened?"
                placeholder="Enter Description"
                control={control}
                inputName="description"
                errors={errors}
                xs={12}
                isMandatory={true}
              />
            )}

            {claimType === "theft" && (
              <Grid item xs={12}>
                <Typography marginBottom={1} fontWeight={400} fontSize={12}>
                  Which vehicle was damaged
                  <span className="text-error-500">*</span>
                </Typography>
                <FormControl fullWidth variant="outlined">
                  <Controller
                    name="vehicleNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        defaultValue={""}
                        {...field}
                        style={{
                          color:
                            field.value === "" || field.value === "-- Select --"
                              ? "gray"
                              : "black",
                        }}
                      >
                        <MenuItem value={"-- Select --" || ""} disabled>
                          -- Select --
                        </MenuItem>
                        {data?.data?.map((vehicle: any) => {
                          return (
                            <MenuItem
                              key={vehicle.vechileId}
                              value={vehicle.vechileId?.toString()}
                            >
                              {vehicle.make + " " + vehicle.model}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            )}

            {/* Toggle buttons for property damage */}
            {/* {!(claimType === "theft") && <Grid xs={12} marginTop={4}>
              <Typography marginBottom={1} fontWeight={400} fontSize={12} width={343}>
                Was any property damaged, other than the vehicle(s) involved in the collision? <span className='text-error-500'>*</span>
              </Typography>
              <div className='border  bg-gray-200  px-0  rounded-lg w-[217px] h-[46px]'>
                <ToggleButtonGroup
                  color='primary'
                  exclusive
                  onChange={(_event, newValue) => {
                    console.log('onChange', newValue);
                    setIsPropertyDamage(newValue)
                  }}
                  aria-label='Platform'
                >
                  <ToggleButton
                    value={true}
                    name='isPropertyDamage'
                    className={`w-[100px] h-[30px] text-xs mt-2 mb-2 ml-2  border-none ${
                      isPropertyDamage &&
                      'bg-gray-950 text-gray-25 hover:bg-slate-950 hover:text-gray-25 !rounded'
                    }`}
                    style={{ textTransform: 'none' }} 
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    value={false}
                    name='isPropertyDamage'
                    className={`w-[100px] h-[30px] text-xs mt-2 mb-2 ml-0  border-none ${
                      !isPropertyDamage &&
                      'bg-gray-950 text-gray-25 hover:bg-slate-950 hover:text-gray-25 !rounded'
                    }`}
                    style={{ textTransform: 'none' }} 
                  >
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </Grid>} */}

            {/* Input field for briefly describing property damage */}
            {claimType === "theft" && (
              <div className="mt-4">
                <InputItem
                  label="Please describe what happened"
                  placeholder="Enter details here"
                  control={control}
                  inputName="briefDescription"
                  errors={errors}
                  xs={12}
                  isMandatory={true}
                  textFieldProps={{ rows: 3, multiline: true }}
                />
              </div>
            )}

            {/* Display Back and Save buttons */}
            <BackAndSaveButton
              isValid={isValid}
              backButtonHandler={navigateToPrevScreen}
              saveAndNextHandler={handleSubmit(handleUpdateClaimDraftAPI)}
            />
          </div>
        </form>
      </Container>
    </div>
  );
};

export default DescribeIncident;
