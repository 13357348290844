import React, { useEffect } from "react";
import {
  Breadcrumbs,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// Importing icon images
import AvisIcon from "assets/Images/image 18.png";
import Budget1Icon from "assets/Images/image 19.png";
import Budget2Icon from "assets/Images/image 20.png";
import Budget3Icon from "assets/Images/image 20 (1).png";

// Importing API function to fetch categories
import {
  FetchCategoriesAPI,
  FetchCompanyDetailAPI,
  FetchOfferDetailAPI,
} from "queries/BenefitsQueries";

// Predefined offers data
const offers = [
  {
    name: "Avis",
    icon: <img src={AvisIcon} alt="" />,
    link: "/benefits/discounts/avis",
  },
  {
    name: "Budget 1",
    icon: <img src={Budget1Icon} alt="" />,
    link: "/benefits/discounts/budget",
  },
  {
    name: "Budget 2",
    icon: <img src={Budget2Icon} alt="" />,
  },
  {
    name: "Budget 3",
    icon: <img src={Budget3Icon} alt="" />,
  },
];

// Breadcrumb items for navigation
const breadcrumbItems = [
  { label: "Benefits", href: "/benefits" },
  { label: "Member Discounts", href: "/benefits/discounts" },
];

// BenefitsDiscount component
const BenefitsDiscount = () => {
  const navigate = useNavigate();

  // Navigate to the travel page
  const navigateToTravel = (categoryName: any) => {
    navigate(`/benefits/travel?categoryName=${categoryName}`);
  };

  // Fetching categories using API call
  const { data: Categories } = FetchCategoriesAPI();
  const { data: Offers, refetch } = FetchOfferDetailAPI();
  const offersLength = Offers?.data?.length;

  useEffect(() => {
    if (offersLength === 6) {
      refetch();
    }
  }, [offersLength, refetch]);

  return (
    <>
      {/* Grid layout container */}
      <Grid container spacing={2}>
        {/* Breadcrumb navigation */}
        <Grid item xs={12}>
          <Breadcrumbs
            className="mt-4 ml-6"
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbItems.map((item, index) => (
              <React.Fragment key={item.label}>
                {/* Conditional rendering for breadcrumb links */}
                {index === breadcrumbItems.length - 1 ? (
                  <Link
                    href={item.href}
                    className="lastLink"
                    style={{
                      outline: "none",
                      color: "#BB3330",
                      fontWeight: "600",
                      textDecorationColor: "#bb3330",
                    }}
                  >
                    {item.label}
                  </Link>
                ) : (
                  <Link
                    href={item.href}
                    style={{
                      outline: "none",
                      textDecoration: "none",
                      color: "#545454",
                    }}
                  >
                    {item.label}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </Breadcrumbs>
        </Grid>

        {/* Categories Section */}
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="#0F0F0F"
            className="mt-4 ml-2 font-semibold text-lg leading-8"
          >
            Categories
          </Typography>
        </Grid>
      </Grid>

      {/* Category Cards */}
      <Grid container spacing={2}>
        {Categories?.data.map((category: any, index: number) => (
          <Grid item xs={12} sm={6} md={4} key={category.name}>
            <ButtonBase
              className="flex w-full h-full"
              onClick={() => navigateToTravel(category.name)}
            >
              <Card className="flex flex-row w-full m-1 h-[84px] justify-center">
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      {/* <Typography className="text-center">
                        {category.icon}
                      </Typography> */}
                    </Grid>
                    <Grid item xs={12}>
                      {category.url && (
                        <img
                          width={"40px"}
                          height={"40px"}
                          src={category.url}
                          alt="categoryLogo"
                        />
                      )}

                      <Typography
                        color="#333333"
                        className="font-medium text-sm leading-5 text-center"
                      >
                        {category.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>

      {/* Offers Section */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="#0F0F0F"
            className="mt-4 ml-2 font-semibold text-lg leading-8"
          >
            Offers
          </Typography>
        </Grid>
      </Grid>

      {/* Offer Cards */}
      <Grid container spacing={2}>
        {Offers?.data?.map(
          ({ companyName, companyId, companyUrl, id }: any) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              key={id}
              className="cursor-pointer"
              onClick={() => {
                // offer.link && navigate(offer.link);
                navigate(`/benefits/discounts/offer?id=${companyId}`);
              }}
            >
              <Card className="flex flex-row m-1 px-4 h-[265px] justify-center items-center">
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <img
                        className="w-full h-full "
                        src={companyUrl}
                        alt="companyLogo"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
};

export default BenefitsDiscount;
