//@ts-nocheck

import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  Container,
  TextField,
  Autocomplete,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackAndSaveButton from "./BackAndSaveButton";
import ShowBreadCrumbs from "./BreadCrumbs";
import {
  FetchClaimsDraftAPI,
  FetchDriverVehicleDetailAPI,
  FetchPolicyAddressAPI,
  FetchStateDetailAPI,
  UpdateClaimDraftAPI,
} from "queries/ClaimQueries";
import InputItem from "./InputItem";
import SelectDropDownItem from "./SelectDropDownItem";
import { Address, ClaimTypeEnums } from "../types";
import useQueryParams from "../../../utils/useQueryParams";
import { ClaimSteps } from "../constants";

// Define the validation schema for the form fields using Yup.
const schema = yup.object().shape({
  contact: yup
    .string()
    .test("required", "Vehicle Name is required", function (value) {
      // Custom validation logic to check if the value is not an empty string or 'Select'
      if (value === "" || value === "-- Select --") {
        return this.createError({
          path: "contact",
          message: "Contact Name is required",
        });
      }
      return true;
    }),
  addressLine1: yup
    .string()
    .required("Address Line 1 is required")
    .max(100, "Address must not exceed 100 characters"),
  addressLine2: yup
    .string()
    .nullable()
    .max(100, "Address must not exceed 100 characters"),
  addressLine3: yup.string(),
  zipCode: yup
    .string()
    .required("Zip code is required")
    .matches(/^\d{5}(-\d{4})?$/, "Zip code must be in a valid format"),
  city: yup
    .string()
    .required("City is required")
    .matches(/^[A-Za-z ]+$/, "City must contain only letters"),
  state: yup
    .string()
    .test("required", "Vehicle Name is required", function (value) {
      // Custom validation logic to check if the value is not an empty string or 'Select'
      if (value === "" || value === "-- Select --") {
        return this.createError({
          path: "state",
          message: "State Name is required",
        });
      }
      return true;
    }),
  primaryPhone: yup
    .string()
    .nullable()
    .required("Primary Number is required")
    .matches(/^[0-9]{10}$/, "Invalid Phone Number"),
  homePhone: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .matches(/^[0-9]{10}$/, "Invalid Phone Number"),
  workPhone: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .matches(/^[0-9]{10}$/, "Invalid Phone Number"),
  email: yup
    .string()
    .email()
    .matches(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.]{1}[a-zA-Z0-9-.]{1,}[a-z]{1,}$/,
      "Email is not valid"
    )

    .required("Email is required"),
});

// Define an array of input fields with their configurations.
const inputFields = [
  // {
  //   label: "Address Line 1",
  //   inputName: "addressLine1",
  //   xs: 12,
  //   isMandatory: true,
  //   placeholder: "Enter Address Line 1",
  // },
  {
    label: "Address Line 2",
    inputName: "addressLine2",
    xs: 6,
    isMandatory: false,
    placeholder: "Enter Address Line 2",
  },
  {
    label: "Address Line 3",
    inputName: "addressLine3",
    xs: 6,
    isMandatory: false,
    placeholder: "Enter Address Line 3",
  },
  {
    label: "Zip Code",
    inputName: "zipCode",
    xs: 6,
    isMandatory: true,
    placeholder: "Enter Zip Code",
    maxCharacters: 5,
    isValueAndOnChangeFn: true
  },
  {
    label: "City",
    inputName: "city",
    xs: 6,
    isMandatory: true,
    placeholder: "Enter City",
  },
  {
    label: "State",
    inputName: "state",
    xs: 6,
    isMandatory: true,
    // placeholder:"--Select--"
  },
  {
    label: "Primary Phone",
    inputName: "primaryPhone",
    xs: 6,
    isMandatory: true,
    placeholder: "Enter Primary Phone",
    maxCharacters: 10,
    isValueAndOnChangeFn: true
  },
  {
    label: "Home Phone",
    inputName: "homePhone",
    xs: 6,
    isMandatory: false,
    placeholder: "Enter Home Phone",
    maxCharacters: 10,
    isValueAndOnChangeFn: true
  },
  {
    label: "Work Phone",
    inputName: "workPhone",
    xs: 6,
    isMandatory: false,
    placeholder: "Enter Work Phone",
    maxCharacters: 10,
    isValueAndOnChangeFn: true
  },
  {
    label: "Email",
    inputName: "email",
    xs: 6,
    isMandatory: true,
    placeholder: "Enter Email",
  },
];

const ContactDetailsForm: React.FC = () => {
  const navigate = useNavigate();
  const { policyId, claimId, claimType } = useQueryParams([
    "policyId",
    "claimId",
    "claimType",
  ]);

  // Fetch data from various APIs using React Query.
  const { data: state } = FetchStateDetailAPI();
  const { data: driverData } = FetchDriverVehicleDetailAPI();
  const { mutate: draftData } = UpdateClaimDraftAPI();
  const { data: claimsDraftDetail } = FetchClaimsDraftAPI(claimId || "");
  const { data: claimsDraftDetailData } = claimsDraftDetail || {};

  const [contactDetailState, setContactDetailState] = useState({
    zipCode: '',
    primaryPhone: '',
    homePhone: '',
    workPhone: ''
  })
  
  const { data: policyAddressesDetail } = FetchPolicyAddressAPI();
  const policyAddressesData = useMemo(() => {
    console.log({});
    return policyAddressesDetail?.data.map((item) => {
      const addrKeys = [
        "address_line_1",
        "address_line_2",
        "city",
        // "state",
        "zipCode",
      ];
      let addrStr = "";
      console.log({ item });
      for (let key in item) {
        console.log(item[key] && addrKeys.includes(key), "item");
        if (item[key] && addrKeys.includes(key)) {
          addrStr += `${item[key]} `;
        }
      }
      return { value: item, label: addrStr.trim() };
    });
  }, [policyAddressesDetail?.data]);




  // Initialize the React Hook Form with validation schema.
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      contact: "-- Select --",
      state: "ID"
    }
  });

  const [addressLine1] = watch(["addressLine1"]);
  

  // Function to navigate to the previous screen.
  const navigateToPrevScreen = () => {
    navigate(`/add-witness${window.location.search}`);
  };

  useEffect(() => {
    // Redirect to the claims page if necessary conditions are not met.
    if (!policyId || !claimId || claimsDraftDetailData === null) {
      navigate("/claims");
    }
  }, [claimId, navigate, policyId, claimsDraftDetailData]);

  useEffect(() => {
    // Populate form fields with data if available in the draft.
    if (claimsDraftDetailData?.witness) {
      for (let key in claimsDraftDetailData.witness) {
        if (
          (key === "firstName" && claimsDraftDetailData.witness.firstName) ||
          (key === "lastName" && claimsDraftDetailData.witness.lastName)
        ) {
          setValue(
            "contact",
            `${claimsDraftDetailData.witness.firstName} ${claimsDraftDetailData.witness.lastName}`
          );
          continue;
        }
        if (
          key === "firstName" &&
          claimsDraftDetailData.witness.firstName === ""
        ) {
          setValue("contact", "-- Select --");
          continue;
        }
        if (key === "state" && claimsDraftDetailData.witness.state === "") {
          setValue("state", "-- Select --");
          continue;
        }

        if(key === 'zipCode' || key === 'primaryPhone' || key === 'homePhone' || key === 'workPhone'){
          setContactDetailState((prev) => ({...prev, [key]: claimsDraftDetailData.witness[key] || ""}))
        }

        setValue(
          key as keyof Address,
          claimsDraftDetailData.witness[key] || ""
        );
      }
    }
  }, [claimsDraftDetailData, setValue]);

  // Function to handle the form submission and update the claim draft.
  const handleUpdateClaimDraftAPI = () => {
    const {
      contact,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      state,
      zipCode,
      primaryPhone,
      homePhone,
      email,
      workPhone,
    } = getValues();

    const [firstName, lastName] = contact?.split(" ") || [];
    

    draftData(
      {
        step: ClaimSteps[claimType as ClaimTypeEnums]["contact-details"],
        policyId,
        draftId: claimsDraftDetailData?.draftId,
        hasUnknownTime: claimsDraftDetailData?.hasUnknownTime,
        witnessContactDetail: {
          firstName,
          lastName,
          addressLine1,
          addressLine2,
          addressLine3,
          city,
          state,
          zipCode,
          primaryPhone,
          homePhone,
          workPhone,
          email,
        },
      },
      {
        onSuccess: () => {
          navigate(`/summary${window.location.search}`);
        },
      }
    );
  };

  // Memoized data for driver dropdown.
  const updatedDriverData = useMemo(
    () =>
      (driverData?.data || []).filter(driver => driver.firstName && driver.lastName)
        .map(({ driverId, firstName, middleName, lastName }) => ({
          id: driverId,
          name: `${firstName || ''} ${middleName || ''} ${lastName || ''}`,
        })),
    [driverData?.data]
  );

  const handleContactDetailState = (key, value) => {
    const updatedValue = value.replace(/[^0-9]/g, '');
    let sliceUpto = 10;
    if(key === 'zipCode'){
      sliceUpto = 5;
    }
    // Ensure only numeric characters are allowed

    // Restrict to 5 characters
    const limitedValue = updatedValue.slice(0, sliceUpto);

    console.log({limitedValue})

    // Handle your state or other logic here
    setContactDetailState((prev) => ({...prev, [key] : limitedValue}))
 }



  return (
    <div>
      <ShowBreadCrumbs />
      <Grid item xs={12} className="flex justify-between pr-7">
        <Typography className="font-semibold text-lg leading-7 mt-5">
          Contact Details
        </Typography>
        <Typography className="font-semibold leading-7 mt-5 text-[#545454] text-[10px] bg-[#F5F5F5] pl-[14px] pr-[14px] rounded">
          New Claim
        </Typography>
      </Grid>
      <Container className="mx-auto mt-4 -ml-5">
        <form onSubmit={handleSubmit(handleUpdateClaimDraftAPI)}>
          <Grid container spacing={3} className="mt-4">
            {/* Render input fields and dropdowns */}
            <SelectDropDownItem
              label="Contact"
              control={control}
              name="contact"
              errors={errors}
              data={updatedDriverData || []}
            />

<Grid item xs={12}>
              <Typography marginBottom={1} fontWeight={400} fontSize={12}>
                Address Line 1<span className="text-error-500">*</span>
              </Typography>
              <Controller
                name="addressLine1"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  // <TextField
                  //   size="small"
                  //   placeholder="Enter Address Line 1"
                  //   fullWidth
                  //   {...field}
                  //   type="string"
                  //   error={!!errors?.addressLine1}
                  //   helperText={errors?.addressLine1?.message || ''}
                  // />
                  <Autocomplete
                    options={policyAddressesData || []}
                    getOptionLabel={(option) => option.label}
                    freeSolo
                    inputValue={addressLine1 || ""}
                    onChange={(event, newInputValue = {}) => {
                      const matchingOption = policyAddressesData.find(
                        (option) => option.label === newInputValue
                      );
                      const selectedValue =
                        newInputValue?.value || matchingOption?.value || {};

                      const keyValue = {
                        addressLine1: "address_line_1",
                        addressLine2: "address_line_2",
                        zipCode: "zipCode",
                        city: "city",
                        // state: "state",
                      };

                      for (let key in keyValue) {
                        if (keyValue[key]) {
                          let value = selectedValue[keyValue[key]];
                          if (key === "zipCode") {
                            value = selectedValue[keyValue[key]]?.split("-")[0];
                            setContactDetailState((prev)=>({
                             ...prev,zipCode:value
                            }))
                          }
                          setValue(key, value);
                        }
                      }
                      // setValue('addressLine1', selectedValue.address_line_1 || "")
                      // setValue('addressLine2', selectedValue.address_line_2 || "")
                      // setValue('zipCode', selectedValue.zipCode || "")
                      // setValue('city', selectedValue.city || "")
                      // setValue('state', selectedValue.state || "-- Select --")
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Enter Address Line 1"
                        fullWidth
                        {...field}
                        type="string"
                        error={!!errors?.addressLine1}
                        helperText={errors?.addressLine1?.message || ""}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            {inputFields.map((item) => {
              if (item.inputName === "state") {
                return (
                  <SelectDropDownItem
                    label="State"
                    control={control}
                    name="state"
                    errors={errors}
                    data={state?.data}
                  />
                );
              }

              return (
                <InputItem
                  placeholder={item.placeholder}
                  {...item}
                  {...(item.isValueAndOnChangeFn ? 
                    { isValueAndOnChangeFn: true, 
                      value: contactDetailState[item.inputName],
                      onValueChange: (value) => handleContactDetailState(item.inputName, value)
                    }: {}
                    )}
                  control={control}
                  errors={errors}
                />
              );
            })}
          </Grid>

          {/* Render Back and Save buttons */}
          <BackAndSaveButton
            isValid={isValid}
            backButtonHandler={navigateToPrevScreen}
            saveAndNextHandler={handleSubmit(handleUpdateClaimDraftAPI)}
          />
        </form>
      </Container>
    </div>
  );
};

export default ContactDetailsForm;
