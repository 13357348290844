// Importing necessary components and hooks
import { Avatar, Typography } from "@mui/material";
import Icons from "constants/Icon";
import { useEffect, useState } from "react";
import UserAccount from "./userAccount";
import {
  FetchUserDetailsAPI,
  useGetNotifications,
  useSendDeviceToken,
} from "queries/AuthQueries";
import DropdownIcon from "assets/Icons/chevron-down.svg";
import { onMessageListener, requestForToken } from "utils/firebase";
import { useQueryClient } from "react-query";
import { NotificationBox } from "./NotificationBox";

/**
 * TopNav is a functional component that renders the top navigation bar of the application.
 */
const TopNav = () => {
  // State and hooks for various functionalities
  const queryClient = useQueryClient();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false); // State for user account popover
  const [isNotificationOpen, setIsNotificationOpen] = useState(false); // State for notifications popover
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for user account popover
  const [anchorNotificationEl, setAnchorNotificationEl] = useState(null); // Anchor element for notifications popover
  const { mutate } = useSendDeviceToken(); // Hook to send device token for notifications
  const { data: myNotifications } = useGetNotifications(); // Hook to get notifications

  // Effect for requesting and sending device token for notifications
  useEffect(() => {
    requestForToken()
      .then((res) => {
        mutate({
          deviceToken: res,
          deviceType: "WEB",
        });
      })
      .catch((err) => console.log(err));
  }, []);

  // Effect for handling incoming messages and refreshing notifications
  useEffect(() => {
    onMessageListener(() => {
      queryClient.invalidateQueries(["device-notifications-info"]);
    });
  }, []);

  // Function to handle clicks on avatar (user account)
  const handleAvatarClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  // Function to handle clicks on notification icon
  const handleNotificationClick = (event: any) => {
    setAnchorNotificationEl(event.currentTarget);
    setIsNotificationOpen(true);
  };

  // Function to close the user account popover
  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  // Function to close the notifications popover
  const handleNotificationClose = () => {
    setIsNotificationOpen(false);
  };

  // Fetching user details using a custom hook
  const { data: userDetails } = FetchUserDetailsAPI();

  // Extracting user's name and initials for display
  let name = "";
  if (userDetails) {
    name = userDetails.data.firstName;
  }
  let initials =
    name
      ?.split(" ")
      .map((part) => part.charAt(0))
      .join("") || "";

  // Rendering the TopNav component
  return (
    <div className="border-b border-solid border-[#F5F5F5]">
      {/* ... Navigation bar layout with user account and notifications */}
      <div className="m-auto items-center">
        <div className="m-auto ml-[120px] flex justify-between py-4">
          <div className="flex items-center">
            <Typography
              variant="h6"
              color="white"
              className="flex items-center text-black font-semibold"
            >
              {Icons.MAIN_LOGO}
            </Typography>
          </div>
          <div className="flex items-center justify-center gap-7 mr-[120px]">
            <div className="flex items-center  relative">
              <span
                className=" cursor-pointer flex items-start"
                onClick={handleNotificationClick}
              >
                {myNotifications?.data.length > 0
                  ? Icons.UNREAD_NOTIFICATION
                  : Icons.NOTIFICATION}
              </span>
            </div>
            <Typography
              variant="subtitle1"
              color="secondary"
              className="questrial hidden font-semibold md:block"
            >
              Hello {name?`, ${name.toUpperCase()}`:"" }
            </Typography>
            <div className="flex items-center">
              <Avatar
                sx={{ bgcolor: "#BB3330", marginLeft: "8px" }}
                onClick={handleAvatarClick}
              >
                {initials}
              </Avatar>
              <img
                src={DropdownIcon}
                alt="Dropdown"
                onClick={handleAvatarClick}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleAvatarClick(e);
                  }
                }}
                tabIndex={0}
                style={{
                  cursor: "pointer",
                  height: "20px",
                  width: "20px",
                  marginLeft: "8px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <UserAccount
        open={isPopoverOpen}
        anchorEl={anchorEl}
        handleClose={handlePopoverClose}
        userDetails={userDetails?.data}
      />
      <NotificationBox
        myNotifications={myNotifications?.data}
        open={isNotificationOpen}
        anchorEl={anchorNotificationEl}
        handleClose={handleNotificationClose}
      />
    </div>
  );
};

// Exporting the TopNav component
export default TopNav;
