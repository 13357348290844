// Type definition for the response object of the FetchClaimsDraft API
export type FetchClaimsDraftResponse = {
  message: string
  data: FetchClaimsDraftData
}

// Type definition for the data within the FetchClaimsDraftResponse
export type FetchClaimsDraftData = {
  step: number
  claimId: string
  policyId: number
  dateOfLoss: string
  claimType: string
  describeDamage: string
  describeWhatHappened: string
  anyPropertyDamaged: string
  descriptionOfPropertyDamage: string
  typeOfTheft: any
  offeringCode: string
  status: string
  updatedAt: string
  locationAddress: LocationAddress
  primaryContact: PrimaryContact
  vehicles: Vehicles
  witnessesOrParties: WitnessesOrParties
  witness: any
  draftId: string
  hasUnknownTime: boolean;
}

// Type definition for toggleable vehicle attributes in the claim form
export type IAboutVehicleToggleKeys = {
  isVehicleOperable: boolean
  areAirbagsDeployed: boolean
  doesEquipmentFailureExists: boolean
  doesVehicleNeedTowed: boolean
  isRentalNeeded: boolean
}

// Type definition for a partial address object
export type IPartialAddress = {
  city: string
  state: string
}

// Detailed type definition for a location address
export type LocationAddress = {
  addressLine1: string
  addressLine2: string
  addressLine3: string
  city: string
  state: string
  zipCode: string
}

// Type definition for primary contact information, including an address
export type PrimaryContact = {
  address: Address
}

// Type definition for an address object
export type Address = {
  addressLine1: string
  addressLine2: string
  addressLine3: string
  city: string
  state: string
  zipCode: string
}

// Type definition for vehicle information within a claim
export type Vehicles = {
  entry: Entry[]
}

// Type definition for individual vehicle entry, including driver and vehicle details
export type Entry = IAboutVehicleToggleKeys & {
  driverFirstName: string
  driverLastName: string
  color: string
  driverHomePhone: string
  driverMobilePhone: string
  licensePlate: string
  licenseState: string
  make: string
  model: string
  passengers: Passengers
  vin: string
  year: number
}

// Type definition for passengers associated with a vehicle
export type Passengers = {
  entry: PassengersEntry[]
}

// Type definition for individual passenger entry
export type PassengersEntry = {
  id: number,
  firstName: string
  lastName: string
  phoneNumber: string
}

// Type definition for witnesses or other parties involved in the claim
export type WitnessesOrParties = {
  entry: Entry3[]
}

// Type definition for individual witness or party entry
export type Entry3 = {
  firstName: string
  isInjured: boolean
  isWitness: boolean
  lastName: string
  phoneNumber: string
}

// Type definition for the response of the FetchClaimsDraft API
export interface IFetchClaimsDraftResponse {
  message: string
  data: IFetchClaimsDraftResponseData[]
}

// Type definition for the data part of the FetchClaimsDraftResponse
export interface IFetchClaimsDraftResponseData {
  policyId: number
  policyNo: string
  name: string
  claimId: string
  claimType: string
  status: string
}

// Type definition for the response of the DriverVehicleDetail API
export type DriverVehicleDetailAPIResponse = {
  message: string
  data: DriverVehicleDetailAPIResponseData[]
}

// Type definition for individual vehicle and driver details in the DriverVehicleDetailAPI response
export type DriverVehicleDetailAPIResponseData = {
  vechileId: number
  driverId: number
  firstName: string
  middleName: string
  lastName: string
  dob: string
  licenseNo: string
  vin: string
  make: string
  model: string
}

// Enumeration for different types of claims
export enum ClaimTypeEnums {
  collision_and_rollover = 'collision_and_rollover',
  impact_with_an_animal = 'impact_with_an_animal',
  theft = 'theft'
}
