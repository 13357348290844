// Importing necessary Material UI components, icons, and other assets

import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NewPhoneIcon from "assets/Icons/phoneIcon (1).svg";
import ProfileIcon from "assets/Images/profileIcon.svg";
import MarkerPinIcon from "assets/Icons/marker-pin-01.svg";
import NavigatorPointerIcon from "assets/Icons/navigation-pointer-01.svg";
import PhoneIcon from "../../assets/Icons/phone.svg";
import EmailIcon from "../../assets/Icons/mail-01.svg";
import AddExistingPolicyDialog from "components/Policy/AddExistingPolicy";
import { useMemo, useState } from "react";
import {
  FetchPoliciesAPI,
  FetchPolicyAgentAPI,
  FetchPolicySyncAPI,
} from "queries/PolicyQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchBillsAPI, FetchNextBillAPI } from "queries/BillsQueries";
import Branding from "components/Branding";

// MyAgent Component: Displays agent information and allows interaction with policies

const MyAgent: React.FC<any> = (props) => {
  const currentLocation = useLocation().pathname;
  // console.log("currentLocation",currentLocation)
  const { currentPageProps } = props;
  const showAssist = currentPageProps.showAssistance;
  const currentPath = currentPageProps.pathname;
  const [openDialog, setOpenDialog] = useState(false);
  const { data: bill, dataUpdatedAt } = FetchNextBillAPI();
  const { data: receivedBills } = FetchBillsAPI(1, 50, "ASC", false);
  const { data: policies } = FetchPoliciesAPI();
  const { data: policySync } = FetchPolicySyncAPI();

  const { policyNo, amountDue, invoiceId } = receivedBills?.data?.[0] || {};

  let policyList: any = [];

  // Setting the list of policies if data is available
  if (policies) {
    policyList = policies.data;
  }

  const navigate = useNavigate();
  const { nextBillData } = useMemo(() => {
    if (dataUpdatedAt) {
      console.log("nextBillData", bill?.data, dataUpdatedAt);
      return {
        nextBillData: bill?.data,
      };
    } else {
      return {
        nextBillData: {},
      };
    }
  }, [dataUpdatedAt]);

  // Function to open the dialog for adding a new policy

  const openAddPolicyDialog = () => {
    setOpenDialog(true);
  };

  // Function to close the dialog

  const handleClose = () => {
    setOpenDialog(false);
  };

  // Fetching agent data using a custom API hook

  const { data: agent } = FetchPolicyAgentAPI();
  const agentData = agent?.data?.agent;

  // Function to extract initials from the agent's name

  function getInitials(firstName: string, lastName: string) {
    return `${firstName.charAt(0).toUpperCase()}${lastName
      .charAt(0)
      .toUpperCase()}`;
  }

  let emails = agentData?.email?.split(" Or ");
  // console.log(agentData);

  // const address = `${agentData?.agentDetail?.address_line_1 || "" + ","} ${
  //   agentData?.agentDetail?.address_line_2
  //     ? agentData?.agentDetail?.address_line_2 + ","
  //     : ""
  // }${agentData?.agentDetail?.city + ","} ${agentData?.agentDetail?.state} ${
  //   agentData?.agentDetail?.zipCode
  // }`;

  const address =
    `${
      agentData?.agentDetail?.address_line_1
        ? agentData.agentDetail.address_line_1 + ","
        : ""
    }` +
    `${
      agentData?.agentDetail?.address_line_2
        ? agentData.agentDetail.address_line_2 + ","
        : ""
    }` +
    `${agentData?.agentDetail?.city ? agentData.agentDetail.city + "," : ""}` +
    `${
      agentData?.agentDetail?.state ? agentData.agentDetail.state + " " : ""
    }` +
    `${agentData?.agentDetail?.zipCode ? agentData.agentDetail.zipCode : ""}`;


    // display number in USA formate
    function formatUSPhoneNumber(phoneNumber:string) {
      const phoneStr = phoneNumber;
      if (phoneStr.length !== 10) {
          return "+1 "+phoneStr
      }
      return "+1"+phoneStr.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
  }


  // Component render

  return (
    <Grid container className="h-screen">
      {/* Conditional rendering based on whether to show assistance or not */}
      {!showAssist ? (
        <Grid item xs={12} className="space-y-4">
          {(currentPath === "/bills" ||
            currentPath === "/policies" ||
            currentPath === "/dashboard") &&
            policyList?.length > 0 && (
              <Grid
                style={{ background: "#F5F5F5", borderRadius: "8px" }}
                className="w-[384px] h-[auto] mt-4 p-4 "
              >
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color="#0F0F0F"
                    className="mt-4 ml-6 font-medium text-base leading-7"
                  >
                    My Agent
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="#545454"
                    className="mt-1 ml-6 font-normal text-xs leading-4"
                  >
                    Stay connected, stay protected
                  </Typography>
                </Grid>
                <Grid
                  spacing={1}
                  className="flex flex-col m-3 p-4 gap-2 w-[336px] max-h-[210px] bg-white rounded-md shadow-md"
                >
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <Box display="flex" alignItems="center">
                        {agentData ? (
                          <Avatar
                            className="mt-1 h-[64px] w-[64px] text-[20px] leading-[32px]"
                            sx={{
                              bgcolor: "#262626",
                            }}
                          >
                            {getInitials(
                              agentData.firstName,
                              agentData.lastName
                            )}
                          </Avatar>
                        ) : (
                          <img src={ProfileIcon} />
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondaryInfo"
                        className="items-start font-medium text-[16px] leading-[28px]"
                      >
                        {agentData?.firstName || ""} {agentData?.lastName || ""}
                      </Typography>
                      <div className="flex gap-1">
                        <Typography className="text-start mt-1">
                          <img src={MarkerPinIcon} alt="" />
                        </Typography>
                        <Typography
                          color="#757575"
                          className="text-start font-medium text-[12px] leading-[20px]"
                        >
                          {address}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography className="text-end mt-5 cursor-pointer">
                          <img
                            src={NavigatorPointerIcon}
                            style={{ marginTop: "17px" }}
                          />
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>
                  {agentData?.phone && (
                    <Grid item xs={12}>
                      <Button
                        color="secondary"
                        fullWidth
                        type="submit"
                        size="large"
                        startIcon={<img src={NewPhoneIcon} />}
                        className="w-[311px] h-[32px] items-center justify-center flex !bg-[#262626] text-white"
                      >
                        <span className="text-base font-medium text-sm">
                         {formatUSPhoneNumber(agentData?.phone)}
                        </span>
                      </Button>
                    </Grid>
                  )}

                 {!agentData?.email.includes("null") &&  <Grid item xs={12}>
                    <Button
                      color="secondary"
                      fullWidth
                      type="submit"
                      size="large"
                      startIcon={<img src={EmailIcon} />}
                      className="w-[311px] h-[32px] items-center justify-center m-auto flex border border-[#0F0F0F]"
                    >
                      <span className="text-base font-medium text-sm">
                        {emails && (
                          <a
                            style={{ textDecoration: "none", color: "black" }}
                            href={`mailto:${emails[0]},${emails[1]}`}
                          >
                            {emails[1] ? emails[1] : ""}
                          </a>
                        )}
                      </span>
                    </Button>
                  </Grid>}
                </Grid>

                <Box
                  className="w-[384px] ml-[-16px]"
                  sx={{ borderBottom: 1, color: "#CCCCCC" }}
                ></Box>
                {
                  currentPath !== "/benefits" && (
                    <div>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          color="#0F0F0F"
                          className="mt-4 ml-6 font-medium font-inter text-base leading-7"
                        >
                          Can’t find what you are looking for?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="flex items-center mt-4 ml-6 space-x-2">
                          <img src={PhoneIcon} alt="Phone" />
                          <Typography
                            variant="subtitle1"
                            color="#0F0F0F"
                            className="font-medium font-inter text-base leading-7"
                          >
                            Phone
                          </Typography>
                        </div>
                        <Typography
                          variant="subtitle1"
                          color="#545454"
                          className="mt-1 ml-6 font-inter font-normal text-xs leading-4"
                        >
                          {agentData?.generalSupportPhone}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="flex items-center mt-4 ml-6 space-x-2">
                          <img src={EmailIcon} alt="Phone" />
                          <Typography
                            variant="subtitle1"
                            color="#0F0F0F"
                            className="font-medium font-inter text-base leading-7"
                          >
                            Email
                          </Typography>
                        </div>
                        <Typography
                          variant="subtitle1"
                          color="#545454"
                          className="mt-1 ml-6 font-inter font-normal text-xs leading-4"
                        >
                          <a
                            style={{ textDecoration: "none", color: "black" }}
                            href={`mailto:${agentData?.generalSupportEmail}`}
                          >
                            {agentData?.generalSupportEmail}
                          </a>
                        </Typography>
                      </Grid>
                    </div>
                  )
                  // : (
                  //   <Grid>
                  //     <Typography
                  //       variant="subtitle1"
                  //       color="#0F0F0F"
                  //       className="mt-4 ml-6 font-inter font-medium text-[16px] leading-[28px]"
                  //     >
                  //       You may contact Idaho Farm Bureau Federation by phone at{" "}
                  //       <span className="text-[#BB3330]">1208 232 7914</span> or Visit{" "}
                  //       <span className="text-[#BB3330]">idahofb.org</span>
                  //     </Typography>
                  //   </Grid>
                  // )
                }
              </Grid>
            )}
          <div className="py-1 flex items-center justify-center">
            <Branding />
          </div>
          <Grid item xs={12} className="w-[384px]">
            <Button
              color="secondary"
              fullWidth
              size="large"
              className={`items-center justify-center m-auto flex h-12 border ${
                currentPath === "/policies"
                  ? "bg-black text-white border-black"
                  : "border-[#0F0F0F]"
              } gap-[8px]`}
              onClick={openAddPolicyDialog}
            >
              <AddIcon style={{ height: "20px", width: "20px" }} />
              <span
                className={`text-base font-medium leading-7 text-center ${
                  currentPath === "/policies" ? "text-white" : "text-gray-700"
                }`}
                color="#545454"
              >
                Add Existing Policy
              </span>
            </Button>
            <AddExistingPolicyDialog
              open={openDialog}
              handleClose={handleClose}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          style={{ background: "#F5F5F5", borderRadius: "8px" }}
          className="mt-4 p-4 w-[384px] h-[128px] flex  justify-between"
        >
          <div>
            <Typography
              variant="subtitle1"
              color="#0F0F0F"
              className="mt-4 ml-6 font-medium text-base leading-7"
            >
              {currentLocation === "/select-method"
                ? `Policy No ${
                    nextBillData?.policyNo || policyNo || "Policy No"
                  }`
                : "For all other Payment Questions call"}
            </Typography>
            <div className="flex items-center mt-1 ml-6 gap-[8px]">
              {currentLocation !== "/select-method" && (
                <img
                  src={PhoneIcon}
                  alt="Phone"
                  className="h-[32px] w-[32px]"
                />
              )}
              <Typography
                variant="subtitle1"
                color="#262626"
                className="font-semibold text-3xl leading-9"
              >
                {currentLocation === `/select-method`
                  ? `$ ${nextBillData?.amountDue || 1 || "Amount Due"}`
                  : "1-833-996-3276"}
              </Typography>
            </div>
          </div>
          {currentLocation === "/select-method" && (
            <div className="flex flex-col justify-end align-bottom items-end">
              <Typography color={"grey.600"} fontWeight={400} fontSize={10}>
                Invoice ID
              </Typography>
              <Typography fontWeight={500} fontSize={12}>
                {nextBillData?.invoiceId || invoiceId || "Invoice Id"}
              </Typography>
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default MyAgent;
