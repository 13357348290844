import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Radio,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./index.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateIcon from "assets/Icons/calender.svg";
import timePickerIcon from "assets/Icons/timePicker.svg";
import { useForm } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  FetchClaimsDetailAPI,
  FetchPolicyClaimDetailAPI,
  UpdateClaimDraftAPI,
} from "queries/ClaimQueries";
import { useQueryClient } from "react-query";
import { setLocalStorageItem } from "hooks/localStorageHelper";

// Breadcrumbs items for navigation
const breadcrumbItems = [
  { label: "Home", href: "/dashboard" },
  { label: "Claims", href: "/claims" },
  { label: "Report a Claim", href: "/report-claim" },
];

// ClaimsForm component for claim report submission
const ClaimsForm = () => {
  // State for managing date and time selection
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  // State for managing selected policy data
  const [policyData, setPolicyData] = useState<null | {id: number, policyNo: string}>(null);
  const [unknownTime,setUnknownTime] = useState(false)
  // Mutation hook for updating claim draft
  const { mutate: draftData } = UpdateClaimDraftAPI();

  // Handlers for date and time selection
  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (newTime: any) => {
    setSelectedTime(newTime);
  };

  // Handler for insurance policy selection
  const handleInsuranceChange = (id: number, policyNo: string) => {
    setPolicyData({id, policyNo});
  };

  // Fetching policy claim details
  const { data: policyClaimDetails } = FetchPolicyClaimDetailAPI();
  const queryClient = useQueryClient();

  // Handler for updating claim draft API
  const handleUpdateClaimDraftAPI = () => {
    if (!selectedDate || (!unknownTime && !selectedTime)) return;
    let dateOfLoss: Date | null = new Date(selectedDate);

    if (!unknownTime && selectedTime) {
      const time = new Date(selectedTime);
      dateOfLoss.setHours(time.getHours(), time.getMinutes(), time.getSeconds());
    }
  
    let dateOfLossISO: string | null = null;
  
    if (dateOfLoss) {
      dateOfLossISO = dateOfLoss.toISOString();
    } else {
      // Handle the case when dateOfLoss is null
      console.error("Date of loss is null");
      return;
    }

    const { id: policyId, policyNo } = policyData || {}

    // Draft data mutation
    draftData(
      {
        step: 1,
        hasUnknownTime: unknownTime,
        policyId,
        dateOfLoss,
      },
      {
        onSuccess: (response) => {
          queryClient.invalidateQueries(['GET_CLAIMS_DRAFT_DETAIL']);
          const {claimId, draftId} = response?.data || {};
          // setLocalStorageItem('unknownTime' , {hasUnknownTime: unknownTime, claimId })
          navigate(`/select-claim?policyId=${policyId}&claimId=${claimId}&policyNo=${policyNo}&draftId=${draftId}`);
        },
      }
    );
  };

  // Utility functions
  const isToday = (date: Date) => {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
  };
  
  const getMaximumTime = () => {
    const now = new Date();
    return new Date(0, 0, 0, now.getHours(), now.getMinutes());
  };

  // Form handling using react-hook-form
  const { handleSubmit } = useForm({});

  // Error handler for form submission
  const handleClaimsErrors = (err: any) => {
    console.log(err, "errors");
  };

  // Navigation hook and handler for returning to the claims page
  const navigate = useNavigate();
  const navigateToClaim = (id: any) => {
    navigate(`/claims`);
  };

  // Custom input components for DatePicker and TimePicker
  const CustomDatePickerInput: React.FC<any> = ({ value, onClick }) => (
    // Custom DatePicker input component
    <div className="relative">
      <input
        type="text"
        value={value || ""}
        placeholder="MM/DD/YYYY"
        onClick={onClick}
        className="h-[48px] rounded-md pl-10 pr-0 pt-0 pb-0 border border-[#E3E3E3] border-solid font-normal"
        style={{fontSize:"16px" , width:"450px"}}
        readOnly
      />
      <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
        <img src={dateIcon} alt="" />
      </div>
    </div>
  );

  const CustomTimePickerInput: React.FC<any> = ({ value, onClick }) => (
    // Custom TimePicker input component
    <div className="relative">
      <input
        type="text"
        disabled={unknownTime}

        value={ unknownTime?"HH:MM":value}
        placeholder="HH:MM"
        onClick={onClick}
        className="h-[48px] rounded-md pl-10 pr-0 pt-0 pb-0 border border-[#E3E3E3] border-solid font-normal"
        style={{fontSize:"16px", width:"450px"}}
        readOnly
      />
      <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
        <img src={timePickerIcon} alt="" />
      </div>
    </div>
  );

  return (
    <>
      <form
        onSubmit={handleSubmit(handleUpdateClaimDraftAPI, handleClaimsErrors)}
        id="claims-form"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs className="mt-4 ml-6" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbItems.map((item, index) => (
                <React.Fragment key={index}>
                  {index === breadcrumbItems.length - 1 ? (
                    <Link
                      href={item.href}
                      className="lastLink"
                      style={{ outline: "none", color: "#BB3330", fontWeight:"600"  }}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    <Link href={item.href} style={{ outline: "none", textDecoration:"none" , color:"#545454" }}>
                      {item.label}
                    </Link>
                  )}
                </React.Fragment>
              ))}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Accordion defaultExpanded className="shadow-none">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="font-semibold text-xl leading-7">
                  Policy
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className="flex flex-col pl-2 pb-3" spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      color="#333333"
                      className="font-normal text-sm leading-4 mb-2"
                    >
                      When did loss occur?
                      <span className="text-error-500">*</span>
                    </Typography>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="P"
                      customInput={<CustomDatePickerInput />}
                      className="h-[48px] rounded-md w-[200px]"
                      maxDate={new Date()} 
                      // excludeDates={[today, yesterday]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      color="#333333"
                      className="font-normal text-sm leading-4 mb-2"
                    >
                      What time?
                      <span className="text-error-500">*</span>
                    </Typography>
                    <DatePicker
                    disabled={unknownTime}
                      selected={selectedTime}
                      onChange={handleTimeChange}
                      showTimeSelect
                      
                      showTimeSelectOnly
                      timeIntervals={1}
                      minTime={new Date(0, 0, 0, 0, 0)}
                      maxTime={selectedDate && isToday(selectedDate) ? getMaximumTime() : new Date(0, 0, 0, 23, 59)}
                      dateFormat="h:mm aa"
                      timeCaption="Time"
                      timeFormat="h:mm aa"
                      customInput={<CustomTimePickerInput />}
                      className="h-[48px] rounded-md !w-[450px]"
                      wrapperClassName="react-datepicker-wrapper"
                      popperClassName="react-datepicker-popper"
                    />
                  </Grid>
                  <div className="ml-5 mt-4">
                    
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={unknownTime}
                  onChange={() => {
                    setUnknownTime((prev) => !prev);
                  }}
                  // sx={{
                  //   color: `${red[800]} !important`,
                  // }}
                />
              }
              label="Unknown Time"
            />
          </FormGroup>
          </div>
                </Grid>
                <Grid container>
                  <Typography
                    variant="body1"
                    className="font-normal text-sm leading-4 m-2"
                  >
                    Select Insurance
                  </Typography>
                  {policyClaimDetails?.data?.filter(({ type }: { type: string }) => type !== "homePolicy") .map(
                    ({
                      id,
                      name,
                      policyNo,
                    }: {
                      id: number;
                      name: string;
                      policyNo: string;
                    }) => (
                      <Card variant="outlined" className="w-full m-2 h-[68px] shadow-none">
                        <CardContent>
                          <Grid container direction="row">
                            <Grid item xs={12} sm={10}>
                              <Typography
                                variant="body1"
                                className="font-semibold text-sm leading-5"
                              >
                                {name}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                className="font-normal text-[10px] leading-4"
                              >
                                Policy No. {policyNo}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                              <div className="flex justify-end">
                                <Radio
                                  checked={policyData?.id === id}
                                  onChange={() => handleInsuranceChange(id, policyNo)}
                                  value={id}
                                  name="radio-buttons"
                                  inputProps={{
                                    "aria-label": "Auto Insurance",
                                  }}
                                  style={{
                                    color: `${
                                      policyData?.id === id ? "#BB3330" : "#EDEDED"
                                    }`, paddingTop:"0",
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    )
                  )}

                  {/* <Card variant="outlined" className="w-full m-2 h-[68px]">
                    <CardContent>
                      <Grid container direction="row">
                        <Grid item xs={12} sm={10}>
                          <Typography
                            variant="body1"
                            className="font-semibold text-base leading-5"
                          >
                            Farm Insurance
                          </Typography>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            className="font-normal text-xs leading-4"
                          >
                            Policy No. 1245879652145
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <div className="flex justify-end">
                            <Radio
                              checked={selectedInsurance === "farm"}
                              onChange={handleInsuranceChange}
                              value="farm"
                              name="radio-buttons"
                              inputProps={{ "aria-label": "Farm Insurance" }}
                              style={{
                                color: `${
                                  selectedInsurance === "farm"
                                    ? "#BB3330"
                                    : "#EDEDED"
                                }`,
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card> */}
                  <Grid
                    item
                    xs={12}
                    className="w-full p-1 flex justify-between"
                  >
                    <Button
                      variant="text"
                      className="h-12 text-[#333333] text-md font-medium"
                      onClick={navigateToClaim}
                    >
                      <IconButton>
                        <ArrowBackIcon className="text-[#333333] w-5 h-5"/>
                      </IconButton>
                      Back
                    </Button>
                    <Button
                      color="secondary"
                      fullWidth
                      type="submit"
                      className="custom-button h-11 w-[139px] text-[16px] leading-[28px] font-medium"
                      // disabled={!(selectedDate && selectedTime && policyData?.id)}
                      disabled={
                        !(
                          (selectedDate && selectedTime && policyData?.id && !unknownTime) ||
                          (selectedDate && policyData?.id && unknownTime)
                        )
                      }
                      onClick={handleSubmit(
                        handleUpdateClaimDraftAPI,
                        handleClaimsErrors
                      )}
                    >
                      Save and Next
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* <Grid item xs={12}>
            <Accordion className="shadow-none bg-[#F5F5F5]">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="font-semibold text-lg leading-7">
                  Incident
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Accordion className="shadow-none bg-[#F5F5F5]">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="font-semibold text-lg leading-7">
                  Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            <Accordion className="shadow-none bg-[#F5F5F5]">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="font-semibold text-lg leading-7">
                  Contact Info
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion className="shadow-none bg-[#F5F5F5]">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="font-semibold text-lg leading-7">
                  Submitted
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid> */}
        </Grid>
      </form>
    </>
  );
};
export default ClaimsForm;
