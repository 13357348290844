// Importing necessary assets and components
import notificationImg from "./notificationImg.png"; // Notification image import
import { Popover } from "@mui/material"; // Importing Popover from Material-UI
import "./sidebar.css"; // Importing CSS for styling
import { useReadNotifications } from "queries/AuthQueries"; // Importing a custom hook for handling notifications


export const NotificationBox: React.FC<any> = ({
  open,
  handleClose = () => null,
  anchorEl,
  myNotifications,
}) => {
  // Hook to mark a notification as read
  const { mutate: readNotification } = useReadNotifications();

  /**
   * Function to calculate and format the time difference between the current time
   * and the notification's creation time.
   */
  const calculateTimeDiff = (dateStr: string) => {
    // Converting the date string to Date objects
    let notificationDate = new Date(dateStr);
    let currDate = new Date();
    // Calculating the time difference in milliseconds
    let difference = currDate.getTime() - notificationDate.getTime();

    // Converting time difference to days, hours, minutes, and seconds
    let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((difference % (1000 * 60)) / 1000);

    // Formatting the time difference for display
    if (days > 0) return `${days}d.`;
    if (hours > 0) return `${hours}h.`;
    if (minutes > 0) return `${minutes}min.`;
    if (seconds > 0) return `${seconds}sec.`;
    return "";
  };

  // Rendering the NotificationBox component
  return (
    <Popover
      open={open} // Controlling the visibility of the popover
      anchorEl={anchorEl} // Setting the anchor element for the popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleClose} // Handling the popover close event
      className=""
    >
      <div className="flex flex-col p-2 h-[300px] w-[225px] gap-2">
        {/* Rendering notification title or no notification message */}
        <span className=" py-2">
          {myNotifications?.length > 0
            ? "Notifications"
            : "No Notification Found"}
        </span>
        {/* Mapping through notifications and rendering each notification */}
        {myNotifications?.length > 0
          ? myNotifications?.map((notification: any, i: number) => (
              <div key={notification.id} className="flex flex-col gap-2">
                {i === 0 && <div className="w-full h-[1px] bg-black/30"></div>}
                <div
                  onClick={() => readNotification(notification.id)}
                  className="grid  cursor-pointer  grid-cols-[max-content_1fr] w-full gap-x-3 items-center"
                >
                  <div className="w-fit p-2rounded-full bg-[#f5f5f5]">
                    <img src={notificationImg} alt="notification icon" />
                  </div>
                  <div className="w-full flex flex-col gap-1 items-start">
                    <p className="text-[.6rem] font-medium m-0">
                      {notification.body}
                    </p>
                    <span className=" text-[.5rem] text-gray-500">
                      {calculateTimeDiff(notification.createdAt)} ago
                    </span>
                  </div>
                </div>
                <div className="w-full h-[1px] mb-1 bg-black/30"></div>
              </div>
            ))
          : ""}
      </div>
    </Popover>
  );
};
