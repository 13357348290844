// Import necessary Material-UI components for theme customization
import type { ThemeOptions } from "@mui/material/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// Define interfaces for extended palette colors and design palette
interface IExtendedPaletteColor {
  main?: string;
  contrastText?: string;
  light?: string;
  dark?: string;
  25?: string;
  50?: string;
  100?: string;
  200?: string;
  300?: string;
  400?: string;
  500?: string;
  600?: string;
  700?: string;
  800?: string;
  900?: string;
}

interface IDesignPalette {
  primary: IExtendedPaletteColor;
  secondary: IExtendedPaletteColor;
  gray: IExtendedPaletteColor;
  error: IExtendedPaletteColor;
}
// interface IDatePickerClasses {
//   MuiPickersCalendarHeader: any;
// }

export interface IExpandedTheme extends ThemeOptions {
  palette: IDesignPalette;
}

// Create the primary theme with custom settings
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const primaryTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#545454",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#545454",
      contrastText: "#757575",
    },
    info: {
      main: "#E7E7E7",
      contrastText: "#13121299",
    },
    error: {
      main: "#EA4335",
      "800": "#EA4335", 
    },
  },
  typography: {
    fontFamily: `"Poppins", sans-serif -webkit-font-smoothing: antialiased -moz-osx-font-smoothing: grayscale !important`,
  },
  components: {
    // Customization for various Material-UI components
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "8px",
            // maxHeight: "47px",
          },
          input: {
            lineHeight: "24px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // "--TextField-brandBorderColor": "#E4E4E7",
          // "--TextField-brandBorderHoverColor": "#A1A1AA",
          "--TextField-brandBorderFocusedColor": "none",
          // "& label.Mui-focused": {
          //     color: "var(--TextField-brandBorderFocusedColor)",
          // },
          // "&:hover": {
          //     // Add hover styles here
          //     "--TextField-brandBorderColor": "red",
          //     "--TextField-brandBorderHoverColor": "red",
          //     "--TextField-brandBorderFocusedColor": "red", // Add other hover styles as needed
          // },

          ".Mui-disabled": {
            borderRadius: "8px !important",
            backgroundColor: "#E4E4E7", 
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderWidth: "1px",
          borderRadius: "8px",
          borderColor: "#E3E3E3",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        avatar: {
          marginBottom: "auto",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          // minHeight: "70px !important",
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.grey[700],
          fontSize: "14px",
        }),
        asterisk: ({ theme }) => ({
          color: theme.palette.error.contrastText, 
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "outlined",
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "inherit",
          borderWidth: "1px",
          borderRadius: "8px",
          borderColor: "#E3E3E3",
          fontSize: "18px",
          fontWeight: 400,
          boxShadow: "inherit !important",
          padding: "12px 10px !important",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          // backgroundColor: "#5C5C5C",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: "0px",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          boxShadow: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 2px 0px",
        },
      },
    },
  },
});

// Enhance the theme with responsive font sizes
export default responsiveFontSizes(createTheme(primaryTheme));
