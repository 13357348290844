// @ts-nocheck
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Chip, Divider, Grid, Slide, Typography } from '@mui/material';
import LabelledTextField from 'components/Common/LabelledTextField';
import PasswordField from 'components/Common/LabelledTextField/PasswordField';
import { FormValidationSchemas } from 'constants/FormValidationSchemas';
import React, { useState, FC, useMemo, Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import WestIcon from '@mui/icons-material/West';
import moment from 'moment';
import AppleLogin from 'react-apple-login';
import farmBureau from 'assets/Images/farmBureau.svg';
import MailIcon from 'assets/Icons/mailIcon.svg';
import { Cancel } from '@mui/icons-material';
import Icons from 'constants/Icon';
import './LoginSignupForm.css';
import DateSelect from 'components/Common/DatePicker';
import { routes } from 'routes/RouteConstants';
import Loader from 'components/Common/Loader/loader';
import NumberPatternField from 'components/Common/NumberField';
import { useGoogleLogin } from '@react-oauth/google';
import { isIOS, isMacOs } from 'react-device-detect';
import { toast } from 'react-toastify';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PasswordIcon from 'assets/Icons/passwordIcon.svg';
import 'react-toastify/dist/ReactToastify.css';

import { setLocalStorageItem } from 'hooks/localStorageHelper';
import {
  FetchUserDetailsAPI,
  UpdatePaperlessEnrolledAPI,
  useEmailExistQuery,
  useGetDeviceToken,
  useLoginUserQuery,
  useResetPasswordRequest,
  useSendResetLink,
  useSignupUserQuery,
  useSocialLoginQuery,
} from 'queries/AuthQueries';
import NoPolicyFoundDialog from './NoPolicyFoundDialog';
import { useQueryClient } from 'react-query';
import { CheckUserPolicyExists } from 'queries/PolicyQuery';
import HandleErrorToast from 'helper/HandleErrorResponse';
import { error } from 'console';
import AddExistingPolicyDialog from 'components/Policy/AddExistingPolicy';
import useQueryParams from 'utils/useQueryParams';
import Branding from 'components/Branding';

const {
  email,
  password,
  first_name,
  last_name,
  zip_code,
  dob,
  confirm_password,
} = FormValidationSchemas;
const userLoginSchema = yup.object().shape({
  email,
  password,
});

const userForgotPasswordSchema = yup.object().shape({
  password,
  confirm_password,
});

const userEmailLogin = yup.object().shape({
  email,
});

const userSignUpSchema = yup.object().shape({
  first_name,
  last_name,
  email,
  password,
});

const userDetailsSchema = yup.object().shape({
  first_name,
  last_name,
  email,
  password,
  // zip_code,
});

const googleLoginSchema = yup.object().shape({
  first_name,
  last_name,
  // dob,
});

const APPLE_CLIENT_ID = `${process.env.REACT_APP_APPLE_CLIENT_ID}`;

// This page is used for login, sign up & forgot password
const LoginForm: FC<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // All api calling
  const { mutate: socialLogin } = useSocialLoginQuery();
  const { mutate: checkUser } = useEmailExistQuery();
  const { mutate: userSignUp } = useSignupUserQuery();
  const { mutate: userSignIn } = useLoginUserQuery();
  const { mutate: resetPasswordLink } = useSendResetLink();
  const { mutate: resetPasswordRequest } = useResetPasswordRequest();

  const isSignupScreen = Boolean(location.pathname === routes.SIGNUP.pathname);
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [signinSignupForm, setSigninSignupForm] = useState(false);
  const [detailsForm, setDetailsForm] = useState(false);
  const [registeredUser, setRegisteredUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isForgotPass, setIsForgotPass] = useState(false);
  const [isForgotPassLinkSend, setIsForgotPassLinkSend] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const { mutate: updateGoogleLogin } = UpdatePaperlessEnrolledAPI();
  const { data: userDetails, dataUpdatedAt } = FetchUserDetailsAPI();
  const [resetLinkError, setResetLinkError] = useState('');

  const resetNewPassword =
    location.pathname === routes.RESET_PASSWORD?.pathname;
  const { token, email: queryParamEmail } = useQueryParams(['token', 'email']);

  const uuid = userDetails?.data?.uuid;

  const { userData } = useMemo(() => {
    if (dataUpdatedAt) {
      return {
        userData: userDetails?.data,
      };
    } else {
      return {
        userData: {},
      };
    }
  }, [dataUpdatedAt]);

  const userForgotPassword = Boolean(
    location.pathname === routes.FORGOT_PASSWORD.pathname,
  );

  const getSchema = () => {
    if (resetNewPassword) {
      return userForgotPasswordSchema;
    }
    if (isEmailLogin && !signinSignupForm) {
      return userEmailLogin;
    } else if (signinSignupForm && !registeredUser && !detailsForm) {
      return userSignUpSchema;
    } else if (detailsForm) {
      return userDetailsSchema;
    } else if (isSignupScreen) {
      return userSignUpSchema;
    } else if (isGoogleLogin) {
      return googleLoginSchema;
    } else {
      return userLoginSchema;
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(getSchema()),
  });

  const {
    email,
    first_name,
    last_name,
    password,
    zip_code,
    dob,
    confirm_password,
  } = watch();

  const dateOfBirth = moment(dob);
  const formattedDob = dateOfBirth?.format('YYYY-MM-DD');

  // console.log("login ", errors);

  const [open, setOpen] = useState(false);
  const openNoPolicyDialog = () => {
    setOpen(true);
  };
  const queryCliet = useQueryClient();

  const handleClose = () => {
    navigate(routes.DASHBOARD?.pathname as string);
    setOpen(false);
  };

  function handleSendForgetPassLink() {
    resetPasswordLink(
      { email: email },
      {
        onSuccess: (res) => {
          setIsForgotPassLinkSend(true);
        },
        onError: (err) => {
          console.log('Error:', err);
          setResetLinkError(err.fieldErrors[0].message);
        },
      },
    );
  }

  const resetUserPassword = () => {
    resetPasswordRequest(
      {
        email: queryParamEmail,
        password,
        confirmPassword: confirm_password,
        token,
      },
      {
        onSuccess: (res) => {
          setIsForgotPassLinkSend(false);
          setIsForgotPass(false);
          navigate(routes.LOGIN.pathname);
        },
        onError: (err) => {
          console.log('Error:', err);
        },
      },
    );
  };

  function handleSigninEmailClick() {
    checkUser(
      { email: email },
      {
        onSuccess: (res) => {
          if (res?.data?.emailExists) {
            setRegisteredUser(true);
            setSigninSignupForm(true);
          } else {
            setRegisteredUser(false);
            setSigninSignupForm(true);
          }
        },
        onError: (err) => {
          console.log('Error:', err);
        },
      },
    );
  }
  const inValidateQueries = () => {
    queryCliet.invalidateQueries(['GET_LOGGED_IN_USER_DETAILS']);
    queryCliet.invalidateQueries(['GET_POLICY_LIST']);
    queryCliet.invalidateQueries(['GET_AGENT']);
    queryCliet.invalidateQueries(['GET_BILLS']);
  };
  const handleSignin = (email: any, password: any) => {
    setLoading(true);
    userSignIn(
      { scope: 'USER_LOGIN', email, password },
      {
        onSuccess: async (res) => {
          const { data: policies } = await CheckUserPolicyExists();
          if (policies && policies.policyExists === false) {
            openNoPolicyDialog();
          } else {
            navigate(routes.DASHBOARD.pathname);
          }
          inValidateQueries();
          setLoading(false);
        },
        onError: (error: any) => {
          console.log('Error:', error.message);
          alert(error.message);
          setLoading(false);
        },
      },
    );
  };
  const handleSignup = async (data: any) => {
    try {
      setLoading(true);
      userSignUp(data, {
        onSuccess: async (res) => {
          try {
            const { data: policies } = await CheckUserPolicyExists();
            if (policies && policies.policyExists === false) {
              openNoPolicyDialog();
            } else {
              navigate(routes.DASHBOARD.pathname);
            }
          } catch (error) {
            console.error('Error in policy check:', error);
          } finally {
            setLoading(false);
          }
        },
        onError: (err) => {
          HandleErrorToast(err?.fieldErrors[0]?.message);
          console.error('Error during sign up:', err);
        },
      });
    } catch (error) {
      console.error('Error in handleSignup:', error);
    } finally {
      setLoading(false);
    }
  };

  const submitAuthForm = (data: any) => {
    if (resetNewPassword) {
      resetUserPassword();

      return;
    }

    if (isForgotPassLinkSend) {
      window.open('https://mail.google.com', '_blank');
      return;
    }

    if (isForgotPass && !resetNewPassword) {
      handleSendForgetPassLink();
      return;
    }
    // if (isForgotPass) {
    //   setIsForgotPassLinkSend(true);
    //   return;
    // }
    if (isEmailLogin && !signinSignupForm) {
      handleSigninEmailClick();
      return;
    }

    if (signinSignupForm && !registeredUser) {
      //   setDetailsForm(true);
      // }

      // if (detailsForm) {
      const dateOfBirth = moment(data.dob);
      const dob = dateOfBirth?.format('YYYY-MM-DD');

      const payload = {
        scope: 'USER_REGISTER',
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        password: data.password,
        zip_code: data.zip_code || null,
        dob: dob,
        address: '',
      };

      handleSignup(payload);
    }
  };

  const handleAuthErrors = (err: any) => {
    console.log(err, 'errors');
  };

  function handleEmailClick() {
    setIsEmailLogin(true);
  }

  function cancelEmail() {
    setSigninSignupForm(false);
    setRegisteredUser(false);
  }
  function handleDateChange(event: any) {
    setValue('dob', event.target.value, { shouldValidate: true });
  }

  function handleZipCodeChange(event: any) {
    setValue('zip_code', event, { shouldValidate: true });
  }

  const handleSignIn = async (tokenId, identityProvider) => {
    try {
      setLoading(true);
      setLocalStorageItem(`${identityProvider}_ACCESS_TOKEN`, tokenId);

      socialLogin(
        {
          token: tokenId,
          identityProvider: identityProvider,
          deviceType: 'WEB',
        },
        {
          onSuccess: async () => {
            const { data: policies } = await CheckUserPolicyExists();

            if (!userData?.dob) {
              setIsGoogleLogin(true);
            } else {
              if (policies && policies.policyExists === false) {
                openNoPolicyDialog();
              } else {
                navigate(routes.DASHBOARD.pathname);
              }
            }

            if (policies && policies.policyExists === false) {
              // openNoPolicyDialog();
            } else {
              if (
                identityProvider !== 'GOOGLE' ||
                identityProvider !== 'APPLE'
              ) {
                navigate(routes.DASHBOARD.pathname);
              }
            }
            inValidateQueries();
          },
          onError: (err) => {
            console.log('Social Login Error:', err);
          },
        },
      );
    } catch (error) {
      console.error(
        `An error occurred during ${identityProvider} login:`,
        error,
      );
    } finally {
      setLoading(false);
    }
  };

  const onGoogleLoginSuccess = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const tokenId = tokenResponse.access_token;
      if (!tokenId) {
        console.log('Google Login failed: No tokenId found.');
        return;
      }
      handleSignIn(tokenId, 'GOOGLE');
    },
  });

  const handleGooleLogin = () => {
    onGoogleLoginSuccess();
  };

  const handleAppleSignIn = (res) => {
    const tokenId = res?.authorization?.id_token;
    console.log('AppleLoginnResponse:::', res?.authorization);
    if (!tokenId) {
      console.log('Apple Login failed: No tokenId found.');
      return;
    }
    handleSignIn(tokenId, 'APPLE');
  };

  function getFormTitle(detailsForm: any, userForgotPassword: any) {
    if (resetNewPassword) {
      return (
        <Fragment>
          <Grid>
            <img src={PasswordIcon} alt="Password Icon" />
          </Grid>
          <Grid>Set new password</Grid>
        </Fragment>
      );
    }

    if (detailsForm) {
      return 'Enter Details';
    }
    if (isForgotPassLinkSend) {
      return (
        <Fragment>
          <Grid className=" w-full flex justify-center items-center">
            <img src={MailIcon} alt="Mail icon" />
          </Grid>
          <Grid>Check your email</Grid>
        </Fragment>
      );
    }
    if (isForgotPass) {
      return 'Forgot Password ?';
    }

    return 'Welcome';
  }

  const title = getFormTitle(detailsForm, userForgotPassword);

  const getWelcomeMessage = () => {
    if (detailsForm) return '';
    if (resetNewPassword) {
      return 'Your new password must be different from previously used passwords';
    }

    if (isForgotPassLinkSend) {
      return (
        <Fragment>
          <Grid>
            <Typography fontWeight={500} fontSize={'16px'}>
              {' '}
              We have sent a password reset link to
            </Typography>
          </Grid>
          <Grid>
            <Typography fontWeight={500} fontSize={'16px'} color={'black'}>
              {email}
            </Typography>
          </Grid>
        </Fragment>
      );
    }
    if (isForgotPass) {
      return 'We will send a reset link to your email address.';
    }

    return 'Welcome! Please enter your details.';
  };
  const welcomeMessage = getWelcomeMessage();

  const handleGoogleUserUpdate = () => {
    console.log(first_name, last_name, dob, 'userUpdate');

    const updateData = {
      data: {
        firstName: first_name,
        lastName: last_name,
        dob: dob,
      },
      uuid: uuid,
    };

    updateGoogleLogin(updateData, {
      onSuccess: () => {
        openNoPolicyDialog();
      },
      onError: (error: any) => {
        console.error('Password reset failed:', error.fieldErrors[0].message);
      },
    });
  };

  const handleGoBack = () => {
    window.location.reload();
  };

  return (
    <>
      {loading && <Loader />}

      <form
        onSubmit={handleSubmit(submitAuthForm, handleAuthErrors)}
        id="login-form"
        className="my-auto"
      >
        <Slide direction="right" in key={location.pathname} timeout={600}>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className="flex max-w-4xl flex-col items-center justify-center mb-4">
                  <img src={farmBureau} alt="" className="logo-icon" />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Typography
                    variant="h4"
                    color="text.blackHeading"
                    align="center"
                    className="font-semibold text-3xl leading-9"
                  >
                    {title}
                  </Typography>
                  <div className="flex justify-center items-center">
                    <Typography
                      variant="subtitle1"
                      color="#B0B0B0"
                      align="center"
                      className="mt-4 !mb-8 font-normal text-base leading-6 w-96"
                    >
                      {welcomeMessage}
                    </Typography>
                  </div>
                </div>
              </Grid>
              {!detailsForm && (
                <Grid item container xs={12}>
                  {signinSignupForm &&
                    registeredUser &&
                    !(
                      isForgotPass ||
                      isForgotPassLinkSend ||
                      resetNewPassword
                    ) && (
                      <Grid item container spacing={6}>
                        <Grid item xs={12}>
                          {email && (
                            <Chip
                              className="max-w-[250px] items-center justify-center m-auto flex"
                              label={<span className="text-lg">{email}</span>}
                              onDelete={cancelEmail}
                              deleteIcon={<Cancel />}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <PasswordField
                            className="max-w-[343px] items-start justify-center m-auto flex h-12"
                            inputProps={{ maxLength: 25 }}
                            labelText="Password"
                            placeholder="Enter Password"
                            type="password"
                            {...register('password')}
                            error={Boolean(errors?.password)}
                            helperText={
                              errors?.password ? errors.password.message : ' '
                            }
                            autoComplete="password"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className=" items-center justify-center  flex h-8"
                        >
                          <Typography
                            fontWeight={400}
                            fontSize={'12px'}
                            className="hover:underline cursor-pointer text-right w-[343px]"
                            onClick={() => setIsForgotPass(true)}
                          >
                            Forgot Password
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            color="secondary"
                            fullWidth
                            type="submit"
                            size="large"
                            disabled={!password}
                            className="max-w-[343px] items-center justify-center m-auto flex custom-button h-12"
                            onClick={() => handleSignin(email, password)}
                          >
                            Sign in
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  {signinSignupForm && !registeredUser && (
                    <Grid item container spacing={6} xs={12}>
                      <Grid item xs={12}>
                        {email && (
                          <Chip
                            className="max-w-[250px] items-center justify-center m-auto flex"
                            label={<span className="text-lg">{email}</span>}
                            onDelete={cancelEmail}
                            deleteIcon={<Cancel />}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <LabelledTextField
                          className="max-w-[343px] items-start justify-center m-auto flex h-8 mb-2"
                          variant="outlined"
                          labelText="First Name"
                          inputProps={{ style: { height: '12px' } }}
                          placeholder="Enter First name"
                          {...register('first_name')}
                          error={Boolean(errors?.first_name)}
                          helperText={
                            (errors?.first_name?.message as string) || ''
                          }
                          autoComplete="first_name"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabelledTextField
                          className="max-w-[343px] items-start justify-center m-auto flex h-8 mb-2"
                          variant="outlined"
                          labelText="Last Name"
                          inputProps={{ style: { height: '12px' } }}
                          placeholder="Enter Last Name"
                          {...register('last_name')}
                          error={Boolean(errors?.last_name)}
                          helperText={
                            (errors?.last_name?.message as string) || ''
                          }
                          autoComplete="last_name"
                        />
                      </Grid>
                      {console.log('errors?.password', errors)}
                      <Grid item xs={12}>
                        <PasswordField
                          className="max-w-[343px] items-start justify-center m-auto flex h-8 mb-2"
                          labelText="Password"
                          placeholder="Enter Password"
                          inputProps={{ style: { height: '12px' } }}
                          type="password"
                          {...register('password')}
                          error={Boolean(errors?.password)}
                          helperText={
                            (errors?.password?.message as string) || ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} className="!pt-7">
                        <Button
                          color="secondary"
                          fullWidth
                          type="submit"
                          size="large"
                          disabled={
                            !(email && first_name && last_name && password)
                          }
                          className="max-w-[343px] items-center justify-center m-auto flex custom-button h-12"
                          // onClick={handleSignup}
                        >
                          Sign up
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {isEmailLogin &&
                    !signinSignupForm &&
                    !isForgotPass &&
                    !resetNewPassword && (
                      <Grid item container spacing={4}>
                        <Grid item xs={12}>
                          <LabelledTextField
                            variant="outlined"
                            labelText="Email"
                            inputProps={{ style: { height: '12px' } }}
                            placeholder="Enter Email"
                            {...register('email')}
                            error={Boolean(errors?.email?.message)}
                            helperText={
                              (errors?.email?.message as string) || ''
                            }
                            autoComplete="email"
                            className="max-w-[343px] items-start justify-center m-auto flex h-8"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className=" items-center justify-center  flex h-8"
                        >
                          <Typography
                            fontWeight={400}
                            fontSize={'12px'}
                            className="hover:underline cursor-pointer text-right w-[343px]"
                            onClick={() => setIsForgotPass(true)}
                          >
                            Forgot Password
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            color="secondary"
                            fullWidth
                            type="submit"
                            size="large"
                            disabled={!email}
                            className="max-w-[343px] items-center justify-center m-auto flex custom-button h-12"
                          >
                            <span className="font-medium text-base leading-7">
                              Continue with Email
                            </span>
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="max-w-[343px] items-center justify-center m-auto"
                        >
                          <div className="flex items-center justify-between mx-auto">
                            <Divider className="w-[45%] border-dashed" />
                            <p className="text-center mx-2 text-[#CCCCCC]">
                              OR
                            </p>
                            <Divider className="w-[45%] border-dashed" />
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  {/* Forgot password page */}
                  {isForgotPass &&
                    !isForgotPassLinkSend &&
                    !resetNewPassword && (
                      <Grid item container spacing={4}>
                        <Grid item xs={12}>
                          <LabelledTextField
                            variant="outlined"
                            labelText="Email"
                            inputProps={{ style: { height: '12px' } }}
                            placeholder="Enter your email"
                            {...register('email',{
                              onChange: (e) => {
                                setResetLinkError("")
                              }
                            })}
                            error={Boolean(errors?.email?.message)}
                            helperText={
                              (errors?.email?.message as string) || ''
                            }
                            autoComplete="email"
                            className="max-w-[343px] items-start justify-center m-auto flex h-8"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {resetLinkError.length !== 0 && (
                            <div className="text-center text-xs mb-2 text-red-600">
                              {' '}
                              {resetLinkError}
                            </div>
                          )}
                          <Button
                            color="secondary"
                            fullWidth
                            type="submit"
                            size="large"
                            disabled={!email}
                            className="max-w-[343px] items-center justify-center m-auto flex custom-button h-12"
                          >
                            <span className="font-medium text-base leading-7">
                              Send Reset Link
                            </span>
                          </Button>
                        </Grid>
                        {resetLinkError.length !== 0 && (
                          <div
                            className="max-w-[153px] items-center justify-center m-auto flex border-black border-2 border-solid py-2 px-4 rounded-lg mt-4 cursor-pointer"
                            onClick={handleGoBack}
                          >
                            <WestIcon className="mr-1 text-sm" />
                            <span className="font-medium text-sm  ml-2">
                              Back to Login
                            </span>
                          </div>
                        )}
                      </Grid>
                    )}

                  {isForgotPassLinkSend && !resetNewPassword && (
                    <Grid item container spacing={4}>
                      <Grid item xs={12}>
                        <Button
                          color="secondary"
                          fullWidth
                          type="submit"
                          size="large"
                          disabled={!email}
                          className="max-w-[343px] items-center justify-center m-auto flex custom-button h-12"
                        >
                          <span className="font-medium text-base leading-7">
                            Open email app
                          </span>
                        </Button>
                        <Grid
                          item
                          xs={12}
                          className="max-w-[343px] items-center justify-center m-auto mt-6 flex h-8 gap-2"
                        >
                          <Typography fontWeight={400} fontSize={'14px'}>
                            Didn’t receive the email?
                          </Typography>
                          <Typography
                            fontWeight={500}
                            fontSize={'14px'}
                            className="hover:underline cursor-pointer"
                          >
                            Click to resend
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="max-w-[343px] items-center justify-center m-auto flex h-8"
                        >
                          <Button
                            className="h-12 border-none hover:border-none text-[16px] font-semibold mt-4"
                            onClick={() => {
                              setIsForgotPass(false);
                              setIsForgotPassLinkSend(false);
                            }}
                          >
                            <WestIcon className="mr-2 w-4" />
                            Back to Login
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {resetNewPassword && (
                    <Grid item container spacing={4}>
                      <Grid item xs={12}>
                        <PasswordField
                          className="max-w-[343px] items-start justify-center m-auto flex h-12"
                          inputProps={{ maxLength: 25 }}
                          labelText={
                            <Typography fontSize={'12px'} fontWeight={400}>
                              New Password
                            </Typography>
                          }
                          placeholder="Enter your new password"
                          type="password"
                          {...register('password')}
                          error={Boolean(errors?.password)}
                          helperText={
                            errors?.password ? errors.password.message : ' '
                          }
                          autoComplete="password"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <PasswordField
                          className="max-w-[343px] items-start justify-center m-auto flex h-12"
                          inputProps={{ maxLength: 25 }}
                          labelText={
                            <Typography
                              fontSize={'12px'}
                              fontWeight={400}
                              marginTop={'24px'}
                            >
                              Confirm Password
                            </Typography>
                          }
                          placeholder="Enter your confirm password"
                          type="password"
                          {...register('confirm_password')}
                          error={Boolean(errors?.confirm_password)}
                          helperText={
                            errors?.confirm_password
                              ? errors.confirm_password.message
                              : ' '
                          }
                          autoComplete="password"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="secondary"
                          fullWidth
                          type="submit"
                          size="large"
                          disabled={
                            !password ||
                            !confirm_password ||
                            password !== confirm_password
                          }
                          className="max-w-[343px] items-center justify-center m-auto flex custom-button h-12 mt-6"
                        >
                          Reset Password
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  {!registeredUser &&
                    !(
                      resetNewPassword ||
                      isForgotPass ||
                      isForgotPassLinkSend
                    ) && (
                      <Grid item container>
                        <Grid container spacing={2}>
                          {!isGoogleLogin && (
                            <Grid
                              item
                              xs={12}
                              className="flex justify-center items-center h-full mt-2"
                            >
                              <Button
                                color="secondary"
                                fullWidth
                                type="button"
                                size="large"
                                className="max-w-[343px] items-center justify-center m-auto flex h-12"
                                startIcon={Icons.Google}
                                // onClick={() => onGoogleLoginSuccess()}
                                onClick={handleGooleLogin}
                              >
                                <span className="font-medium text-base leading-7">
                                  Continue with Google
                                </span>
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                        {!isGoogleLogin && (
                          <Grid item xs={12} className="!pt-4">
                            <AppleLogin
                              clientId={APPLE_CLIENT_ID}
                              redirectURI={`${window.location.origin}`}
                              usePopup={true}
                              render={(renderProps) => (
                                <Button
                                  onClick={renderProps.onClick}
                                  color="secondary"
                                  fullWidth
                                  size="large"
                                  startIcon={Icons.Apple}
                                  className="max-w-[343px] items-center justify-center m-auto flex h-12"
                                >
                                  <span className="font-medium text-base leading-7">
                                    Continue with Apple
                                  </span>
                                </Button>
                              )}
                              responseMode="query"
                              callback={handleAppleSignIn}
                              scope="email name"
                            />
                          </Grid>
                        )}
                      </Grid>
                    )}
                  {isGoogleLogin && (
                    <Grid item container spacing={6} xs={12} className="-mt-8">
                      <Grid item xs={12}>
                        {userData?.email && (
                          <Chip
                            className="max-w-[250px] items-center justify-center m-auto flex"
                            label={
                              <span className="text-lg">{userData?.email}</span>
                            }
                            onDelete={cancelEmail}
                            deleteIcon={<Cancel />}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <LabelledTextField
                          className="max-w-[343px] items-start justify-center m-auto flex h-8 mb-2"
                          variant="outlined"
                          labelText="First Name"
                          inputProps={{ style: { height: '12px' } }}
                          placeholder="Enter First name"
                          {...register('first_name')}
                          error={Boolean(errors?.first_name)}
                          helperText={
                            (errors?.first_name?.message as string) || ''
                          }
                          autoComplete="first_name"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabelledTextField
                          className="max-w-[343px] items-start justify-center m-auto flex h-8 mb-2"
                          variant="outlined"
                          labelText="Last Name"
                          inputProps={{ style: { height: '12px' } }}
                          placeholder="Enter Last Name"
                          {...register('last_name')}
                          error={Boolean(errors?.last_name)}
                          helperText={
                            (errors?.last_name?.message as string) || ''
                          }
                          autoComplete="last_name"
                        />
                      </Grid>
                      {/* <Grid item xs={12} className="-mt-4">
                        <DateSelect
                          className="max-w-[343px] items-start justify-center m-auto"
                          value={dob}
                          onChange={handleDateChange}
                          error={Boolean(errors?.dob)}
                          labelText="Date of Birth"
                          helperText={(errors?.dob?.message as string) || ""}
                        >
                          {console.log("errorsdob", errors)}
                        </DateSelect>
                        {errors?.dob && (
                          <Typography
                            variant="body2"
                            color="error"
                            textAlign={"center"}
                            marginTop={2}
                            marginBottom={-8}
                          >
                            {errors?.dob?.message ?? ""}
                          </Typography>
                        )}
                      </Grid> */}
                      <Grid item xs={12} className="-mt-5">
                        <Button
                          color="secondary"
                          fullWidth
                          type="submit"
                          size="large"
                          disabled={!(first_name && last_name)}
                          className="max-w-[343px] items-center justify-center m-auto flex custom-button h-12"
                          onClick={handleGoogleUserUpdate}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
              {!isEmailLogin &&
                !detailsForm &&
                !isGoogleLogin &&
                !(resetNewPassword || isForgotPass || isForgotPassLinkSend) && (
                  <Grid item container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      className="max-w-[343px] items-center justify-center m-auto"
                    >
                      <div className="flex items-center justify-between mx-auto">
                        <Divider className="w-[45%] border-dashed border-gray-300" />
                        <p className="text-center mx-2 font-normal text-base leading-5 text-gray-300">
                          OR
                        </p>
                        <Divider className="w-[45%] border-dashed border-gray-300" />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        color="secondary"
                        fullWidth
                        type="submit"
                        size="large"
                        className="max-w-[343px] items-center justify-center m-auto flex !bg-[#262626] text-white h-12"
                        onClick={handleEmailClick}
                      >
                        <span className="font-medium text-base leading-7">
                          Continue with Email
                        </span>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              {detailsForm && (
                <Grid item container spacing={6}>
                  <Grid item xs={12}>
                    <LabelledTextField
                      className="max-w-[343px] items-start justify-center m-auto flex h-8 text-field"
                      variant="outlined"
                      labelText="First Name"
                      inputProps={{ style: { height: '12px' } }}
                      placeholder="Enter First name"
                      {...register('first_name')}
                      error={Boolean(errors?.first_name)}
                      helperText={(errors?.first_name?.message as string) || ''}
                      disabled={first_name !== ''}
                      autoComplete="first_name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LabelledTextField
                      className="max-w-[343px] items-start justify-center m-auto flex h-8"
                      variant="outlined"
                      labelText="Last Name"
                      inputProps={{ style: { height: '12px' } }}
                      placeholder="Enter Last Name"
                      {...register('last_name')}
                      error={Boolean(errors?.last_name)}
                      helperText={(errors?.last_name?.message as string) || ''}
                      disabled={last_name !== ''}
                      autoComplete="last_name"
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <NumberPatternField
                      className="max-w-[343px] items-start justify-center m-auto flex h-8"
                      variant="outlined"
                      labelText="Zip code"
                      inputProps={{
                        style: { height: "12px" },
                        maxLength: 5,
                      }}
                      placeholder="Enter Zip Code"
                      value={zip_code}
                      error={Boolean(errors?.zip_code)}
                      onChange={handleZipCodeChange}
                      helperText={(errors?.zip_code?.message as string) || ""}
                    ></NumberPatternField>
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    <DateSelect
                      className="max-w-[343px] items-start justify-center m-auto"
                      value={dob}
                      onChange={handleDateChange}
                      error={Boolean(errors?.dob)}
                      labelText="Date of Birth"
                      helperText={(errors?.dob?.message as string) || ""}
                    >
                      {console.log("errorsdob", errors)}
                    </DateSelect>
                    {errors?.dob && (
                      <Typography
                        variant="body2"
                        color="error"
                        textAlign={"center"}
                        marginTop={2}
                        marginBottom={-4}
                      >
                        {errors?.dob?.message ?? ""}
                      </Typography>
                    )}
                  </Grid> */}
                  <Grid item xs={12}>
                    <Button
                      color="secondary"
                      fullWidth
                      type="submit"
                      size="large"
                      className="max-w-[343px] items-center justify-center m-auto flex custom-button h-12"
                      disabled={!(first_name && last_name)}
                    >
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <footer>
              <Grid container className='md:flex hidden'>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color="#757575"
                    className="font-normal text-base leading-5 text-start ml-10 mb-4"
                  >
                    © Farm Bureau
                  </Typography>
                </Grid>
              </Grid>
            </footer>
          </div>
        </Slide>
      </form>
      <AddExistingPolicyDialog open={open} handleClose={handleClose} />
    </>
  );
};

export default LoginForm;
