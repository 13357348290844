// Importing necessary components and hooks from Material-UI, React, and other libraries.
import { Button, Dialog, DialogTitle, Typography } from "@mui/material";
import closeImg from "assets/Images/close.svg";
import React, { useId } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { PassengersEntry } from "../types";

// Defining validation schema for the passenger form using Yup
const schema = yup.object().shape({
  // Schema for first name
  firstName: yup
    .string()
    .nullable()
    .required("First name is required")
    .matches(/^[A-Za-z]+$/, "Please enter a valid first name")
    .max(30, "Name can not be longer than 30 characters"),
  // Schema for last name
  lastName: yup
    .string()
    .nullable()
    .required("Last name is required")
    .matches(/^[A-Za-z]+$/, "Please enter a valid last name")
    .max(30, "Name can not me longer than 30 characters"),
  // Schema for phone number
  phoneNumber: yup
    .string()
    .trim()
    .required("Phone Number is required")
    .matches(/^[0-9]+$/, {
      message:
        "Phone number must be exactly 10 digits and contain only numbers.",
    }),
});

// Prop types for the AddPassengerModal component
type IAddPassengerModal = {
  open: boolean;
  handleClose: () => void;
  handleFormSubmit: (data: PassengersEntry) => void;
  heading: string;
  personType: string;
};

// Component definition for adding a passenger modal
const AddPassengerModal = ({
  open,
  handleClose,
  handleFormSubmit,
  heading,
  personType,
}: IAddPassengerModal) => {
  // Setting up form handling using react-hook-form
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Form submission handler
  
  const onSubmit = (data: any) => {
    reset();
    handleFormSubmit({ id: Date.now(), ...data });
    console.log(data);
  };

  return (
    <div>
      <Dialog 
      open={open} 
      maxWidth="xs" 
      sx={{ 
        zIndex: "9999999"
      }}
      >
        <DialogTitle id="alert-dialog-title" bgcolor={"natural.100"}>
          <div className="flex justify-end">
            {/* Close button for the modal */}
            <img
              src={closeImg}
              alt="close"
              onClick={handleClose}
              className="cursor-pointer"
            />
          </div>
          <div className="flex flex-col justify-start items-start gap-2 mb-2">
            {/* Modal heading */}
            <Typography fontSize={18} fontWeight={500} color="gray.500">
              {heading}
            </Typography>
            {/* Modal description */}
            <Typography fontSize={12}>
              Please provide details about the {personType} you want to add
            </Typography>
          </div>
          {/* Form for adding passenger details */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* First name input field */}
            <div className="mb-4">
              <label className="text-sm">
                First Name<span className="text-error-500">*</span>
              </label>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    error={!!errors.firstName}
                    helperText={(errors?.firstName?.message as string) || ""}
                  />
                )}
              />
            </div>
            {/* Last name input field */}
            <div className="mb-4">
              <label className="text-sm">
                Last Name<span className="text-error-500">*</span>
              </label>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    //  variant="outlined"
                    fullWidth
                    error={!!errors.lastName}
                    helperText={(errors?.lastName?.message as string) || ""}
                  />
                )}
              />
            </div>
            {/* Phone number input field */}
            <div className="mb-4">
              <label className="text-sm">
                Phone Number<span className="text-error-500">*</span>
              </label>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    //  variant="outlined"
                    fullWidth
                    error={!!errors.phoneNumber}
                    helperText={(errors?.phoneNumber?.message as string) || ""}
                    inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                  />
                )}
              />
            </div>
            {/* Submit button for the form */}
            <Button
              type="submit"
              variant="contained"
              className="bg-gray-950 text-gray-25"
              fullWidth
            >
              {heading}
            </Button>
          </form>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default AddPassengerModal;
