import React, { useState } from "react";
import brokenGlass from "assets/Images/brokenGlass.svg";
import CallIcon from "@mui/icons-material/Call";
import WestIcon from "@mui/icons-material/West";

import { Button, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ShowBreadCrumbs from "./BreadCrumbs";
import useQueryParams from "utils/useQueryParams";

const BrokenAndOtherSuccessfulPage = () => {
  // Extract the 'claimType' from query parameters using a custom hook.
  const { claimType } = useQueryParams(["claimType"]);
  

  // Initialize the router's navigation function.
  const navigate = useNavigate();

  // Function to redirect to the homepage when the button is clicked.
  const redirectToHomePage = () => {
    navigate("/dashboard");
  };

  return (
    <div>
      {/* Display breadcrumbs */}
      <ShowBreadCrumbs />
      <Grid className="flex flex-col justify-center items-center gap-4 mt-8">
        <div className="flex flex-col gap-12 justify-center items-center">
          {/* Display an image */}
          <img src={brokenGlass} alt="img" />
          <div className="flex flex-col gap-3 justify-center items-center">
            {/* Display a title based on the claim type */}
            {claimType === "other" ? 
            <Typography fontWeight={600} fontSize={20}>
              File a claim for the other Claims
            </Typography>
             : <Typography fontWeight={600} fontSize={20} textAlign={"center"}>
            Report Glass or Windshield Damage
            </Typography>}

            {/* Display claim-related information */}
            {claimType === "other"? <Typography fontWeight={400} fontSize={12} textAlign={"center"}>
            To file a claim other than auto, glass, or windshield damage, please contact your agent or call our 24-hour claims line.
            </Typography>  : 
            <Typography fontWeight={400} fontSize={12} textAlign={"center"}>
            To report glass or windshield damage, please contact Safelite through one of the options below.
            </Typography> 
              }
          </div>
        </div>

        {/* Button to call the claim helpline */}
        <Button
          className="h-12 bg-black text-white hover:bg-gray-950"
          fullWidth
        >
          <CallIcon className="mr-2" /> 888-392-9673
        </Button>

        {/* Button to navigate back to the homepage */}
        <Button
          className="h-12 border-none hover:border-none"
          onClick={redirectToHomePage}
        >
          <WestIcon className="mr-2" />
          Back to Homepage
        </Button>
        {claimType !== "other" && 
        <a className="decoration-transparent text-black hover:text-blue-800" href="https://www.safelite.com" target="_blank">www.safelite.com</a> 
        }
      </Grid>

      
    </div>
  );
};

export default BrokenAndOtherSuccessfulPage;
